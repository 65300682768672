import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import DataTable, { getPage, SortChangeHandler, TableColumn, UncontrolledDataTable } from 'components/DataTable';
import MiniPaginate from 'components/MiniPaginate';
import elements from 'config/elements';
import { DATE_FORMAT } from 'config/global';
import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { ClientLevel } from 'enums/Permission';
import Authorize from 'lib/Authorize';
import { ProgramSearchBar, SearchType } from 'components/program/ProgramSearchBar';
import { sortBy } from 'lodash';
import {
    SearchResultsResponse,
    ClientCustomerSearchResultsResponse,
    ClientCustomerSearchResults,
} from 'core/ovations-api/definitions';
import { selectors } from 'redux-modules/root';
import CreateButton from 'core/ovations-components/CreateButton';

export type ProgramSearchProps = ContainerProps<{ clientId: string }>;
type Program = OvationsApi.Types.Program;
type Customer = OvationsApi.Types.Customer;
type CustomerDataDisplay = Customer & { programHeader: JSX.Element | null; programId: string };

interface ProgramSearchContainerState {
    page: number;
    perPage: number;
    programResults: Program[];
    clientCustomerResponse: ClientCustomerSearchResultsResponse;
    selectedSearchType: SearchType;
    sortKey?: string | undefined;
    sortDescending?: boolean;
    isLoading?: boolean;
}

export const emptyClientCustomerSearchResultsResponse = {
    totalPrograms: 0,
    totalResults: 0,
    results: [{ programId: 0, customers: [] }],
};

export class ProgramSearchContainer extends React.Component<ProgramSearchProps, ProgramSearchContainerState> {
    programColumns: Array<TableColumn<Program>> = [
        {
            key: 'name',
            header: 'Program Name',
            headerClassName: 'w-50',
            cell: (row, key) => (
                <th key={key} scope="row">
                    {row[key]}
                </th>
            ),
            sortable: true,
        },
        {
            key: 'number',
            header: 'Program ID',
            headerClassName: 'w-25',
            cell: (row, key) => <td key={key}>{row[key]}</td>,
            sortable: true,
        },
        {
            key: 'createDate',
            header: 'Date Created',
            headerClassName: 'w-25',
            cell: (row, key) => <td key={key}>{moment.utc(row[key]).format(DATE_FORMAT)}</td>,
            sortable: true,
        },
        {
            key: 'edit',
            header: '',
            cell: (row, key) => (
                <td key={key}>
                    <FAIcon icon={faPencilAlt} />
                </td>
            ),
        },
    ];

    customerColumns: Array<TableColumn<Customer>> = [
        {
            key: 'customerName',
            header: 'Customer Name',
            headerClassName: 'w-50',
            cell: (row, key) => (
                <th key={key} scope="row">
                    {`${row.firstName} ${row.lastName}`}
                </th>
            ),
            sortable: false,
        },
        {
            key: 'address',
            header: 'Address',
            headerClassName: 'w-25',
            cell: (row, key) => <td key={key}>{`${row.address.address1}, ${row.address.state} ${row.address.zip}`}</td>,
            sortable: false,
        },
        {
            key: 'email',
            header: 'Email',
            headerClassName: 'w-25',
            cell: (row, key) => <td key={key}>{row[key]}</td>,
            sortable: false,
        },
        {
            key: 'edit',
            header: '',
            cell: (row, key) => (
                <td key={key}>
                    <FAIcon icon={faPencilAlt} />
                </td>
            ),
        },
    ];

    constructor(props: ProgramSearchProps) {
        super(props);
        this.state = {
            page: 1,
            perPage: UncontrolledDataTable.defaultProps.perPage,
            programResults: [],
            clientCustomerResponse: emptyClientCustomerSearchResultsResponse,
            selectedSearchType: SearchType.Programs,
            isLoading: false,
        };
    }

    async componentDidMount() {
        await this.getInitialData();
    }

    getInitialData = async () => {
        this.setState({ programResults: await this.searchForProgram() });
    };

    searchForProgram = async (searchText = '', page = 1, maxResults = 0): Promise<Program[]> => {
        const { clientId } = this.props.match.params;
        this.setState({ isLoading: true });
        let programResults: SearchResultsResponse<Program> = { totalResults: 0, results: [] };
        try {
            programResults = await OvationsApi.Program.search(clientId, {
                query: searchText,
                page,
                maxResults,
            });
        } catch (error) {
            programResults.results = [];
        }
        this.setState({ isLoading: false });
        return programResults.results;
    };

    updateProgramResults = (programResults: Program[]) => {
        this.setState({ programResults });
    };

    updateClientCustomerResults = (clientCustomerResponse: ClientCustomerSearchResultsResponse) => {
        this.setState({ clientCustomerResponse });
    };

    onSelectedSearchTypeChange = async (selectedSearchType: SearchType) => {
        this.setState({
            selectedSearchType,
            page: 1,
            clientCustomerResponse: emptyClientCustomerSearchResultsResponse,
            programResults: await this.searchForProgram(),
        });
    };

    onSortChange: SortChangeHandler = (sortKey, sortDescending) => {
        let rows = this.state.programResults;
        if (sortKey) {
            rows = sortBy(rows, sortKey);
        }
        if (sortDescending === true) {
            rows = rows.reverse();
        }
        if (!sortDescending && !sortKey) {
            rows = sortBy(rows, 'name');
        }

        this.setState({ programResults: rows, sortKey, sortDescending });
    };

    onProgramRowClick = (row: Program) => {
        const { clientId } = this.props.match.params;
        const editProgramUrl = toPath(routes.CLIENT_PROGRAM_DETAIL, { clientId, programId: row.id });
        this.props.dispatch(push(editProgramUrl));
    };

    onCustomerRowClick = (row: CustomerDataDisplay) => {
        const path = selectors.getCustomerDetailPath(this.props, row.id, row.programId);
        if (path) {
            this.props.dispatch(push(path));
        }
    };

    onMiniPageClick = (page: number) => {
        this.setState({ page });
    };

    getProgramHeader = (programId: number) => {
        const program = this.state.programResults.find((p) => p.number === programId);
        return (
            <tr className="table__row--header">
                <td key={programId} colSpan={5}>
                    {program?.name}: ID - {program?.number}
                </td>
            </tr>
        );
    };

    getProgramId = (programId: number) => {
        const program = this.state.programResults.find((p) => p.number === programId);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return program!.id;
    };

    getFlattenedCustomers = () => {
        const custsToDisplay: CustomerDataDisplay[] = [];
        this.state.clientCustomerResponse.results.forEach((result: ClientCustomerSearchResults) => {
            result.customers.forEach((cust, index) => {
                const customerData = { ...cust, programId: this.getProgramId(result.programId) } as CustomerDataDisplay;
                if (index === 0) {
                    custsToDisplay.push({
                        ...customerData,
                        programHeader: this.getProgramHeader(result.programId),
                    } as CustomerDataDisplay);
                } else {
                    custsToDisplay.push({
                        ...customerData,
                        programHeader: null,
                    });
                }
            });
        });
        return custsToDisplay;
    };

    render() {
        const { state, props } = this;
        const { clientId } = props.match.params;

        return (
            <div className="wrap mt-4">
                <div className="d-flex justify-content-between align-content-center">
                    <div>
                        <h1 className="h3 m-0">Programs</h1>
                    </div>
                    <div>
                        <Authorize permissions={[ClientLevel.ManagePrograms]}>
                            <Link
                                to={toPath(routes.CLIENT_PROGRAM_CREATE, { clientId })}
                                id={elements.programsPage.id.newProgramTrigger}
                            >
                                <CreateButton id="newProgramBtn" name="New Program" />
                            </Link>
                        </Authorize>
                    </div>
                </div>
                <hr />
                <Row>
                    <Col className="d-flex">
                        <div className="flex-grow">
                            <ProgramSearchBar
                                clientId={clientId}
                                updateProgramResults={this.updateProgramResults}
                                updateClientCustomerResults={this.updateClientCustomerResults}
                                onSelectedSearchTypeChange={this.onSelectedSearchTypeChange}
                                page={this.state.page}
                            />
                        </div>
                    </Col>
                    <Col className="text-end">
                        {this.state.selectedSearchType === SearchType.Customers &&
                            this.state.clientCustomerResponse.totalPrograms > 0 && (
                                <span className="fw-bold pe-2">
                                    {this.state.clientCustomerResponse.totalPrograms}{' '}
                                    {this.state.clientCustomerResponse.totalPrograms === 1 ? 'Program' : 'Programs'}
                                </span>
                            )}
                        <div className="d-inline-block">
                            <MiniPaginate
                                page={state.page}
                                perPage={state.perPage}
                                total={
                                    this.state.selectedSearchType === SearchType.Programs
                                        ? this.state.programResults.length
                                        : this.state.clientCustomerResponse.totalResults
                                }
                                onArrowClick={this.onMiniPageClick}
                                noun={this.state.selectedSearchType === SearchType.Programs ? 'Program' : 'Customer'}
                                plural={this.state.selectedSearchType}
                                id={elements.programsPage.id.tablePaginate}
                            />
                        </div>
                    </Col>
                </Row>
                <hr />

                {this.state.selectedSearchType === SearchType.Programs ? (
                    <DataTable
                        columns={this.programColumns}
                        rows={getPage(this.state.programResults, state.page, state.perPage)}
                        sortDescending={this.state.sortDescending}
                        sortKey={this.state.sortKey}
                        onSortChange={this.onSortChange}
                        onRowClick={this.onProgramRowClick}
                        id={elements.programsPage.id.programsDataTable}
                        page={this.state.page}
                        totalPages={Math.ceil(this.state.programResults.length / state.perPage)}
                        onPageClick={this.onMiniPageClick}
                        isLoading={this.state.isLoading}
                    />
                ) : (
                    <DataTable
                        columns={this.customerColumns}
                        rows={getPage(this.getFlattenedCustomers(), state.page, state.perPage)}
                        id={elements.programsPage.id.clientCustomersDataTable}
                        page={this.state.page}
                        totalPages={Math.ceil(this.state.clientCustomerResponse.totalResults / state.perPage)}
                        onPageClick={this.onMiniPageClick}
                        isLoading={this.state.isLoading}
                        emptyLabel="Enter the customer name in the search to search all programs."
                        rowHeaderElement={(row: CustomerDataDisplay) => row.programHeader}
                        onRowClick={this.onCustomerRowClick}
                    />
                )}
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(ProgramSearchContainer);
