import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export interface BreadcrumbTrailItem {
    url?: string;
    label: string;
}

interface BreadcrumbTrailProps {
    breadcrumbs: BreadcrumbTrailItem[];
    className?: string;
}

const BreadcrumbTrail: React.FC<BreadcrumbTrailProps> = (props) => (
    <Breadcrumb className={props.className}>
        {props.breadcrumbs.map(({ url, label }, i) => {
            const isLastItem = i === props.breadcrumbs.length - 1;
            return (
                <BreadcrumbItem key={i} active={isLastItem}>
                    {!!url && !isLastItem ? <Link to={url}>{label}</Link> : label}
                </BreadcrumbItem>
            );
        })}
    </Breadcrumb>
);

export default BreadcrumbTrail;
