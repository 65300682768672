/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface PaginateProps {
    page: number;
    totalPages?: number;
    visiblePages?: number;
    className?: string;
    onPageClick?: (page: number) => void;
}

const defaultProps: Partial<PaginateProps> = {
    visiblePages: 5,
};

const getPagesShown = (props: PaginateProps): number[] => {
    const { page, totalPages } = props;
    const numPagesShowable = Math.min(totalPages!, props.visiblePages!);
    const pagesShown: number[] = [page];
    for (let i = 1; i < numPagesShowable; i++) {
        const rightSideCandidate = page + i;
        const leftSideCandidate = page - i;
        if (rightSideCandidate <= totalPages!) {
            const newLength = pagesShown.push(rightSideCandidate);
            if (newLength >= numPagesShowable) {
                break;
            }
        }
        if (leftSideCandidate > 0) {
            const newLength = pagesShown.unshift(leftSideCandidate);
            if (newLength >= numPagesShowable) {
                break;
            }
        }
    }
    return pagesShown;
};

const Paginate: React.SFC<PaginateProps> = (props) => {
    if (!props.totalPages || props.totalPages < 2) {
        return null;
    }

    const onPageClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        const newPage = Number(e.currentTarget.getAttribute('data-page'));
        if (props.onPageClick && newPage) {
            props.onPageClick(newPage);
        }
    };

    const { page } = props;
    const showFirstAndLast = props.totalPages > props.visiblePages!;
    const isFirstPage = page === 1;
    const isLastPage = page === props.totalPages;
    const pagesShown = getPagesShown(props);
    return (
        <Pagination className={props.className}>
            {showFirstAndLast && (
                <PaginationItem disabled={isFirstPage} className="link-start">
                    <PaginationLink tag="button" type="button" onClick={onPageClick} data-page={1}>
                        First
                    </PaginationLink>
                </PaginationItem>
            )}

            <PaginationItem disabled={isFirstPage}>
                <PaginationLink tag="button" type="button" onClick={onPageClick} data-page={page - 1}>
                    Previous
                </PaginationLink>
            </PaginationItem>

            {pagesShown.map((n) => (
                <PaginationItem key={n} active={n === page}>
                    <PaginationLink tag="button" type="button" onClick={onPageClick} data-page={n}>
                        {n}
                    </PaginationLink>
                </PaginationItem>
            ))}

            <PaginationItem disabled={isLastPage}>
                <PaginationLink tag="button" type="button" onClick={onPageClick} data-page={page + 1}>
                    Next
                </PaginationLink>
            </PaginationItem>

            {showFirstAndLast && (
                <PaginationItem disabled={isLastPage} className="link-end">
                    <PaginationLink tag="button" type="button" onClick={onPageClick} data-page={props.totalPages}>
                        Last
                    </PaginationLink>
                </PaginationItem>
            )}
        </Pagination>
    );
};
Paginate.defaultProps = defaultProps;

export default Paginate;
