import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import {
    SalesIncentive,
    SalesIncentiveSearchRequest,
    SearchResultsResponse,
    StaticPointsRewardCalculation,
} from 'core/ovations-api/definitions';
import * as Enums from 'core/ovations-api/enums';
import SalesIncentiveApi from 'core/ovations-api/SalesIncentive';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import SalesIncentiveState from 'redux-modules/definitions/SalesIncentiveState';

export const initialState = {
    map: {},
    totalResults: 0,
};

export const emptyStaticPointsRewardCalculation: StaticPointsRewardCalculation = {
    id: 0,
    points: null,
    label: null,
    type: Enums.RewardCalculationType.StaticPoints,
    alternatePayeeRewardPackageId: null,
    alternatePayeeRewardPackageType: null,
    rewardPackageName: '',
    rewardPackages: [],
    sortOrder: 0,
};

export const emptySalesIncentive: SalesIncentive = {
    id: '',
    number: 0,
    name: '',
    salesStart: null,
    salesEnd: null,
    state: Enums.SalesIncentiveState.Draft,
    programId: '',
    ownerName: '',
    calculationType: Enums.SalesIncentiveCalculationType.PerUnit,
    contactNumber: '',
    contactName: '',
    contactEmail: '',
    estimatedRedemptions: null,
    poBox: '',
    poNumber: '',
    suiteNumber: '',
    callCenterTollFreeNumber: '',
    requiresPrefunding: false,
    rewardCalculation: emptyStaticPointsRewardCalculation,
    totalQuantity: undefined,
};

const { reducer, update } = createReducer('salesIncentive/UPDATE', initialState);
export const salesIncentiveReducer = reducer;

export const actions = {
    update,

    fetchSingle(
        clientId: string,
        programId: string,
        salesIncentiveId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const salesIncentive = await SalesIncentiveApi.fetch(clientId, programId, salesIncentiveId);
            dispatch(actions.upsertSalesIncentive(clientId, salesIncentive));
        };
    },

    search(
        clientId: string,
        programId: string,
        request: Partial<SalesIncentiveSearchRequest>,
    ): ThunkAction<Promise<SearchResultsResponse<SalesIncentive>>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const response = await SalesIncentiveApi.search(clientId, programId, request);
            const map = keyBy(response.results, 'id');
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: response.totalResults })));
            return response;
        };
    },

    createSalesIncentive(
        clientId: string,
        programId: string,
        salesIncentive: SalesIncentive,
    ): ThunkAction<Promise<SalesIncentive>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const newSalesIncentive = await SalesIncentiveApi.create(clientId, programId, salesIncentive);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const totalResults = ctx.salesIncentive.totalResults + 1;
            dispatch(actions.upsertSalesIncentive(clientId, newSalesIncentive));
            dispatch(clientContextManager.action(clientId, update({ totalResults })));
            return newSalesIncentive;
        };
    },

    updateSalesIncentive(
        clientId: string,
        programId: string,
        salesIncentive: SalesIncentive,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await SalesIncentiveApi.update(clientId, programId, salesIncentive);
            dispatch(actions.upsertSalesIncentive(clientId, salesIncentive));
        };
    },

    upsertSalesIncentive(clientId: string, salesIncentive: SalesIncentive): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.salesIncentive.map, [salesIncentive.id]: salesIncentive };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

export const selectors = {
    getList: createSelector([(state: SalesIncentiveState) => state.map], (map) => values(map)),
};
