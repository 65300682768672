import { push } from 'connected-react-router';
import { LocationListener } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { matchPath, Redirect, Route, Switch } from 'react-router';

import { InCallContainer, PatternsContainer } from 'asyncComponents/components';
import Notifications from 'components/Notifications';
import * as routes from 'config/routes';
import PotentialDuplicateContainer from 'containers/claims/PotentialDuplicateContainer';
import { ContainerProps } from 'containers/Container';
import HomeContainer from 'containers/HomeContainer';
import LoginContainer from 'containers/LoginContainer';
import PortalMissingFieldsContainer from 'containers/portal/PortalMissingFieldsContainer';
import { L10nProvider } from 'core/l10n/components';
import enMessages from 'core/l10n/messages/en-US.json';
import AIAnalytics from 'core/lib/analytics/AIAnalytics';
import toPath from 'core/lib/toPath';
import PrivateRoute from 'lib/PrivateRoute';
import * as notification from 'redux-modules/notification';
import * as settings from 'redux-modules/settings';

export class App extends React.Component<ContainerProps> {
    async componentDidMount() {
        this.props.history.listen(this.onLocationChange);
        const appSettings = await this.props.dispatch(settings.actions.get());
        /* istanbul ignore next */
        if (process.env.NODE_ENV === 'production') {
            AIAnalytics.configure(appSettings.appInsightsInstrumentationKey);
            AIAnalytics.trackPageView();
            this.props.history.listen(() => AIAnalytics.trackPageView());
        }
    }

    // Added this via timeout because all other methods of waiting for DOM to fully load were inconsistent
    async componentDidUpdate() {
        setTimeout(() => {
            this.disableScrollForInputs();
        }, 200);
    }

    // Ensures the scroll wheel on the mouse doesn't change our inputs
    disableScrollForInputs = () => {
        if (document.readyState === 'complete') {
            const scrollableElements = document.querySelectorAll('input[type="number"]');
            scrollableElements.forEach((item: HTMLElement) => {
                item.addEventListener('wheel', (e) => {
                    e.preventDefault();
                    return false;
                });
            });
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onLocationChange: LocationListener = (location, action) => {
        if (this.props.call.activeCallState) {
            const isNavigatingAwayFromActiveCall = !matchPath(location.pathname, { path: routes.IN_CALL });
            if (isNavigatingAwayFromActiveCall) {
                const { clientId, programId, customer } = this.props.call.activeCallState;
                const redirectUrl = toPath(routes.IN_CALL, { clientId, programId, customerId: customer.id });
                this.props.dispatch(push(redirectUrl));
            }
        }
    };

    onDismissNotification = (id: number) => {
        this.props.dispatch(notification.actions.remove(id));
    };

    render() {
        const { pathname } = this.props.history.location;
        if (/[A-Z]/.test(pathname)) {
            return <Redirect to={pathname.toLowerCase()} />;
        }
        return (
            <L10nProvider locale="en" messages={enMessages}>
                <>
                    {/* react-intl Provider crashes if it has more than one immediate descendent */}
                    <Switch>
                        <Route path="/login" component={LoginContainer} />
                        <Route path="/patterns" component={PatternsContainer} />
                        <PrivateRoute path={routes.IN_CALL} component={InCallContainer} />
                        <Route path={routes.VIEW_DUPE_ATTACHMENTS} component={PotentialDuplicateContainer} />
                        <Route path={routes.VIEW_MISSING_FIELDS} component={PortalMissingFieldsContainer} />
                        <PrivateRoute path="/" component={HomeContainer} />
                    </Switch>
                    <Notifications items={this.props.notification.items} onDismiss={this.onDismissNotification} />
                </>
            </L10nProvider>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(App);
