/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Button, Input, InputGroup, Modal, ModalBody, ModalHeader, Progress } from 'reactstrap';

import elements from 'config/elements';
import * as messages from 'config/messages';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';

interface UserCreatorProps {
    isOpen: boolean;
    onAddSuccess: (newUserId: string, adResult: OvationsApi.Types.ActiveDirectoryResult) => void;
    onModalClose: () => void;
}

interface UserCreatorState {
    idBeingAdded: string | undefined;
    error: string | undefined;
    isSearching: boolean;
    results: OvationsApi.Types.ActiveDirectoryResult[] | undefined;
    searchTerm: string;
    searchedTerm: string;
}

export default class UserCreator extends React.Component<UserCreatorProps, UserCreatorState> {
    constructor(props: UserCreatorProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            idBeingAdded: undefined,
            error: undefined,
            isSearching: false,
            results: undefined,
            searchTerm: '',
            searchedTerm: '',
        };
    }

    onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({ searchTerm: e.currentTarget.value });
    };

    onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        this.search();
    };

    onResultClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        const id = e.currentTarget.getAttribute('data-id');
        if (!id) {
            return;
        }
        this.addUser(id);
    };

    async search() {
        let error;
        let { results } = this.state;
        const { searchTerm } = this.state;
        if (!searchTerm) {
            return;
        }
        this.setState({ isSearching: true, searchedTerm: searchTerm, error });
        try {
            results = await OvationsApi.ActiveDirectory.search(searchTerm);
        } catch (e) {
            if (e.response && e.response.status === 403) {
                error = messages.insufficientPermissions();
            } else {
                error = messages.adSearchFailure();
            }
        }

        this.setState({ error, results, isSearching: false });
    }

    async addUser(id: string) {
        let error;
        this.setState({ idBeingAdded: id, error });
        try {
            const adResult = this.state.results && this.state.results.find((result) => result.id === id);
            const newUserId = await OvationsApi.ActiveDirectory.import(id);
            this.setState(this.getInitialState());
            this.props.onAddSuccess(newUserId, adResult!);
        } catch (e) {
            if (e.response && e.response.status === 403) {
                error = messages.insufficientPermissions();
            } else {
                error = messages.adAddFailure();
            }
            this.setState({ idBeingAdded: undefined, error });
        }
    }

    renderResult(result: OvationsApi.Types.ActiveDirectoryResult) {
        const { idBeingAdded } = this.state;
        const shouldDisableAll = idBeingAdded !== undefined;
        let actionText: string | JSX.Element = result.wasAdded ? 'Added' : 'Add';
        if (result.id === idBeingAdded) {
            actionText = <FAIcon icon={faCircleNotch} className="spin" />;
        }
        return (
            <button
                key={result.id}
                data-id={result.id}
                className={classNames('list-group-item', elements.newUserModal.class.addUserTrigger, {
                    clickable: !shouldDisableAll && !result.wasAdded,
                    'list-group-item-action secret-trigger': !result.wasAdded,
                })}
                onClick={this.onResultClick}
                disabled={result.wasAdded || shouldDisableAll}
            >
                <span className="d-flex justify-content-between align-items-center">
                    <span className={classNames({ 'text-primary': !result.wasAdded })}>
                        {result.firstName} {result.lastName}
                    </span>
                    <span
                        className={classNames({
                            'secret-trigger__secret': !shouldDisableAll,
                            invisible: !result.wasAdded && shouldDisableAll && idBeingAdded !== result.id,
                        })}
                    >
                        <Button
                            tag="span"
                            size="sm"
                            color={result.wasAdded ? 'light' : 'primary'}
                            disabled={result.wasAdded}
                        >
                            {actionText}
                        </Button>
                    </span>
                </span>
            </button>
        );
    }

    render() {
        const { results } = this.state;
        return (
            <Modal className={elements.newUserModal.class.modal} isOpen={this.props.isOpen} autoFocus={false}>
                <ModalHeader toggle={this.props.onModalClose} tag="h5">
                    New O-vations User
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={this.onSubmit}>
                        <InputGroup>
                            <Input
                                id={elements.newUserModal.id.userName}
                                autoFocus
                                type="search"
                                placeholder="e.g. John Smith"
                                value={this.state.searchTerm}
                                onChange={this.onChange}
                            />
                            <Button
                                id={elements.newUserModal.id.submit}
                                color="light"
                                disabled={!this.state.searchTerm}
                            >
                                Search Active Directory
                            </Button>
                        </InputGroup>
                    </form>
                    {this.state.error && <div className="text-danger pt-3">{this.state.error}</div>}
                    {(results !== undefined || this.state.isSearching) && (
                        <div className="card mt-3">
                            <div className="scrollable scrollable--short">
                                {this.state.isSearching ? (
                                    <Progress animated value={100} className="progress--squared" />
                                ) : results && !results.length ? (
                                    <div className="p-3 no-results">{messages.noUsers(this.state.searchedTerm)}</div>
                                ) : (
                                    <div className="list-group list-group-flush list-group--tight">
                                        {results!.map((result) => this.renderResult(result))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}
