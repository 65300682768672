import { AnyAction, Dispatch } from 'redux';

import createReducer from 'core/lib/createReducer';
import { AppSettings, Types } from 'core/ovations-api';
import SettingsState from 'redux-modules/definitions/SettingsState';

export const emptyLanguage: Types.Language = {
    cultureCode: '',
    name: '',
};

const initialState: SettingsState = {
    appInsightsInstrumentationKey: '',
    featureFlags: {},
    supportedCountries: [],
    supportedLanguages: [],
};
const { reducer, update } = createReducer('settings/UPDATE', initialState);
export const settingsReducer = reducer;

export const actions = {
    get:
        () =>
        async (dispatch: Dispatch<AnyAction>): Promise<SettingsState> => {
            const settings = await AppSettings.get();
            dispatch(update(settings));
            return settings;
        },
};
