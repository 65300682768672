import { flatten, keyBy, sortBy, uniq, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { BatchType, RewardPackageType } from 'core/ovations-api/enums';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import RewardPackageState from 'redux-modules/definitions/RewardPackageState';
import S from 'redux-modules/definitions/RootState';

type RewardPackage = OvationsApi.Types.RewardPackage;

export const emptyRewardPackage: RewardPackage = {
    id: '',
    name: '',
    cardEnginePackageId: '',
    reportId: '',
    projectedMailDateSLAInDays: null,
    customerFacingName: '',
    programId: '',
    isArchived: false,
    isUsed: false,
    rewardPackageType: null,
    notes: '',
};

export const initialState: RewardPackageState = {
    map: {},
    activeRewardTypes: [],
};

const { reducer, update } = createReducer<RewardPackageState>('rewardPackage/UPDATE', initialState);
export const rewardPackageReducer = reducer;

export const actions = {
    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.RewardPackage.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const rewardPackages = keyBy(list, 'id');
            const map = { ...ctx.rewardPackage.map, ...rewardPackages };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    fetchAllTypes(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const activeRewardTypes = await OvationsApi.RewardPackage.fetchAllTypes(clientId, programId);
            dispatch(clientContextManager.action(clientId, update({ activeRewardTypes })));
        };
    },

    createNewRewardPackage(
        clientId: string,
        rewardPackage: RewardPackage,
    ): ThunkAction<Promise<string>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newId = await OvationsApi.RewardPackage.create(clientId, rewardPackage);
            const newRewardPackage = { ...rewardPackage, id: newId };
            dispatch(actions.upsertRewardPackage(clientId, newRewardPackage));
            return newId;
        };
    },

    updateRewardPackage(
        clientId: string,
        rewardPackage: RewardPackage,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.RewardPackage.update(clientId, rewardPackage);
            dispatch(actions.upsertRewardPackage(clientId, rewardPackage));
        };
    },

    upsertRewardPackage(clientId: string, rewardPackage: RewardPackage): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.rewardPackage.map, [rewardPackage.id]: rewardPackage };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector([(state) => state.map], (map) => values(map));

const getListForProgram = createSelector([(programId: string) => programId], (programId: string) =>
    createSelector([getList], (rewardPackages) =>
        rewardPackages.filter((rewardPackage) => rewardPackage.programId === programId),
    ),
);

const getActiveRewardTypes = createSelector([(state) => state.activeRewardTypes], (activeRewardTypes) => {
    return activeRewardTypes;
});

const getAvailableBatchTypes = createSelector([(programId: string) => programId], (programId: string) =>
    createSelector([getListForProgram(programId)], (rewardPackages) => {
        const activeRewardPackages = rewardPackages.filter((rp) => !rp.isArchived);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const activeRewardPackageTypes = uniq(activeRewardPackages.map((rp) => rp.rewardPackageType!));

        // eslint-disable-next-line array-callback-return
        // tslint:disable-next-line: cyclomatic-complexity
        const mappedTypes = activeRewardPackageTypes.map((rewardPackageType): BatchType[] => {
            switch (rewardPackageType) {
                case RewardPackageType.SingleLoadUSA:
                    return [BatchType.SingleLoadUSA];
                case RewardPackageType.SingleLoadCAN:
                    return [BatchType.SingleLoadCAN];
                case RewardPackageType.ReloadableUSA:
                    return [BatchType.ReloadableUSA];
                case RewardPackageType.ReloadableCAN:
                    return [BatchType.ReloadableCAN];
                case RewardPackageType.ReloadableCANVisa:
                    return [BatchType.ReloadableCANVisa];
                case RewardPackageType.VirtualUSA:
                    return [BatchType.VirtualUSA];
                case RewardPackageType.CheckUSA:
                    return [BatchType.CheckUSA];
                // We are currently not supporting the Galileo types
                // case RewardPackageType.SingleLoadUSAGalileo:
                //     return [BatchType.SingleLoadUSAGalileo];
                // case RewardPackageType.ReloadableUSAGalileo:
                //     return [BatchType.ReloadableUSAGalileo];
                case RewardPackageType.RetailCard:
                    return [BatchType.Retail];
                default:
                    return [];
            }
        });
        const sortPriority = Object.keys(BatchType);
        const availableBatchTypes = sortBy(flatten(mappedTypes), (batchType) => sortPriority.indexOf(batchType));
        return uniq([...availableBatchTypes, BatchType.Replacements]);
    }),
);

export const selectors = {
    getList,
    getListForProgram,
    getActiveRewardTypes,
    getAvailableBatchTypes,
};
