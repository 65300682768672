export enum Color {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
    Light = 'light',
    Dark = 'dark',
}
