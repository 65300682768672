/* tslint:disable max-line-length */
export default {
    global: {
        class: {
            next: 'next',
            prev: 'prev',
        },
        pageTitle: {
            cssSelector: 'h1',
        },
        modal: {
            class: {
                close: 'close',
                confirm: 'confirm',
                cancel: 'cancel',
            },
        },
    },
    globalHeader: {
        id: {
            searchBar: 'global-header__search-bar',
            searchInput: 'global-header__search-input',
            searchButton: 'global-header__search-button',
            searchResults: 'global-header__search-results',
            homeButton: 'global-header__home-button',
        },
        class: {
            globalSettingsDropdown: 'global-settings-dropdown',
            username: 'global-header__username',
            newUserTrigger: 'global-header__new-user-trigger',
            newClientTrigger: 'new-client-trigger',
            clientsDropdown: 'clients-dropdown',
            manageClientDropdown: 'manage-client-dropdown',
            homeLink: 'global-header__home-link',
        },
        cssSelector: {
            searchResult: '#global-header__search-bar .dropdown-item',
        },
    },
    cancelConfirm: {
        class: {
            cancel: 'cancel-confirm__cancel',
            submit: 'cancel-confirm__submit',
            abandon: 'cancel-confirm__abandon',
            continue: 'cancel-confirm__continue',
        },
    },
    imageUploader: {
        class: {
            addFile: 'image-uploader__add-file',
            preview: 'image-uploader__preview',
            removeFile: 'image-uploader__remove-file',
            removalModal: 'image-uploader__removal__modal',
        },
    },
    clientNav: {
        class: {
            sidebar: 'client-nav',
            programDropdown: 'client-nav__program-dropdown',
            clientLogo: 'client-nav__client-logo',
            programLinks: 'client-nav__program-links',
        },
    },
    subNav: {
        class: {
            trigger: 'sub-nav__trigger',
            item: 'sub-nav__item',
        },
    },
    notification: {
        class: {
            success: 'card--accent--success',
            error: 'card--accent--danger',
        },
    },
    formValidation: {
        class: {
            invalidFeedback: 'invalid-feedback',
            invalidIcon: 'invalid-icon',
        },
    },
    customerTypeahead: {
        class: {
            customerName: 'global-header__customer-name',
            customerEmail: 'global-header__customer-email',
            newCustomer: 'customer-typeahead__new-customer',
        },
    },
    usersPage: {
        class: {
            filterByStatusDropdown: 'users-page__filter-by-status-dropdown',
        },
        id: {
            activeCheckbox: 'users-page__active-checkbox',
            archivedCheckbox: 'users-page__archived-checkbox',
            dataTable: 'users-page__data-table',
            tablePaginate: 'users-page__table-paginate',
            searchBox: 'users-page__search-box',
            newUserTrigger: 'users-page__new-user-trigger',
        },
    },
    newPromotionModal: {
        class: {
            modal: 'promotion-creator',
        },
        id: {
            loadingSpinner: 'new-promotion__loading-spinner',
            noPromotionsAvailable: 'new-promotion__no-promotions-available',
            promotionsList: 'new-promotion__promotions-list',
            promotionName: 'new-promotion__name',
            submit: 'new-promotion__submit',
            cancel: 'new-promotion__cancel',
            copyExistingButton: 'new-promotion__copy-existing',
            newPromotionButton: 'new-promotion__new-promotion',
            claimSubmissionPromotionTypeRadio: 'new-promotion__type-claimSubmission',
            redemptionPromotionTypeRadio: 'new-promotion__type-redemption',
        },
        cssSelector: {
            close: '.promotion-creator .close',
        },
    },
    newUserModal: {
        class: {
            modal: 'user-creator',
            addUserTrigger: 'new-user__add',
        },
        id: {
            userName: 'new-user__name',
            submit: 'new-user__submit',
        },
        cssSelector: {
            close: '.user-creator .close',
        },
    },
    userSettingsPage: {
        class: {
            userStatusDropdown: 'user-settings__status-dropdown',
            userStatusToggle: 'user-settings__status-toggle',
        },
        id: {
            userName: 'user-settings__name',
            userEmail: 'user-settings__email',
            saveChanges: 'user-settings__save-changes',
            backButton: 'user-settings__back',
            userPermissionsTab: 'platform-permissions',
            userClientRolesTab: 'client-roles',
            userHistoryTab: 'history',
            userPermissions: 'user-settings__permissions',
            userClientRoleTable: 'user-settings__client-role-table',
        },
        cssSelector: {
            breadcrumbName: '.active.breadcrumb-item',
        },
        xpath: {
            permissionsCheckbox: '//*[@id="user-settings__permissions"]//label[text()="CHECKBOX_LABEL"]//input',
        },
    },
    archiveUserModal: {
        class: {
            modal: 'user-archiver',
        },
        id: {
            cancelArchiveTrigger: 'user-settings__cancel-archive',
            archiveTrigger: 'user-settings__archive',
        },
        cssSelector: {
            close: '.user-archiver .close',
        },
    },
    editUserClientRolesModal: {
        class: {
            modal: 'user-client-roles-editor',
        },
        id: {
            userClientRoles: 'user-client-roles-editor__client-roles',
        },
        cssSelector: {
            close: '.user-client-roles-editor .close',
        },
        xpath: {
            clientRolesCheckbox:
                '//*[@id="user-client-roles-editor__client-roles"]//label[text()="CHECKBOX_LABEL"]//input',
        },
    },
    newClientModal: {
        class: {
            modal: 'client-creator',
        },
        id: {
            clientName: 'new-client__name',
            submit: 'new-client__submit',
        },
        cssSelector: {
            close: '.client-creator .close',
        },
    },
    confirmNewClientModal: {
        class: {
            modal: 'confirm-new-client',
        },
        id: {
            name: 'confirm-new-client__name',
            message: 'confirm-new-client__message',
            cancel: 'confirm-new-client__cancel',
            submit: 'confirm-new-client__submit',
        },
        cssSelector: {
            close: '.confirm-new-client .close',
        },
    },
    clientSettingsPage: {
        id: {
            clientName: 'client-settings__name',
            logoUpload: 'client-settings__client-logo',
            logoPreview: 'client-settings__logo-preview',
            removeLogo: 'client-settings__remove-logo-trigger',
            confirmCancel: 'client-settings__logo-cancel',
            confirmRemove: 'client-settings__logo-remove',
            saveChanges: 'client-settings__save-changes',
            backButton: 'client-settings__back',
            cardEngineClientId: 'client-settings__card-engine-client-id',
        },
    },
    programsPage: {
        id: {
            newProgramTrigger: 'programs__new-program-trigger',
            searchPrograms: 'programs__search',
            tablePaginate: 'programs__table-paginate',
            programsDataTable: 'programs__data-table',
            clientCustomersDataTable: 'programs__clientCustomers-data-table',
        },
        cssSelector: {
            previousPage: '#programs__table-paginate .prev',
            nextPage: '#programs__table-paginate .next',
        },
        xpath: {
            tableRow: '//*[@id="programs__data-table"]//th[text()="PROGRAM_NAME"]/parent::tr',
        },
    },
    programDetailsPage: {
        class: {
            programDetailBreadcrumbs: 'progDetail-breadcrumbs',
            modal: 'data-set-creator',
            questionModal: 'question-creator',
            questionModalDisplayAsCurrency: 'question-creator__display-as-dollars',
            questionModalDataSetSelectLabel: 'question-creator__data-set-label',
            questionModalDataSetSelectColumn: 'question-creator__data-set-column',
            questionModalDataSetSelectAdd: 'question-creator__data-set-add',
            questionModalDataSetSelectDelete: 'question-creator__data-set-delete',
            questionModalDataSetSelectMove: 'question-creator__data-set-move',
        },
        id: {
            selectProgramPrompt: 'select-program-prompt',
            programName: 'program-details__name',
            pager: 'program-details__pager',
            back: 'program-details__back',
            saveChanges: 'program-details__save-changes',
            questionLibraryTab: 'program-details__question-library-tab',
            rewardPackagesTab: 'program-details__reward-packages-tab',
            callTypesTab: 'program-details__call-types-tab',
            fileImportTab: 'program-details__promotion-claim-import',
            apiKeysTab: 'program-details__api-keys-tab',
            dataSetsTab: 'program-details__data-sets-tab',
            documentsTab: 'program-details__documents-tab',
            reasonCodesTab: 'program-details__reason-codes-tab',
            languagesTab: 'program-details__languages-tab',
            promotionGroupsTab: 'program-details__promotion-groups-tab',
            programId: 'program-details__id',
            questionModalQuestion: 'question-creator__question',
            questionModalDescription: 'question-creator__description',
            questionModalHelpTextFieldset: 'question-creator__help-text',
            questionModalDataSetSelect: 'question-creator__data-set',
            questionModalDataSetRefreshTrigger: 'question-creator__refresh-trigger',
            questionModalCreateDataSetTrigger: 'question-creator__create-trigger',
            questionModalUnformattedRadio: 'question-creator__unformatted',
            questionModalFormattedRadio: 'question-creator__formatted',
            questionModalFormatField: 'question-creator__format',
            questionModalError: 'question-creator__error',
            questionModalReportingGroup: 'question-creator__group',
            questionModalInternalDescription: 'question-creator__internal-description',
            questionModalPreview: 'question-creator__preview',
            headerStatusBadge: 'program-details__portal-status-badge',
            maxFileSizeMb: 'program-details__max-file-size-mb',
        },
        cssSelector: {
            previousProgram: '#program-details__pager .prev',
            nextProgram: '#program-details__pager .next',
        },
    },
    programDataSets: {
        id: {
            dataTable: 'program-data-sets__data-table',
            pager: 'program-data-sets__pager',
            searchBar: 'program-data-sets__search-bar',
            createTrigger: 'program-data-sets__create-data-set-trigger',
            modalFileName: 'data-set-creator__file-name',
            modalFileUploadTrigger: 'data-set-creator__file-trigger',
            modalSelectUniqueColumn: 'data-set-creator__select-unique',
            modalNewDataPoints: 'data-set-creator__new-data-points',
            modalCancel: 'data-set-creator__cancel',
            modalSubmit: 'data-set-creator__submit',
            download: 'data-set__download',
        },
        cssSelector: {
            modalClose: '.data-set-creator .close',
            questionModalClose: '.question-creator .close',
            previousDataSet: '#program-data-sets__pager .prev',
            nextDataSet: '#program-data-sets__pager .next',
            updateMetric: '.data-set-creator__new-data-points li',
        },
        class: {
            modal: 'data-set-creator',
            uploadError: 'upload-error',
            edit: 'data-set-edit',
        },
    },
    programQuestions: {
        id: {
            dataTable: 'program-questions__data-table',
            pager: 'program-questions__pager',
            searchBar: 'program-questions__search-bar',
        },
        class: {
            typeFilterDropdown: 'program-questions__filter-dropdown',
            newQuestionDropdown: 'program-questions__new-question-dropdown',
        },
        xpath: {
            typeFilter: `
        //*[contains(@class, "program-questions__filter-dropdown")]
        //*[text()="LABEL"]
      `,
            newQuestion: `
        //*[contains(@class, "program-questions__new-question-dropdown")]
        //*[text()="LABEL"]
      `,
        },
    },
    programDocuments: {
        id: {
            dataTable: 'program-documents__data-table',
            pager: 'program-documents__pager',
            searchBar: 'program-documents__search-bar',
            fileUploadTrigger: 'program-documents__file-trigger',
        },
        class: {
            copyLink: 'program-documents__copy-link',
            fileUpdate: 'program-documents__file-update',
        },
    },
    programRewardPackages: {
        id: {
            dataTable: 'program-reward-packages__data-table',
            createTrigger: 'program-reward-packages__create-trigger',
            modalRewardPackageName: 'reward-package-creator__reward-package-name',
            modalcardEnginePackageId: 'reward-package-creator__card-engine-package-id',
            modalReportId: 'reward-package-creator__report-id',
            modalProjectedMailDate: 'reward-package-creator__mail-id',
            modalCustomerFacingName: 'reward-package-creator__customer_name-id',
            modalNotes: 'reward-package-creator__notes',
        },
        cssSelector: {
            modalClose: '.reward-package-creator .close',
        },
        class: {
            rewardTypeInput: 'reward-package-creator__reward-type-input',
            modal: 'reward-package-creator',
            statusDropdown: 'reward-package-creator__status-dropdown',
            statusToggle: 'reward-package-creator__status-toggle',
        },
    },
    programPromotionGroups: {
        id: {
            dataTable: 'program-promotion-group__data-table',
            createTrigger: 'program-promotion-group__create-trigger',
            deleteButton: 'promotiongroup-delete',
        },
        cssSelector: {
            modalClose: '.promotion-group-creator .close',
        },
        class: {
            rewardTypeInput: 'promotion-group-creator__reward-type-input',
            modal: 'promotion-group-creator',
            statusDropdown: 'promotion-group-creator__status-dropdown',
            statusToggle: 'promotion-group-creator__status-toggle',
        },
    },
    programPromotionGroupDetail: {
        id: {
            promotionGroupName: 'promotion-group-detail__promotion-group-name',
            back: 'promotion-group-detail__back',
            saveChanges: 'promotion-group-detail__save-changes',
            dollarLimitNone: 'promotion-group-detail__dollar-limit-none',
            dollarLimitDollar: 'promotion-group-detail__dollar-limit-dollar',
            dollarLimitAmount: 'promotion-group-detail__dollar-limit-amount',
            promotionGroupForm: 'promotion-group-detail__form',
            pointsLimitNone: 'promotion-group-detail__points-limit-none',
            pointsLimitPoints: 'promotion-group-detail__points-limit-points',
            pointsLimitAmount: 'promotion-group-detail__points-limit-amount',
            quantityLimitToggle: 'promotion-group-detail__quantity-limit-toggle',
            quantityLimitAmount: 'promotion-group-detail__quantity-limit-amount',
            measurementLabel: 'promotion-group-detail__measurement-label',
            measurementQuestion: 'promotion-group-detail__measurement-question',
        },
    },
    programFileImport: {
        id: {
            dataTable: 'program-file-import__data-table',
            fileImportTableHeading: 'program-file-import__file-import-table-heading',
            fileImportType: 'program-file-import__file-import-type',
            fileUploader: 'program-file-import__file-uploader',
            importTypeTooltip: 'program-file-import__import-type-tooltip',
        },
    },
    promotionSearch: {
        id: {
            newPromotion: 'promotion-search__new-promotion',
            query: 'promotion-search__query',
            origin: 'promotion-search__origin',
            rangeSize: 'promotion-search__range-size',
            date: 'promotion-search__date',
            table: 'promotion-search__table',
            pager: 'promotion-search__pager',
        },
        class: {
            stateDropdown: 'promotion-search__state-dropdown',
        },
    },
    promotionDetailsPage: {
        id: {
            promotionForm: 'promotion-details__form',
            promotionName: 'promotion-details__name',
            customerFacingName: 'promotion-details__customer-facing-name',
            pager: 'promotion-details__pager',
            back: 'promotion-details__back',
            saveChanges: 'promotion-details__save-changes',
            timelineTab: 'promotion-details__promotion-timeline-tab',
            redemptionTimelineTab: 'promotion-details__promotion-redemption-timeline-tab',
            claimTab: 'promotion-details__promotion-claim-tab',
            rewardsTab: 'promotion-details__rewards-tab',
            rewardLimitsTab: 'promotion-details__reward-limits-tab',
            eligibilityTab: 'promotion-details__eligibility-tab',
            billingTab: 'promotion-details__billing-tab',
            promotionStateLabel: 'promotion-details__promotion-state-label',
            promotionStateDate: 'promotion-details__promotion-state-date',
            requirementsModalTrigger: 'promotion-details__requirements-modal-trigger',
            requirementsModal: 'promotion-details__requirements-modal',
            submitForReview: 'promotion-details__submit-for-review',
            confirmSubmitForReviewModal: 'promotion-details__confirm-submit-for-review-modal',
            missingTranslationsWarning: 'promotion-details__missing-translations-warning',
            validationBypassTooltip: 'promotion-details__validation-bypass-tooltip',
            validationBypassToggle: 'promotion-details__validation-bypass-toggle',
            referralConfirmationTooltip: 'promotion-details__referral-confirmation-tooltip',
            referralConfirmationToggle: 'promotion-details__referral-confirmation-toggle',
        },
        class: {
            promotionStateDropdown: 'promotion-details__promotion-state-dropdown',
        },
    },
    claimFormTab: {
        id: {
            termsAndConditionsValue: 'claim-form-tab__tac-value',
            eSignDisclosureValue: 'claim-form-tab__esign-value',
            prepaidClaimsToggle: 'claim-form-tab__prepaid-claims-toggle',
            bulkImportToggle: 'claim-form-tab__bulk-import-toggle',
            displayOnPortalHistoryToggle: 'claim-form-tab__displayOnPortalHistory-toggle',
            displayOnPortalHistoryLabel: 'claim-form-tab__displayOnPortalHistory-label',
            termsAndConditionsToggle: 'claim-form-tab__terms-and-conditions-toggle',
            termsHeading: 'claim-form__terms-heading',
            termsBody: 'claim-form__terms-body',
        },
        class: {
            configureFormTrigger: 'claim-form-tab__configure',
            questionName: 'claim-form-tab__question-name',
            questionDate: 'claim-form-tab__question-date',
            questionDisplayOnPortalHistory: 'claim-form-tab__question-displayOnPortalHistory',
            needsReview: 'claim-form-tab__needs-review',
        },
    },
    claimFormConfigurator: {
        id: {
            editOrder: 'claim-form__edit-order',
            cancelRemove: 'claim-form__cancel-remove',
            cancelSort: 'claim-form__cancel-sort',
            saveSort: 'claim-form__save-sort',
            editTerms: 'claim-form__edit-terms',
            editEsign: 'claim-form__edit-esign',
            termsPreview: 'claim-form__terms-preview',
            toggleTermsRequired: 'claim-form__toggle-terms-required',
            eSignPreview: 'claim-form__esign-preview',
            toggleEsignRequired: 'claim-form__toggle-esign-required',
            eSignAgreementUrl: 'claim-form__esign-text-link',
            addQuestions: 'claim-form__add-questions',
        },
        class: {
            back: 'claim-form__back',
            question: 'claim-form__question',
            removeQuestion: 'claim-form__remove-question',
            editQuestion: 'claim-form__edit-question',
            movePromotionQuestion: 'claim-form__move',
        },
    },
    rewardsTab: {
        id: {
            rewardAmountInput: 'rewards-tab__reward-amount',
            rewardPackageSelect: 'rewards-tab__reward-package',
            alternateRewardPackageSelect: 'rewards-tab_alternate-reward-package',
            alternateRewardPackageToggle: 'rewards-tab_alternate-reward-package-toggle',
            associatedQuestionSelect: 'rewards-tab__associated-question',
            addChoiceReward: 'rewards-tab__add-choice-reward',
            removeChoiceRewardModal: 'rewards-tab__remove-choice-reward-modal',
            rewardPercentage: 'rewards-tab__reward-percentage',
            percentagePreview: 'rewards-tab__percentage-preview',
            householdLimitNone: 'rewards-tab__household-limit-none',
            householdLimitRewards: 'rewards-tab__household-limit-rewards',
            householdLimit: 'rewards-tab__household-Limit',
            householdLimitReset: 'rewards-tab__household-Limit-Reset',
            householdLimitResetSelect: 'rewards-tab__household-Limit-Select',
            householdLimitResetTooltip: 'rewards-tab__household-Limit-Reset-tooltip',
            householdLimitHeaderResetTooltip: 'rewards-tab__household-Limit-Header-Reset-tooltip',
        },
        class: {
            removeChoiceReward: 'rewards-tab__remove-choice-reward',
            rewardType: 'rewards-tab__reward-type',
            rewardAmountInput: 'rewards-tab__reward-amount',
            rewardLabel: 'rewards-tab__reward-label',
            choiceRewardBlock: 'rewards-tab__choice-reward-block',
            associatedQuestionCheckbox: 'rewards-tab__associated-question-checkbox',
            questionPreview: 'rewards-tab__reward-question-preview',
        },
        cssSelector: {
            rewardTypeStatic: '.rewards-tab__reward-type[value="Static"]',
            rewardTypeVariable: '.rewards-tab__reward-type[value="Variable"]',
            rewardTypeUserDefined: '.rewards-tab__reward-type[value="UserDefined"]',
        },
    },
    rewardLimitsTab: {
        id: {
            promotionGroupSelect: 'reward-limits-tab__promotion-group',
            quantityQuestionSelect: 'reward-limits-tab__quantity-question',
            itemName: 'reward-limits-tab__item-name',
            customerProfileFieldSelect: 'reward-limits-tab__customer-profile-field',
            showValueButton: 'reward-limits-tab__show-value-button',
            errorBannerNoNumberTypeQuestion: 'reward-limits-tab__error-banner-no-number-type-question',
            errorBannerMoreThanFiveValues: 'reward-limits-tab__error-banner-more-than-five-values',
            rewardTypeInfo: 'reward-limits-tab__reward-type-info',
        },
        class: {
            rewardLimitType: 'rewards-limits-tab__reward-limit-type',
        },
    },
    billingTab: {
        id: {
            taxableToggle: 'billing-tab__taxable-toggle',
        },
    },
    redemptionTimelineTab: {
        id: {
            portalRedemptExpir_toolTip: 'redemptionTimeline-tab__claimRedemptionExpiration-toolTip',
            portalRedemption_input: 'redemptionTimeline-tab__claimRedemptionExpiration-input',
            platformRedemption_timelimit: 'redemptionTimeline-tab__redemptionTimeLimit-input',
        },
    },
    promotionQuestionOptions: {
        id: {
            optionsModal: 'promotion-question-fieldset__options-modal',
            optionsInput: 'promotion-question-fieldset__options-input',
            existingOptions: 'promotion-question-fieldset__existing-options',
        },
        class: {
            unformattedTextPrompt: 'promotion-question-fieldset__unformatted-text-prompt',
            datePrompt: 'promotion-question-fieldset__date-prompt',
            fileUploadPrompt: 'promotion-question-fieldset__file-upload-prompt',
            numberPrompt: 'promotion-question-fieldset__number-prompt',
            dropdownPrompt: 'promotion-question-fieldset__dropdown-prompt',
            uneditableReason: 'promotion-question-fieldset__uneditable-reason',
            dateTimeframeType: 'promotion-question-fieldset__date-timeframe-type',
        },
    },
    dashboardPage: {
        id: {
            quickLinks: 'dashboard__quick-links',
            welcomeMessage: 'dashboard__welcome-message',
        },
    },
    promotionQuestionModal: {
        id: {
            searchBar: 'promotion-questions__search-bar',
            emptyResultsQuestionListButton: 'promotion-question-creator__empty-results-button',
            addQuestionToPromotion: 'promotion-question-creator__add',
            savePromotionQuestions: 'promotion-question-creator__save',
        },
        class: {
            promotionQuestionModal: 'promotion-question-creator',
            deletePromotionQuestion: 'promotion-question-creator__delete',
            movePromotionQuestion: 'promotion-question-creator__move',
        },
    },
    promotionQuestionEditor: {
        class: {
            hideOnPortal: 'promotion-question-editor__hide-on-portal',
            readOnlyOnPortal: 'promotion-question-editor__readonly-on-portal',
            requiredOnPortal: 'promotion-question-editor__required-on-portal',
            readOnlyOnCallCenter: 'promotion-question-editor__readonly-on-callcenter',
            requiredOnCallCenter: 'promotion-question-editor__required-on-callcenter',
            validateOnCallCenter: 'promotion-question-editor__validate-on-callcenter',
            checkForDuplicate: 'promotion-question-editor__check-for-duplicate',
        },
    },
    portalConfig: {
        id: {
            tagManagerInput: 'portal-config__tag-manager-input',
            tagManagerTooltip: 'portal-config__tag-manager-tooltip',
            headerStatusBadge: 'portal-configurator-header--badge',
            headerUrl: 'portal-configurator-header--url',
            headerUrlTooltip: 'portal-configurator-header--url-tooltip',
            saveChanges: 'portal-configurator--save-changes',
            titleTooltip: 'portal-configurator--title-tooltip',
            titleInput: 'portal-configurator--title-input',
            enableTrigger: 'portal-configurator--enable-trigger',
            urlInput: 'portal-configurator--url-input',
            enableConfirmTrigger: 'portal-configurator--enable-confirm-trigger',
            enableCancelTrigger: 'portal-configurator--enable-cancel-trigger',
            failureBackTrigger: 'portal-configurator--failure-back-trigger',
            failureCancelTrigger: 'portal-configurator--failure-cancel-trigger',
            successConfirmTrigger: 'portal-configurator--success-confirm-trigger',
            logoUploaderArea: 'portal-config__logo-uploader-area',
            favIconUploaderArea: 'portal-config__favicon-uploader-area',
            pwaUploaderArea: 'portal-config__pwa-uploader-area',
            logoImage: 'portal-config__logo-image',
            favIconImage: 'portal-config__favicon-image',
            logoPreview: 'portal-config__logo-preview',
            favIconPreview: 'portal-config__favicon-preview',
            logoRemoveButton: 'portal-config__logo-remove-button',
            favIconRemoveButton: 'portal-config__favicon-remove-button',
            enableSms: 'portal-config__enable-sms',
            optInForFutureOffersInput: 'portal-config__future-offers-opt-in-input',
            enableOptInForFutureOffers: 'portal-config__enable-future-offers-opt-in',
            viewMissingFieldsTrigger: 'portal-config__view-missing-fields-trigger',
            legacyUrlInput: 'portal-configurator--legacyurl-input',
            noAccessError: 'portal-config__no-access-error',
            noAccessErrorToggle: 'portal-config__no-access-error-toggle',
            noAccessErrorTooltip: 'portal-config__no-access-error-tooltip',
            noRegistrationTooltip: 'portal-configurator--no-registration-tooltip',
            noRegistrationToggle: 'portal-config__no-registration-toggle',
            ssoTooltip: 'portal-configurator--sso-tooltip',
            editProfileToggle: 'portal-configurator--edit-profile-toggle',
            dealerNumberNoDataSets: 'portal-config__dealer-number-no-data-sets',
            dealerNumberToggle: 'portal-config__dealer-number-toggle',
            dealerNumberDataSet: 'portal-config__dealer-number-data-set',
            dealerNumberColumn: 'portal-config__dealer-number-column',
            openIdToggle: 'portal-config--open-id-toggle',
            samlToggle: 'portal-config--saml-toggle',
            samlIssuer: 'portal_config--saml-Issuer',
            openIdClientId: 'portal-config--open-id-client-id',
            addPortalRole: 'portal-config--add-portal-role',
            liveChatToggle: 'portal-configurator--live-chat-toggle',
            liveChatWidgetId: 'portal-configurator--live-chat-widget-id',
            languageSelector: 'portal-config__language-selector',
            customFieldMessage: 'portal-config__custom-field-message',
            loginFields: 'portal-config__login-fields',
            dealerRegistrationFieldLabel: 'portal-config__dealer-registration-field-label',
            requirePasswordToggle: 'portal-config__require-password-toggle',
            requirePasswordTooltip: 'portal-config__require-password-tooltip',
        },
        class: {
            modal: 'portal-enabler',
            imageRemoveCancel: 'portal-config__image-remove-cancel',
            imageRemoveConfirm: 'portal-config__image-remove-confirm',
            missingFieldsItem: 'portal-config__missing-fields-item',
            removePortalRole: 'portal-config--remove-portal-role',
            portalRoleInput: 'portal-config--portal-role-input',
            country: 'portal-config__country',
        },
        cssSelector: {
            close: '.portal-enabler .close',
        },
        xpath: {
            sidebarLink: '//*[contains(@class, "portal__nav")]//*[text()="LINK_TEXT"]',
            input: '//*[text()="LABEL_TEXT"]/..//input',
            enableCheckboxWithInput: '//*[text()="LABEL_TEXT"]/ancestor::label/preceding-sibling::input',
            enableCheckbox: '//label[text()="LABEL_TEXT"]/input',
            textarea: '//*[text()="LABEL_TEXT"]/..//textarea',
        },
    },
    portalFooterEditor: {
        id: {
            facebook: 'portal-footer-editor__facebook',
            instagram: 'portal-footer-editor__instagram',
            linkedIn: 'portal-footer-editor__linked-in',
            pinterest: 'portal-footer-editor__pinterest',
            twitter: 'portal-footer-editor__twitter',
            yelp: 'portal-footer-editor__yelp',
            youTube: 'portal-footer-editor__you-tube',
            wordPress: 'portal-footer-editor__word-press',
            linkTooltip: 'portal-footer-editor__link-tooltip',
        },
        class: {
            linkEditor: 'portal-footer-editor__link-editor',
            linkEnabler: 'portal-footer-editor__link-enabler',
            linkText: 'portal-footer-editor__link-text',
            linkUrl: 'portal-footer-editor__link-url',
            iconPreview: 'portal-footer-editor__icon-preview',
        },
    },
    portalContactEditor: {
        id: {
            contactNumberInput: 'portal-contact-editor__contact-number-input',
            hoursOfOperationTextArea: 'portal-contact-editor__hours-of-operation-text-area',
            receivingEmailAddressInput: 'portal-contact-editor__receiving-email-address-input',
        },
        class: {
            questionCategory: 'portal-contact-editor__question-category',
        },
    },
    portalDashboardEditor: {
        class: {
            removeImageModal: 'portal-dashboard-editor__remove-image-modal',
            imageAltTextInput: 'portal-dashboard-editor__alt-text-input',
            dashpartRotatorImageRemoveTrigger: 'portal-dashboard-editor__dashpart-rotator-image-remove',
            dashpartRotatorImage: 'portal-dashboard-editor__dashpart-rotator-image',
            optionalUrlInput: 'portal-dashboard-editor__optional-url-input',
            pointTrackerRoleCheckRow: 'portal-dashboard-editor__point-tracker-role-check-row',
            yoyTrackerRoleCheckRow: 'portal-dashboard-editor__yoy-tracker-role-check-row',
        },
        id: {
            imageRotatorHeadingInput: 'portal-dashboard-editor__image-rotator-heading-input',
            dashpartRotatorImageUploader: 'portal-dashboard-editor__image-uploader',
            removeImageModalRemoveTrigger: 'portal-dashboard-editor__remove-image-modal-remove',
            removeImageModalKeepTrigger: 'portal-dashboard-editor__remove-image-modal-keep',
            pointTrackerToggle: 'portal-dashboard-editor__point-tracker-toggle',
            pointTrackerTitleInput: 'portal-dashboard-editor__point-tracker-title-input',
            pointTrackerGoalInput: 'portal-dashboard-editor__point-tracker-goal-input',
            pointPermissionsLabel: 'portal-dashboard-editor__point-tracker-permissions-label',
            yoyTrackerToggle: 'portal-dashboard-editor__yoy-tracker-toggle',
            yoyTrackerTitleInput: 'portal-dashboard-editor__yoy-tracker-title-input',
            yoyTrackerGoalInput: 'portal-dashboard-editor__yoy-tracker-goal-input',
            yoyTrackerUnitInput: 'portal-dashboard-editor__yoy-tracker-unit-input',
            yoyPermissionsLabel: 'portal-dashboard-editor__yoy-tracker-permissions-label',
        },
    },
    portalProfileSettings: {
        class: {
            customFieldEnabler: 'portal-profile-settings__custom-field-enabler',
            customFieldDisplayedLabel: 'portal-profile-settings__custom-field-displayed-label',
            taxFormToggleModal: 'portal-profile-settings__tax-form-toggle-modal',
        },
        id: {
            taxFormToggle: 'portal-profile-tax-form-toggle',
            taxFormToggleConfirm: 'portal-profile-tax-form-toggle-confirm',
            taxFormTooltip: 'portal-profile-tax-form-tooltip',
            configureProfileFieldsToolTip: 'portal-profile-config-profile-fields-tooltip',
            reviewInformationTooltip: 'portal-profile-review-information-tooltip',
            reviewInfoToggle: 'portal-profile-review-information-toggle',
            communicationPreferences: {
                commCustEditSelectAll: 'communicationCustomerEditSelectAll',
                requireCommCustInputSelectAll: 'requireCommunicationCustomerInputSelectAll',
                commPlatformEditSelectAll: 'communicationPlatformEditSelectAll',
                requireCommPlatformInputSelectAll: 'requireCommunicationPlatformInputSelectAll',
            },
            userInfo: {
                userInfoCustEditSelectAll: 'userInfoCustomerEditSelectAll',
                requireUserInfoCustInputSelectAll: 'requireUserInfounicationCustomerInputSelectAll',
                userInfoPlatformEditSelectAll: 'userInfoPlatformEditSelectAll',
                requireUserInfoPlatformInputSelectAll: 'requireUserInfoPlatformInputSelectAll',
            },
        },
    },
    portalPointsCatalogEditor: {
        id: {
            sharedSecretInput: 'portal-points-catalog__shared-secret-input',
            programGuidInput: 'portal-points-catalog__program-guid-input',
            endpointInput: 'portal-points-catalog__endpoint-input',
            urlInput: 'portal-points-catalog__url-input',
            enableInput: 'portal-points-catalog__enable-input',
            modal: 'portal-points-catalog__modal',
            dollarsPerPoint: 'portal-points-catalog__dollars-per-point',
        },
        class: {
            modal: 'portal-points-catalog__modal',
        },
    },
    portalTierSets: {
        id: {
            tierSetName: 'portal-tierSets__name-input',
            tierSetDetailBackBtn: 'portal-tierSets__back_btn',
            tierSetDetailSaveBtn: 'portal-tierSets__save_btn',
            tierSetDetailMoveToReadyBtn: 'portal-tierSets__move-to-ready-btn',
            tierSetDetailDisplayToggle: 'portal-tierSets__display-toggle',
            tierSetDetailStartDate: 'portal-tierSets__start-date',
            tierSetDetailEndDate: 'portal-tierSets__end-date',
            tierSetDetailRewardName: 'portal-tierSets__reward-name',
            tierSetDetailPointGoal: 'portal-tierSets__point-goal',
            tierSetDetailRewardDetails: 'portal-tierSets__reward-details',
        },
    },
    promotionalContent: {
        class: {
            removeSectionModal: 'promotional-content-remover',
            removeImageModal: 'promotional-content-image-remover',
            removePromotionSection: 'promotional-content__remove',
            movePromotionSection: 'promotional-content__move',
        },
        id: {
            advertisementImage: 'promotional-content__advertisement-image',
            sectionHeader: 'promotional-content__section-header',
            titleInput: 'promotional-content__title-input',
            subtitleInput: 'promotional-content__subtitle-input',
            bodyInput: 'promotional-content__body-input',
            uploadImageButton: 'promotional-content__upload-image-button',
            removeImageButton: 'promotional-content__remove-image-button',
            imageAltTextInput: 'promotional-content__image-alt-text-input',
            timelinePicker: 'promotional-content__timeline-picker',
            card: 'promotional-content__card',
            cardAddContentTrigger: 'promotional-content__card-add-content-trigger',
            cancelRemoveSectionTrigger: 'promotional-content__cancel-remove',
            removeSectionTrigger: 'promotional-content__remove',
            cancelRemoveImageTrigger: 'promotional-content__cancel-remove',
            removeImageTrigger: 'promotional-content__remove',
            optionalUrlInput: 'promotional-content__optional-url-input',
        },
    },
    portalNotification: {
        class: {
            emailSubject: 'portal-notification__email-subject',
            emailBody: 'portal-notification__email-body',
            smsContent: 'portal-notification__sms-content',
            smsLink: 'portal-notification__sms-link',
            activator: 'portal-notification__activator',
            emailTestTrigger: 'portal-notification__email-test-trigger',
            smsTestTrigger: 'portal-notification__sms-test-trigger',
        },
        id: {
            portalNotificationForm: 'portal-notification__form',

            // Communication sections
            registration: 'portal-notification__registration',
            mailInRegistration: 'portal-notification___mail-in-registration',
            claimSubmission: 'portal-notification__claim-submission',
            claimIncomplete: 'portal-notification__claim-incomplete',
            claimAdditionalIncomplete: 'portal-notification__claim-additional-incomplete',
            claimDisqualification: 'portal-notification__claim-disqualification',
            passwordReset: 'portal-notification__password-reset',
            passwordChanged: 'portal-notification__password-changed',
            referredIndividualConfirmation: 'portal-notification__referred-Individual-Confirmation',
            readyToRedeem: 'portal-notification__ready-to-redeem',
            rewardFulfillment: 'portal-notification__reward-fullfillment',
            accountUpdate: 'portal-notification__account-update',

            // Test email/sms modal
            testModal: 'portal-notification__test',
            testRecipient: 'portal-notification__test-recipient',
            testSendTrigger: 'portal-notification__test-send-trigger',
            testCancelTrigger: 'portal-notification__test-cancel-trigger',
            testAcknowledgeTrigger: 'portal-notification__test-acknowledge-trigger',
        },
        cssSelector: {
            close: '.portal-notification__test .close',
            testTriggerExample: '#portal-notification__registration .portal-notification__sms-test-trigger',
        },
    },
    promotionEligiblity: {
        class: {
            selectGroup: 'promotion-eligibility__select-group',
            removeGroup: 'promotion-eligibility__remove-group',
            modal: 'promotion-eligibility__modal',
        },
        id: {
            addGroup: 'promotion-eligiblity__add-group',
            addProfileFieldButton: 'promotion-eligiblity__add-profile-field-button',
            addProfileFieldModalDropdown: 'promotion-eligiblity__add-profile-field-modal-dropdown',
            addProfileFieldModalRadio: 'promotion-eligiblity__add-profile-field-modal-radio',
            addProfileFieldModalTypeahead: 'promotion-eligiblity__add-profile-field-modal-typeahead',
            list: 'promotion-eligiblity__list',
            name: 'promotion-eligiblity__name',
            removeGroupModal: 'promotion-eligiblity__remove-group-modal',
            removeGroupRemove: 'promotion-eligiblity__remove-group-remove',
            saveErrorModalOkayButton: 'promotion-eligiblity__save-error-modal-okay-button',
        },
    },
    portalFAQ: {
        class: {
            selectSection: 'portal-faq__select-section',
            removeSection: 'portal-faq__remove-section',
            moveSection: 'portal-faq__move-section',
            removeItem: 'portal-faq__remove-item',
            editItem: 'portal-faq__edit-item',
        },
        id: {
            sectionList: 'portal-faq__section-list',
            addSection: 'portal-faq__add-section',
            sectionTitle: 'portal-faq__section-title',
            itemList: 'portal-faq__item-list',
            additem: 'portal-faq__add-item',
            itemModal: 'portal-faq__item-modal',
            itemTitle: 'portal-faq__item-title',
            itemRichEditor: 'portal-faq__item-rich-editor',
            removeItemModal: 'portal-faq__remove-item-modal',
            removeItemRemove: 'portal-faq__remove-item-remove',
            removeItemKeep: 'portal-faq__remove-item-keep',
            removeSectionModal: 'portal-faq__remove-section-modal',
            removeSectionRemove: 'portal-faq__remove-section-remove',
            removeSectionKeep: 'portal-faq__remove-section-keep',
        },
        cssSelector: {
            close: '#portal-faq__item-modal .close',
            removeItemclose: '#portal-faq__remove-item-modal .close',
            removeSectioclose: '#portal-faq__remove-section-modal .close',
            newItemCancel: '#portal-faq__item-modal .cancel-confirm__cancel',
            newItemSave: '#portal-faq__item-modal .cancel-confirm__submit',
            newItemAbandon: '#portal-faq__item-modal .cancel-confirm__abandon',
            newItemContinue: '#portal-faq__item-modal .cancel-confirm__continue',
        },
    },
    reports: {
        class: {
            roleCheckbox: 'reports__role-checkbox',
            selectReport: 'reports__select-report',
        },
        id: {
            addModalCancel: 'reports__add-modal-cancel',
            addModalConfirm: 'reports__add-modal-confirm',
            addModal: 'reports__add-modal',
            powerBiId: 'reports__power-bi-id',
            deleteModal: 'reports__delete-modal',
            deleteModalCancel: 'reports__delete-modal-cancel',
            deleteModalConfirm: 'reports__delete-modal-confirm',
            roles: 'reports__roles',
            allRolesCheckbox: 'reports__all-roles-checkbox',
            reportsList: 'reports__reports-list',
            reportName: 'reports__report-name',
            triggerAddModal: 'reports__trigger-add-modal',
            triggerDeleteModal: 'reports__trigger-delete-modal',
        },
    },
    portalResponsePage: {
        id: {
            portalReferralResponsePageForm: 'portal-referral-response-page__referral',
            portalReferralResponseToolTip: 'portal-referral-response-page__toolTip',
            portalResponseContentEditorQuestionText: 'portal-response-content-editor__questionText',
            portalResponseContentEditorConfirmationText: 'portal-response-content-editor__confirmationText',
            portalResponseContentEditorDeclineText: 'portal-response-content-editor__declineText',
        },
    },
    customerDetail: {
        id: {
            enableDisableDropdown: 'customer-detail--enable-disable-dropdown',
            backLink: 'customer-detail-form__back-link',
            form: 'customer-detail-form__form',
            firstName: 'customer-detail-form__first-name',
            lastName: 'customer-detail-form__last-name',
            dateOfBirth: 'customer-detail-form__date-of-birth',
            dealerNumber: 'customer-detail-form__dealer-number',
            email: 'customer-detail-form__email',
            phoneNumber: 'customer-detail-form__phone',
            addressCertification: 'customer-detail-form__address-certification',
            country: 'customer-detail-form__country',
            address1: 'customer-detail-form__address-1',
            address2: 'customer-detail-form__address-2',
            city: 'customer-detail-form__city',
            state: 'customer-detail-form__state',
            zip: 'customer-detail-form__zip',
            preferredLanguage: 'customer-detail-form__preferredLanguage',
            preferEmail: 'customer-detail-form__prefer-email',
            preferSMS: 'customer-detail-form__prefer-sms',
            preferredContactField: 'customer-detail-form__preferred-contact-field',
            optInCheckbox: 'customer-detail-form__opt-in-checkbox',
            passwordResetButton: 'customer-detail-form__password-reset-button',
            passwordResetModal: 'customer-detail-form__password-reset-modal',
            claimList: 'customer-detail-form__claim-list',
            rewardsList: 'customer-detail-form__rewards-list',
            appeasementsList: 'customer-detail-form__appeasements-list',
            ledgersList: 'customer-detail-form__ledgers-list',
            taxableMailingAddressEnabled: 'customer-detail-form__taxable-mailing-address-enabled',
            legalName: 'customer-detail-form__legal-name',
            taxAddress1: 'customer-detail-form__tax-address1',
            taxCity: 'customer-detail-form__tax-city',
            taxState: 'customer-detail-form__tax-state',
            taxZipCode: 'customer-detail-form__tax-zip-code',
            editSSN: 'customer-detail-form__edit-ssn',
            ssnModalCancel: 'customer-detail-form__ssn-modal-cancel',
            ssnModalUpdate: 'customer-detail-form__ssn-modal-update',
        },
        class: {
            enableDisableOption: 'customer-detail--enable-disable-option',
            saveButton: 'customer-detail-form__save-button',
            rewardResult: 'customer-detail__rewardResult',
            rewardResultStatus: 'customer-detail__reward-result-status',
            appeasementResult: 'customer-detail__appeasement-result',
            pager: 'customer-detail-form__pager',
            customerSsnModal: 'customer-detail-form__customer-ssn-modal',
        },
    },
    inCallHeader: {
        id: {
            headerForm: 'in-call-header__form',
            callTypeDropdown: 'in-call-header__call-type-dropdown',
            dispositionDropdown: 'in-call-header__disposition-dropdown',
            promotionDropdown: 'in-call-header__promotion-dropdown',
            claimIdDropdown: 'in-call-header__claim-id-dropdown',
            notesField: 'in-call-header__notes-field',
            saveAndEndButton: 'in-call-header__save-and-end-button',
            saveButton: 'in-call-header__save-button',
            timerWindow: 'in-call-header__timer-window',
            unfinishedCallModal: 'in-call-header__unfinished-call-modal',
        },
    },
    callTypes: {
        class: {
            callTypePicker: 'call-type__call-type-picker',
            callDispositionPicker: 'call-type__call-disposition-picker',
            callTypeDragHandle: 'call-type-drag-handle',
            callTypeListItemButton: 'call-type-list-button',
            callTypeEditDispositionButton: 'call-type-disposition-edit-trigger',
        },
        id: {
            addDispositionButton: 'call-type__add-disposition-trigger',
            addCallTypeButton: 'call-type__add-call-type-trigger',
            callTypeFormInput: 'call-type__form--input',
            callTypeFormSave: 'call-type__form--save',
            callTypeFormCancel: 'call-type__form--cancel',
            callDispositionFormInput: 'call-disposition__form--input',
            callDispositionFormSave: 'call-disposition__form--save',
            callDispositionFormCancel: 'call-disposition__form--cancel',
            dispositionWarningModal: 'call-disposition__warning-modal',
            dispositionWarningModalButton: 'call-disposition__warning-modal--button',
        },
    },
    reasons: {
        id: {
            incompleteDaysCheckbox: 'reasons__incomplete-days-checkbox',
            incompleteDaysCount: 'reasons__incomplete-days-count',
        },
        class: {
            addReasonButton: 'reasons__add-reason-button',
            nameInput: 'reasons__name-input',
            activeCheckbox: 'reasons__active-checkbox',
            label: 'reasons__label',
        },
    },
    customerHistory: {
        id: {
            conversationsButton: 'customer-history__conversations',
            updatesButton: 'customer-history__updates',
            allButton: 'customer-history__all',
        },
    },
    claimHistoryTabs: {
        id: {
            claimsHistory: 'customer-history__claims-history',
            rewardsDetails: 'customer-history__rewards-details',
        },
    },
    saveButtonGroup: {
        class: {
            dropdownToggle: 'save-button-group__dropdown-toggle',
        },
    },
    claimsPage: {
        id: {
            header: 'claims-page__header',
            pager: 'claims-page__pager',
            claimNumberInputForm: 'claims-page__claim-number-input-form',
            promotionFilterDropdown: 'claims-page__promotion-filter-dropdown',
            statusFilterDropdown: 'claims-page__status-filter-dropdown',
            dateRangeDropdown: 'claims-page__date-range-dropdown',
            datePicker: 'claims-page__date-picker',
            table: 'claims-page__table',
        },
        class: {
            claimSearchResult: 'claims-page__claim-search-result',
            searchResultHeading: 'claims-page__search-result-heading',
            searchResultSubheading: 'claims-page__search-result-subheading',
            searchResultLastChanged: 'claims-page__search-result-last-changed',
            statusFilter: 'claims-page__status-filter',
        },
    },
    claimDetail: {
        id: {
            batchExclusionWarning: 'claim-detail__batch-exclusion-warning',
            termsAndConditionsButton: 'claim-detail__terms-and-conditions-button',
            termsAndConditionsWindow: 'claim-detail__terms-and-conditions-window',
            submissionDate: 'claim-detail__submission-date',
            submittedBy: 'claim-detail__submitted-by',
            claimStatus: 'claim-detail__claim-status',
            reason: 'claim-detail__reason',
            notes: 'claim-detal__notes',
            customerAddress: 'claim-detail__customer-address',
            backLink: 'claim-detail__back-link',
            saveChanges: 'claim-detail__save-changes',
            editButton: 'claim-detail__edit-button',
            document: 'claim-detail__document',
            documentPager: 'claim-detail__document-pager',
            documentZoomIn: 'claim-detail__document-zoom-in',
            documentZoomOut: 'claim-detail__document-zoom-out',
            documentRotateLeft: 'claim-detail__document-undo',
            documentRotateRight: 'claim-detail__document-redo',
            claimDetailsTab: 'claim-detail__claim-details-tab',
            claimHistoryTab: 'claim-detail__claim-history-tab',
            claimRewardsDetailTab: 'claim-detail__claim-rewards-detail-tab',
            claimReconciliationTab: 'claim-detail__claim-reconciliation-tab',
            disqualifyClaim: 'claim-detail__disqualify-claim',
            potentialDuplicateModal: 'claim-detail__potential-duplicate-modal',
            cancelDuplicateModal: 'claim-detail__cancel-potential-duplicate-modal',
            confirmDuplicate: 'claim-detail__confirm-duplicate',
            documentDownload: 'claim-detail__document-download',
            reconciliationStatus: 'claim-detail__reconciliation-status',
            redemptionModalCancel: 'claim-detail__redemption-modal-cancel',
            requireProofOfPurchase: 'claim-detail__require-proof-of-purchase',
            selectedReward: 'claim-detail__selected-reward',
            saveAndMarkRedeem: 'claim-detail__save-and-mark-redeem',
            invalidAddressTooltip: 'claim-detail__invalid-address-tooltip',
            sendReminderButton: 'claim-detail__send-reminder-button',
            taxablePromotion: 'claim-detail__taxable-promotion',
        },
        class: {
            attachmentViewerLaunch: 'claim-detail__attachment-badge',
            inCall: 'claim-detail__in-call',
            claimQuestions: 'claim-detail__claim-questions',
            processRedemptionModal: 'claim-detail__claim-questions',
        },
    },
    claimValidation: {
        id: {
            pager: 'claim-validation__pager',
            saveButton: 'claim-validation__save-button',
        },
    },
    claimMailIn: {
        id: {
            searchBar: 'claim-mail-in__search-bar',
            searchInput: 'claim-mail-in__typeahead-input',
            promotionSelect: 'claim-mail-in__promotion-select',
            submissionDate: 'claim-mail-in__submission-date',
            clearForm: 'claim-mail-in__clear-form',
            createClaim: 'claim-mail-in__create-claim',
        },
    },
    lockedContent: {
        class: {
            modal: 'locked-content__modal',
            message: 'locked-content__message',
        },
    },
    fulfillmentSettingsPage: {
        id: {
            appeasementSettingsTab: 'fulfillment-settings__appeasement-settings-tab',
            batchJobsTab: 'fulfillment-settings__batch-jobs-tab',
            saveChanges: 'fulfillment-settings__save-changes',
            backButton: 'fulfillment-settings__back-button',
        },
    },
    batchJobs: {
        id: {
            searchBatchJobs: 'batch-jobs__search',
            typeFilterDropdown: 'batch-jobs__type-filter-dropdown',
            tablePaginate: 'batch-jobs__table-paginate',
            dataTable: 'batch-jobs__data-table',
        },
        cssSelector: {
            previousPage: '#batch-jobs__table-paginate .prev',
            nextPage: '#batch-jobs__table-paginate .next',
            tableRow: '#batch-jobs__data-table tbody tr',
        },
    },
    batchConfigurationCreatorModal: {
        class: {
            newBatchJobTrigger: 'batch-configuration-creator__new-batch-job-trigger',
            newBatchJobDropdown: 'batch-configuration-creator__new-batch-job-dropdown',
        },
        id: {
            batchTypeInput: 'batch-configuration-creator__batch-type',
            jobDescriptionInput: 'batch-configuration-creator__job-description',
            recurrenceDayOfMonth: 'batch-configuration-creator__recurrence-day-of-month',
            recurrenceDaysOfWeek: 'batch-configuration-creator__recurrence-days-of-week',
            recurrenceInput: 'batch-configuration-creator__recurrence-input',
            recurrenceRunIntervalPeriodDropdown: 'batch-configuration-creator__recurrence-run-interval-period-dropdown',
            startDateInput: 'batch-configuration-creator__start-date',
            minimumCardAmount: 'batch-configuration-creator__minimum-card-amount',
            minimumCardAmountInput: 'batch-configuration-creator__minimum-card-amount-input',
            conflictModal: 'batch-configuration-creator__conflict-modal',
            nonCertifiedAddressesToggle: 'batch-configuration-creator__non-certified-address-toggle',
            nonCertifiedAddressesToggleTooltip: 'batch-configuration-creator__non-certified-address-toggle-tooltip',
        },
    },
    batchList: {
        class: {
            batchSearchResult: 'batch-list__batch-search-result',
            statusFilterDropdown: 'batch-list__status-filter-dropdown',
            jobDescriptionDropdown: 'batch-list__job-description-dropdown',
        },
        id: {
            pager: 'batch-list__pager',
            table: 'batch-list__table',
        },
    },
    batchDetailsPage: {
        id: {
            activityContainer: 'batch-details__activity-container',
            backButton: 'batch-details__back-button',
            approveDataControlTrigger: 'batch-details__approve-data-control',
            cancelBatchTrigger: 'batch-details__cancel-batch-trigger',
            requestFundsTrigger: 'batch-details__request-funds-trigger',
            verifyFundsTrigger: 'batch-details__verify-funds-trigger',
            fundsReceivedTrigger: 'batch-details__funds-received-trigger',
            approveForExportTrigger: 'batch-details__approve-for-export-trigger',
            appeasementsTab: 'batch-details__batch-appeasements-tab',
            claimsTab: 'batch-details__batch-claims-tab',
            customerListTab: 'batch-details__customer-list-tab',
            detailsTab: 'batch-details__batch-details-tab',
            pager: 'batch-details__minipager',
            promotionsContainer: 'batch-details__promotions-container',
            statusLabel: 'batch-details__status-label',
            typeLabel: 'batch-details__type-label',
            summaryContainer: 'batch-details__summary-container',
        },
    },
    batchClaimsTab: {
        class: {
            claimSearchResult: 'batch-claims-tab__claim-search-result',
        },
        id: {
            pager: 'batch-claims-tab__pager',
            table: 'batch-claims-tab__table',
            searchInput: 'batch-claims-tab__search-input',
            searchForm: 'batch-claims-tab__search-form',
            clearSearchButton: 'batch-claims-tab__clear-search',
        },
    },
    batchAppeasementsTab: {
        class: {
            appeasementSearchResult: 'batch-appeasements-tab__appeasement-search-result',
        },
        id: {
            pager: 'batch-appeasements-tab__pager',
            table: 'batch-appeasements-tab__table',
            filterDropdown: 'batch-appeasements-tab__filter-dropdown',
            filterToggle: 'batch-appeasements-tab__filter-toggle',
        },
    },
    claimHistory: {
        class: {
            claimHistory: 'claim-history',
            claimHistoryItem: 'claim-history__item',
        },
    },
    batchActionModal: {
        class: {
            modal: 'batch-action-modal__modal',
        },
        id: {
            notesInput: 'batch-action-modal__notes-input',
            cancelBatchTrigger: 'batch-action-modal__cancel-batch-trigger',
            dismissModalTrigger: 'batch-action-modal__dismiss-modal-trigger',
            statusChangeTrigger: 'batch-action-modal__status-change-trigger',
            rewardPackageWarning: 'batch-action-modal__reward-package-warning',
        },
    },
    addressCertificationLandingPage: {
        id: {
            emptyQueueMesssage: 'address-certificiation-mgmt__empty-queue-message',
            beginQueueButton: 'address-certificiation-mgmt__begin-queue-button',
            searchInput: 'address-certificiation-mgmt__search-input',
            searchBar: 'address-certificiation-mgmt__search-bar',
        },
    },
    addressCertification: {
        id: {
            existingCountry: 'address-certification-queue__existing-country',
            existingAddress1: 'address-certification-queue__existing-address-address1',
            existingAddress2: 'address-certification-queue__existing-address-address2',
            existingAddressCity: 'address-certification-queue__existing-address-city',
            existingAddressState: 'address-certification-queue__existing-address-state',
            existingAddressZip: 'address-certification-queue__existing-address-zip',
            suggestedCountry: 'address-certification-queue__suggested-country',
            suggestedAddress1: 'address-certification-queue__suggested-address-address1',
            suggestedAddress2: 'address-certification-queue__suggested-address-address2',
            suggestedAddressCity: 'address-certification-queue__suggested-address-city',
            suggestedAddressState: 'address-certification-queue__suggested-address-state',
            suggestedAddressZip: 'address-certification-queue__suggested-address-zip',
            noSuggestionMessage: 'address-certification-queue__no-suggestion-message',
            certifiedBadge: 'address-certification-queue__certified-badge',
            verifyButton: 'address-certification-queue__verify-button',
            saveButton: 'address-certification-queue__save-button',
            statusDropdown: 'address-certification-queue__status-dropdown',
        },
    },
    rewardDetail: {
        id: {
            backButton: 'reward-detail__back-button',
            issueReplacementButton: 'reward-detail__issue-replacement-button',
            resendEmailButton: 'reward-detail__resend-email-button',
            resendEmailModal: 'reward-detail__resend-email-modal',
            associatedAppeasements: 'reward-detail__associated-appeasements',
            associatedClaims: 'reward-detail__associated-claims',
            historyTab: 'reward-detail__history-tab',
            claimsTab: 'reward-detail__claims-tab',
            appeasementsTab: 'reward-detail__appeasements-tab',
            otherItemsTable: 'reward-detail__other-items-table',
            claimNumberInputForm: 'customer-reward-claims__claim-number-input-form',
            claimNumberFilter: 'customer-reward-claims__claim-number-filter',
            claimNumberFilterButton: 'customer-reward-claims__claim-number-filter__button',
            miniPager: 'customer-reward-claims__mini-pager',
            reloadEventFilter: 'customer-reward-claims__reload-event-filter',
        },
        class: {
            historyItemTitle: 'reward-detail__history-item-title',
            historyItemDateTime: 'reward-detail__history-item-date-time',
            historyItemUser: 'reward-detail__history-item-user',
            historyItemAddress: 'reward-detail__history-item-address',
            historyItemReload: 'reward-detail__history-item-reload',
            appeasementTableRows: 'reward-detail__associated-appeasements--row',
            claimTableRows: 'reward-detail__associated-claims--row',
            replacementStatus: 'reward-detail__replacement-status',
        },
    },
    rewardReplacementActionModal: {
        id: {
            modal: 'reward-replacement-action-modal',
            cancelRequest: 'reward-replacement-action-modal__cancel',
            submitRequest: 'reward-replacement-action-modal__submit',
        },
    },
    customerList: {
        class: {
            customerSearchResult: 'customer-list__customer-search-result',
            statusFilterDropdown: 'customer-list__status-filter-dropdown',
            jobDescriptionDropdown: 'customer-list__job-description-dropdown',
        },
        id: {
            pager: 'customer-list__pager',
            table: 'customer-list__table',
        },
    },
    appeasementCreate: {
        class: {
            cancelModal: 'appeasement-create__cancel-modal',
            removeAttachment: 'appeasement-create__remove-file',
        },
        id: {
            openModal: 'appeasement-create__open-modal',
            rewardAmount: 'appeasement-create__reward-amount',
            responsibility: 'appeasement-create__responsibility',
            comment: 'appeasement-create__comment',
            promotion: 'appeasement-create__promotion',
            addAttachment: 'appeasement-create__add-attachment',
            submitButton: 'appeasement-create__submit-button',
        },
    },
    appeasementSettings: {
        id: {
            approval: 'appeasement-settings__approval',
            rewardPackageSelectCAN: 'appeasement-settings__reward-package-CAN',
            rewardPackageSelectUSA: 'appeasement-settings__reward-package-USA',
        },
    },
    appeasements: {
        id: {
            pager: 'appeasements__pager',
            header: 'appeasements__header',
            statusFilterDropdown: 'appeasements__status-filter-dropdown',
            promotionFilterDropdown: 'appeasements__promotion-filter-dropdown',
            table: 'appeasements__table',
        },
        class: {
            searchResult: 'appeasements__search-result',
        },
    },
    appeasementDetail: {
        id: {
            header: 'appeasement-detail__header',
            badge: 'appeasement-detail__badge',
            pager: 'appeasement-detail__pager',
            denyButton: 'appeasement-detail__deny-button',
            approveButton: 'appeasement-detail__approve-button',
            unapproveButton: 'appeasement-detail__unapprove-button',
            backButton: 'appeasement-detail__back-button',
        },
        class: {
            detailRow: 'appeasement-detail__detail-row',
        },
    },
    salesIncentiveSearchPage: {
        id: {
            newSalesIncentive: 'sales-incentive-search__new-sales-incentive',
            name: 'sales-incentive-search__name',
            stateDropdown: 'sales-incentive-search__state-dropdown',
            origin: 'sales-incentive-search__origin',
            rangeSize: 'sales-incentive-search__range-size',
            date: 'sales-incentive-search__date',
            table: 'sales-incentive-search__table',
            pager: 'sales-incentive-search__pager',
        },
        class: {
            salesIncentiveRow: 'sales-incentive-search__sales-incentive-row',
        },
    },
    salesIncentiveDetailsPage: {
        id: {
            salesIncentiveForm: 'sales-incentive-details__form',
            salesIncentiveName: 'sales-incentive-details__name',
            pager: 'sales-incentive-details__pager',
            back: 'sales-incentive-details__back',
            saveChanges: 'sales-incentive-details__save-changes',
            timelineTab: 'sales-incentive-details__salesIncentive-timeline-tab',
            billingTab: 'sales-incentive-details__billing-tab',
            salesIncentiveStateLabel: 'sales-incentive-details__salesIncentive-state-label',
            salesIncentiveStateDate: 'sales-incentive-details__salesIncentive-state-date',
            requirementsModalTrigger: 'sales-incentive-details__requirements-modal-trigger',
            requirementsModal: 'sales-incentive-details__requirements-modal',
            submitForReview: 'sales-incentive-details__submit-for-review',
            calculationTotalQuantityInput: 'sales-incentive-details__calculation-total-quantity',
            calculationPointsInput: 'sales-incentive-details__calculation-points',
        },
        class: {
            calculationTypeInput: 'sales-incentive-details__calculation-type',
            salesIncentiveStateDropdown: 'sales-incentive-details__salesIncentive-state-dropdown',
        },
    },
    customerSales: {
        id: {
            hideZeroPointSales: 'customer-sales__hide-zero-point-sales',
            pager: 'customer-sales__pager',
            table: 'customer-sales__table',
        },
        class: {
            saleRow: 'customer-sales__sale-row',
        },
    },
    batchCustomerListTab: {
        class: {
            customerSearchResult: 'batch-customer-list-tab__customer-search-result',
        },
        id: {
            table: 'batch-customer-list-tab__table',
            searchInput: 'batch-customer-list-tab__search-input',
            pager: 'batch-customer-list-tab__pager',
        },
    },
    programLanguages: {
        class: {
            activateLanguageSwitch: 'program-languages__activate-language-switch',
            languageRow: 'program-languages__language-row',
            newLanguageDropdown: 'program-languages__new-language-dropdown',
            lastUploadText: 'program-languages__last-upload-text',
            selectFileText: 'program-languages__select-file-text',
            selectedFileText: 'program-languages__selected-file-text',
            removeFileButton: 'program-languages__remove-file-button',
            fileTypeInput: 'program-languages__file-type-input',
        },
        id: {
            configureLanguageModal: 'program-languages__configure-language-modal',
            createTrigger: 'program-languages__create-trigger',
            downloadFileButton: 'program-languages__download-file-button',
            fileUpload: 'program-languages__file-upload',
            originFileDownloadModal: 'program-languages__origin-file-download-modal',
            originFileDownloadModalCancel: 'program-languages__origin-file-download-modal-cancel',
            originFileDownloadModalConfirm: 'program-languages__origin-file-download-modal-confirm',
            originFileDownloadModalLoading: 'program-languages__origin-file-download-modal-loading',
            originFileDownloadModalDownload: 'program-languages__origin-file-download-modal-download',
            promotionSummariesDropdown: 'program-languages__origin-file-download-promotion-summaries',
            removeFile: 'program-languages__remove-file',
            setLanguageActiveModal: 'program-languages__set-language-active-modal',
            setLanguageActiveModalCancel: 'program-languages__set-language-active-modal-cancel',
            setLanguageActiveModalOkay: 'program-languages__set-language-active-modal-okay',
            setLanguageActiveModalSave: 'program-languages__set-language-active-modal-save',
            table: 'program-languages__table',
            newLanguageModalCancel: 'program-languages__new-language-modal-cancel',
            newLanguageModalSave: 'program-languages__new-language-modal-save',
        },
    },
    useEnglishToggle: {
        id: {
            useEnglish: 'use-english-selector__use-english',
            useTranslation: 'use-english-selector__use-translation',
            englishContentAddendum: 'use-english-selector__english-addendum',
        },
    },
    alternatePayee: {
        id: {
            firstName: 'alternate-payee__first-name',
            lastName: 'alternate-payee__last-name',
            address1: 'alternate-payee__address1',
            address2: 'alternate-payee__address2',
            country: 'alternate-payee__country',
            city: 'alternate-payee__city',
            state: 'alternate-payee__state',
            zip: 'alternate-payee__zip',
            invalidAddressTooltip: 'alternate-payee__invalid-address-tooltip',
        },
    },
    nonCertifiedAddressTypeahead: {
        class: {
            email: 'non-certified-address-typeahead__email',
            name: 'non-certified-address-typeahead__name',
        },
    },
    contestDetailPage: {
        id: {
            measurementSelect: 'contest-detail__measurements',
            currentState: 'contest-detail__current-state',
            leaderboardLabel: 'contest-detail__leaderboard-label',
            cancelContest: 'contest-detail__cancel-contest',
            terminateContest: 'contest-detail__terminate-contest',
        },
        class: {
            email: 'non-certified-address-typeahead__email',
            name: 'non-certified-address-typeahead__name',
        },
    },
    contestStateTransitionModal: {
        id: {
            cancelContest: 'contest-state-transition-modal__cancel-contest',
            goBack: 'contest-state-transition-modal__go-back',
        },
        class: {
            modal: 'contest-state-transition-modal__modal',
        },
    },
    pwaSettings: {
        id: {
            enabled: 'pwa-settings__enabled',
            name: 'pwa-settings__name',
        },
        class: {
            configureFormTrigger: 'claim-form-tab__configure',
            questionName: 'claim-form-tab__question-name',
            questionDate: 'claim-form-tab__question-date',
            needsReview: 'claim-form-tab__needs-review',
        },
    },
};
