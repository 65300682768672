enum ModalType {
    None,
    NewBatch,
    NewClient,
    NewUser,
    NewPromotion,
    NewPromotionType,
    ClaimEdit,
}

export default ModalType;
