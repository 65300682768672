import { keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import DocumentState from 'redux-modules/definitions/DocumentState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';

type Document = OvationsApi.Types.Document;

export const emptyDocument: OvationsApi.Types.Document = {
    id: '',
    name: '',
    uploadDate: '',
    uploadedBy: '',
    programId: '',
    url: '',
};

export const initialState: DocumentState = {
    map: {},
};

const { reducer, update } = createReducer<DocumentState>('document/UPDATE', initialState);
export const documentReducer = reducer;

export const actions = {
    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.Document.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const documents = keyBy(list, 'id');
            const map = { ...ctx.document.map, ...documents };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    createNewDocument(clientId: string, document: Document): ThunkAction<Promise<Document>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newDocument = await OvationsApi.Document.create(clientId, document);
            newDocument.file = document.file;
            dispatch(actions.upsertDocument(clientId, newDocument));
            return newDocument;
        };
    },

    updateDocument: (clientId: string, document: Document): ThunkAction<Promise<Document>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const updatedDocument = await OvationsApi.Document.update(clientId, document);
            dispatch(actions.upsertDocument(clientId, updatedDocument));
            return updatedDocument;
        };
    },

    upsertDocument(clientId: string, document: Document): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.document.map, [document.id]: document };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector([(state: DocumentState) => state.map], (map: DocumentState['map']) => values(map));

export const selectors = {
    getList,

    getListForProgram: (state: DocumentState, programId: string) => {
        const documents = selectors.getList(state);
        return documents.filter((document) => document.programId === programId);
    },
};
