/* tslint:disable max-line-length */
import * as OvationsApi from 'core/ovations-api';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import { CommunicationPreferenceFieldType, ProfileFieldType } from 'core/ovations-api/enums';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import ProfileSettingsState from 'redux-modules/definitions/ProfileSettingsState';
import S from 'redux-modules/definitions/RootState';

export const initialState: ProfileSettingsState = {
    map: {},
};

export const emptyCustomField: OvationsApi.Types.CustomField = {
    id: 0,
    key: '',
    label: '',
    displayedInProfile: false,
};

export const emptyProfileSettings: OvationsApi.Types.ProfileSettings = {
    programId: '',
    customFields: [],
    enableTaxableIncomeForm: false,
    enableRewardShoppingCatalog: false,
    disableSecurityNumberCollection: false,
    reviewInformationPage: false,
    fieldControlOption: {
        coreFieldControlOptions: [
            {
                fieldName: ProfileFieldType.FirstName,
                customerEdit: true,
                platformEdit: true,
            },
            {
                fieldName: ProfileFieldType.LastName,
                customerEdit: true,
                platformEdit: true,
            },
            {
                fieldName: ProfileFieldType.PhoneNumber,
                customerEdit: true,
                platformEdit: true,
            },
            {
                fieldName: ProfileFieldType.FullAddress,
                customerEdit: true,
                platformEdit: true,
            },
        ],
        communicationPreferenceFieldControlOptions: [
            {
                description: 'Preferred Language',
                communicationPreferenceFieldType: CommunicationPreferenceFieldType.PreferredLanguage,
                customerEdit: true,
                platformEdit: true,
            },
            {
                description: 'Preferred Method of Contact',
                communicationPreferenceFieldType: CommunicationPreferenceFieldType.PreferredContactMethod,
                customerEdit: true,
                platformEdit: true,
            },
            {
                description: 'Preferred Email',
                communicationPreferenceFieldType: CommunicationPreferenceFieldType.PreferredEmail,
                customerEdit: true,
                platformEdit: true,
            },
            {
                description: 'Preferred Phone',
                communicationPreferenceFieldType: CommunicationPreferenceFieldType.PreferredPhone,
                customerEdit: true,
                platformEdit: true,
            },
        ],
    },
};

const { reducer, update } = createReducer('profileSettings/UPDATE', initialState);
export const profileSettingsReducer = reducer;

export const actions = {
    fetch: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const profileSettings = await OvationsApi.Portal.fetchProfileSettings(clientId, programId);
            if (!profileSettings) {
                return;
            }
            dispatch(actions.upsert(clientId, profileSettings));
        };
    },

    update(
        clientId: string,
        profileSettings: OvationsApi.Types.ProfileSettings,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Portal.updateProfileSettings(clientId, profileSettings);
            dispatch(actions.upsert(clientId, profileSettings));
        };
    },

    upsert(
        clientId: string,
        profileSettings: OvationsApi.Types.ProfileSettings,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.profileSettings.map, [profileSettings.programId]: profileSettings };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
