import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import * as Enums from 'core/ovations-api/enums';
import { Dictionary } from 'lodash';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import LeaderboardState from 'redux-modules/definitions/LeaderboardState';
import S from 'redux-modules/definitions/RootState';

export const initialState: LeaderboardState = {
    map: {},
    profileFields: [],
    measurementTypes: [],
};

export const emptyLeaderBoard: OvationsApi.Types.Leaderboard = {
    id: -1,
    label: '',
    measurementLabel: '',
    measurementType: null,
    measurementQuestionId: null,
    rankGroupCustomerAttributeId: null,
    rankGroupCoreFieldId: null,
    displayGroupCustomerAttributeId: null,
    displayGroupCoreFieldId: null,
    startDate: null,
    endDate: null,
    state: Enums.LeaderboardState.Planning,
    promotionId: null,
    dateFilterPromotionQuestionId: null,
    dateFilterQuestionStartDate: null,
    dateFilterQuestionEndDate: null,
    stateLastUpdated: null,
    stateLastUpdatedBy: null,
    stateLastUpdatedByName: null,
};

export const mockLeaderboards = [
    {
        id: 1,
        label: 'Leaderboard 1',
        measurementLabel: 'Measurement 1',
        measurementType: null,
        measurementQuestionId: 1,
        rankGroupCustomerAttributeId: 1,
        rankGroupCoreFieldId: 1,
        displayGroupCustomerAttributeId: 1,
        displayGroupCoreFieldId: 1,
        startDate: '07-01-2010',
        endDate: '08-01-2020',
        state: Enums.LeaderboardState.Planning,
        promotionId: 1,
        dateFilterPromotionQuestionId: 1,
        dateFilterQuestionStartDate: '05-01-2019',
        dateFilterQuestionEndDate: '05-01-2020',
        stateLastUpdated: '05-01-2019',
        stateLastUpdatedBy: '05-01-2020',
        stateLastUpdatedByName: 'test1',
    },
    {
        id: 2,
        label: 'Leaderboard 2',
        measurementLabel: 'Measurement 1',
        measurementType: null,
        measurementQuestionId: 1,
        rankGroupCustomerAttributeId: 1,
        rankGroupCoreFieldId: 1,
        displayGroupCustomerAttributeId: 1,
        displayGroupCoreFieldId: 1,
        startDate: '07-01-2014',
        endDate: '08-01-2020',
        state: Enums.LeaderboardState.Planning,
        promotionId: 1,
        dateFilterPromotionQuestionId: 1,
        dateFilterQuestionStartDate: '05-01-2019',
        dateFilterQuestionEndDate: '05-01-2020',
        stateLastUpdated: '05-01-2019',
        stateLastUpdatedBy: '05-01-2020',
        stateLastUpdatedByName: 'test2',
    },
    {
        id: 3,
        label: 'Leaderboard 3',
        measurementLabel: 'Measurement 1',
        measurementType: null,
        measurementQuestionId: 1,
        rankGroupCustomerAttributeId: 1,
        rankGroupCoreFieldId: 1,
        displayGroupCustomerAttributeId: 1,
        displayGroupCoreFieldId: 1,
        startDate: '07-01-2014',
        endDate: '08-01-2020',
        state: Enums.LeaderboardState.Running,
        promotionId: 1,
        dateFilterPromotionQuestionId: 1,
        dateFilterQuestionStartDate: '05-01-2019',
        dateFilterQuestionEndDate: '05-01-2040',
        stateLastUpdated: '05-01-2019',
        stateLastUpdatedBy: '05-01-2040',
        stateLastUpdatedByName: 'test3',
    },
];

const { reducer, update } = createReducer('leaderboard/UPDATE', initialState);
export const leaderboardReducer = reducer;

export const actions = {
    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const leaderboardList = await OvationsApi.Leaderboards.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const leaderboards: Dictionary<OvationsApi.Types.Leaderboard[]> = {
                [programId]: leaderboardList,
            };
            const map = { ...ctx.leaderboard.map, ...leaderboards };
            const measurementTypes = await OvationsApi.Leaderboards.fetchMeasurements(clientId, programId);
            const profileFields = await OvationsApi.Leaderboards.fetchProfileFields(clientId, programId);

            dispatch(clientContextManager.action(clientId, update({ map, measurementTypes, profileFields })));
        };
    },

    create(
        clientId: string,
        programId: string,
        leaderboard: OvationsApi.Types.Leaderboard,
    ): ThunkAction<Promise<OvationsApi.Types.Leaderboard>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newLeaderboard = await OvationsApi.Leaderboards.create(clientId, programId, leaderboard);
            dispatch(actions.upsert(clientId, programId, newLeaderboard));
            return newLeaderboard;
        };
    },

    updateContestState(
        clientId: string,
        programId: string,
        leaderboardId: string,
        transitionState: Enums.LeaderboardState,
    ): ThunkAction<Promise<OvationsApi.Types.Leaderboard | undefined>, S, ExtraArg, Action> {
        return async () => {
            return await OvationsApi.Leaderboards.updateContestState(
                clientId,
                programId,
                leaderboardId,
                transitionState,
            );
        };
    },

    update(
        clientId: string,
        programId: string,
        leaderboard: OvationsApi.Types.Leaderboard,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Leaderboards.update(programId, clientId, leaderboard);
            dispatch(actions.upsert(clientId, programId, leaderboard));
        };
    },

    upsert(
        clientId: string,
        programId: string,
        leaderboard: OvationsApi.Types.Leaderboard,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const leaderboards = ctx.leaderboard.map[programId];
            const updateIndex = leaderboards.findIndex((x) => x.id === leaderboard.id);
            if (updateIndex === -1) {
                leaderboards.push(leaderboard);
            } else {
                leaderboards[updateIndex] = leaderboard;
            }
            const map = { ...ctx.leaderboard.map, [programId]: leaderboards };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
