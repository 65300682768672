import { keyBy, values } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import { DEFAULT_PAGE_SIZE } from 'config/global';
import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { AppeasementDetail, AppeasementSearchRequest, SearchResultsResponse } from 'core/ovations-api/definitions';
import {
    AppeasementResponsibility,
    AppeasementState as AppeasementStatus,
    RewardPackageType,
} from 'core/ovations-api/enums';
import { monetize } from 'core/util/strings';
import { emptyCustomer } from 'redux-modules/customer';
import AppeasementState from 'redux-modules/definitions/AppeasementState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { emptyProfile } from 'redux-modules/profile';
import { emptyPromotionSummary } from 'redux-modules/promotion';
import { Action } from 'redux';

export const initialState: AppeasementState = {
    map: {},
    totalResults: 0,
};

export const emptyAppeasementDetail: AppeasementDetail = {
    id: '1',
    number: 1,
    programId: '',
    customer: emptyCustomer,
    promotion: emptyPromotionSummary,
    comment: '',
    rewardAmount: 0,
    responsibility: AppeasementResponsibility.Client,
    appeasementState: AppeasementStatus.Pending,
    attachments: [],
    createDate: '',
    createdBy: emptyProfile,
    reviewedBy: null,
    rewardPackageType: RewardPackageType.SingleLoadUSA,
    isAppeasementApprovalRequired: false,
};

export const emptyAppeasementSearchRequest: OvationsApi.Types.AppeasementSearchRequest = {
    promotionId: '',
    customerId: '',
    states: [],
    responsibleParties: [],
    showAppeasementsWithoutPromotion: true,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    sortIndex: 'createDate',
    sortDescending: true,
};

const { reducer, update } = createReducer('appeasement/UPDATE', initialState);
export const appeasementReducer = reducer;

export const actions = {
    update,

    search(
        clientId: string,
        programId: string,
        request: Partial<AppeasementSearchRequest>,
    ): ThunkAction<Promise<SearchResultsResponse<AppeasementDetail>>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const response = await OvationsApi.Appeasement.search(clientId, programId, request);
            const map = keyBy(response.results, 'id');
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: response.totalResults })));
            return response;
        };
    },

    fetchSingle(
        clientId: string,
        programId: string,
        appeasementId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const appeasementDetail = await OvationsApi.Appeasement.fetchSingle(clientId, programId, appeasementId);
            dispatch(actions.upsertAppeasement(clientId, appeasementDetail));
        };
    },

    review(
        clientId: string,
        programId: string,
        appeasementDetail: AppeasementDetail,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Appeasement.review(clientId, programId, appeasementDetail);
            dispatch(actions.upsertAppeasement(clientId, appeasementDetail));
        };
    },

    upsertAppeasement(clientId: string, appeasementDetail: AppeasementDetail): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.appeasement.map, [appeasementDetail.id]: appeasementDetail };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    unapprove(
        clientId: string,
        programId: string,
        appeasementDetail: AppeasementDetail,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Appeasement.unapprove(clientId, programId, appeasementDetail);
            dispatch(actions.upsertAppeasement(clientId, appeasementDetail));
        };
    },
};

const getList = createSelector([(state) => state.map], (map) => values(map));

export const selectors = {
    getList,
    getRewardAmountDisplay: createSelector(
        (appeasementDetail: AppeasementDetail) => appeasementDetail,
        (appeasementDetail: AppeasementDetail) => {
            let isPointsReward = false;
            if (appeasementDetail.rewardPackageType === RewardPackageType.Points) {
                isPointsReward = true;
            }
            const rewardDisplay = isPointsReward
                ? `${appeasementDetail.rewardAmount}pts`
                : monetize(appeasementDetail.rewardAmount);
            return rewardDisplay;
        },
    ),
};
