import ImageRotator, { getSizeAfterRotation } from 'components/ImageRotator';
import loadImage from 'lib/loadImage';
import { clamp } from 'lodash';
import React from 'react';

type Vector2 = [number, number];

export interface ImageViewerProps {
    imageSrc: string;
    scale: number;
    rotationDegrees: number;
}

interface ImageViewerState {
    isDragging: boolean;
    isLoading: boolean;
    hasError: boolean;
    image: HTMLImageElement | null;
    translate: Vector2;
}

class ImageViewer extends React.Component<ImageViewerProps, ImageViewerState> {
    viewport: HTMLElement | null;

    cache: {
        mousePosition: Vector2;
        upperLeftBound: Vector2;
        lowerRightBound: Vector2;
    };

    constructor(props: ImageViewerProps) {
        super(props);
        this.state = {
            isDragging: false,
            isLoading: true,
            hasError: false,
            image: null,
            translate: [0, 0],
        };
        this.cache = {
            mousePosition: [0, 0],
            upperLeftBound: [0, 0],
            lowerRightBound: [0, 0],
        };
    }

    componentDidMount() {
        this.loadImage(this.props.imageSrc);
    }

    componentDidUpdate(prevProps: ImageViewerProps) {
        if (this.props.imageSrc !== prevProps.imageSrc) {
            this.loadImage(this.props.imageSrc);
            this.setState({ translate: [0, 0] });
            return;
        }
        if (this.props.scale !== prevProps.scale) {
            this.updateBounds(this.props);
            this.translateRelative([0, 0]);
        }
    }

    onMouseDown: React.MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault(); // prevent text selection while dragging
        this.updateBounds(this.props);
        this.cache.mousePosition = [e.clientX, e.clientY];
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('mousemove', this.onMouseMove);
        this.setState({ isDragging: true });
    };

    onMouseMove = (e: MouseEvent) => {
        const deltaX = e.clientX - this.cache.mousePosition[0];
        const deltaY = e.clientY - this.cache.mousePosition[1];
        this.cache.mousePosition = [e.clientX, e.clientY];
        this.translateRelative([deltaX, deltaY]);
    };

    onMouseUp = () => {
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('mousemove', this.onMouseMove);
        this.setState({ isDragging: false });
    };

    async loadImage(imageSrc: string) {
        this.setState({ isLoading: true, hasError: false });
        try {
            const image = await loadImage(imageSrc);
            this.setState({ isLoading: false, image });
        } catch (e) {
            this.setState({ isLoading: false, hasError: true });
        }
    }

    updateBounds(props: ImageViewerProps) {
        const { image } = this.state;
        if (!this.viewport || !image) {
            return;
        }
        const containerRect = this.viewport.getBoundingClientRect();
        const sizeAfterRotation = getSizeAfterRotation(image.width, image.height, props.rotationDegrees);
        const actualWidth = containerRect.width * props.scale;
        const imposedScale = actualWidth / sizeAfterRotation[0];
        const imageSize: Vector2 = [actualWidth, sizeAfterRotation[1] * imposedScale];

        const freedomOfMovement: Vector2 = [imageSize[0] - containerRect.width, imageSize[1] - containerRect.height];
        this.cache.upperLeftBound = [-freedomOfMovement[0] / 2, -freedomOfMovement[1] / 2];
        this.cache.lowerRightBound = [freedomOfMovement[0] / 2, freedomOfMovement[1] / 2];
    }

    translateRelative(delta: Vector2) {
        const [deltaX, deltaY] = delta;
        const [x, y] = this.state.translate;
        const translate: Vector2 = [
            clamp(x + deltaX, this.cache.upperLeftBound[0], this.cache.lowerRightBound[0]),
            clamp(y + deltaY, this.cache.upperLeftBound[1], this.cache.lowerRightBound[1]),
        ];
        this.setState({ translate });
    }

    render() {
        const { state, props } = this;
        if (state.isLoading) {
            return 'Loading...';
        }
        if (state.hasError || !state.image) {
            return <span className="text-danger">There was a problem loading the image.</span>;
        }
        const transition = state.isDragging ? '' : 'transform 100ms';
        const transforms = [
            `translate3d(${state.translate[0]}px, ${state.translate[1]}px, 0)`,
            `scale(${props.scale})`,
        ];
        return (
            <div className="text-center overflow-hidden" ref={(viewport) => (this.viewport = viewport)}>
                <ImageRotator
                    image={state.image}
                    rotationDegrees={this.props.rotationDegrees}
                    onMouseDown={this.onMouseDown}
                    className="d-block w-100"
                    style={{ transition, transform: transforms.join(' ') }}
                />
            </div>
        );
    }
}

export default ImageViewer;
