import { faCopy, faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faAward, faCogs, faLaptop, faTag, faUsers } from '@fortawesome/free-solid-svg-icons';
import { LinkDef } from 'components/landmarks/ClientNav';
import { SubNavLinkDef } from 'components/landmarks/SubNav';
import * as routes from 'config/routes';
import toPath from 'core/lib/toPath';
import { ClientLevel } from 'enums/Permission';

export const claimSubNavLinkDefs: SubNavLinkDef[] = [
    {
        label: 'Validate',
        href: (params) => (params.programId ? toPath(routes.PROGRAM_CLAIMS_VALIDATE, params) : undefined),
        permissions: [ClientLevel.ValidateClaims],
    },
    {
        label: 'Process Mail-In',
        href: (params) => (params.programId ? toPath(routes.PROGRAM_CLAIMS_PROCESS_MAIL_IN, params) : undefined),
        permissions: [ClientLevel.ProcessMailInClaims],
    },
];

export const batchesSubNavLinkDefs: SubNavLinkDef[] = [
    {
        label: 'Fulfillment Settings',
        href: ({ clientId, programId }) =>
            programId
                ? toPath(routes.PROGRAM_BATCHES_FULFILLMENT_SETTINGS_BATCH_JOBS, { clientId, programId })
                : undefined,
        permissions: [ClientLevel.ViewBatchConfigurations, ClientLevel.ManageBatchConfigurations],
    },
    {
        label: 'Address Certification',
        href: ({ clientId, programId }) =>
            programId ? toPath(routes.PROGRAM_BATCHED_ADDRESS_CERTIFICATION, { clientId, programId }) : undefined,
        permissions: [ClientLevel.AddressCertification],
    },
    {
        label: 'Appeasements',
        href: (params) => (params.programId ? toPath(routes.PROGRAM_APPEASEMENTS, params) : undefined),
        permissions: [ClientLevel.ReviewAppeasements],
    },
];

export const clientNavLinkDefs: LinkDef[] = [
    {
        label: 'Claims',
        icon: faFileAlt,
        href: (params) => (params.programId ? toPath(routes.PROGRAM_CLAIMS, params) : undefined),
        subNavOptions: claimSubNavLinkDefs,
    },
    {
        label: 'Customers',
        icon: faUsers,
        href: (params) => (params.programId ? toPath(routes.PROGRAM_CUSTOMERS, params) : undefined),
    },
    {
        label: 'Promotions',
        icon: faTag,
        href: (params) => (params.programId ? toPath(routes.PROGRAM_PROMOTIONS, params) : undefined),
        permissions: [ClientLevel.ViewPromotions, ClientLevel.ManagePromotions],
    },
    {
        label: 'Sales Incentives',
        icon: faAward,
        href: (params) => (params.programId ? toPath(routes.PROGRAM_SALES_INCENTIVES, params) : undefined),
        permissions: [ClientLevel.ViewSalesIncentives, ClientLevel.ManageSalesIncentives],
    },
    {
        label: 'Program Settings',
        icon: faCogs,
        href: (params) => (params.programId ? toPath(routes.CLIENT_PROGRAM_DETAIL, params) : undefined),
    },
    {
        label: 'Customer Portal',
        icon: faLaptop,
        href: (params) => (params.programId ? toPath(routes.PORTAL_SETTINGS, params) : undefined),
        permissions: [ClientLevel.ManagePortal, ClientLevel.ViewPortal],
    },
    {
        label: 'Batches',
        icon: faCopy,
        href: (params) => (params.programId ? toPath(routes.PROGRAM_BATCHES, params) : undefined),
        labelWhenInSubNav: 'Batch List',
        subNavOptions: batchesSubNavLinkDefs,
        permissions: [ClientLevel.ViewBatches, ClientLevel.ManageBatches],
    },
];
