/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { max, min } from 'lodash';
import React from 'react';

type Vector2 = [number, number];

// https://stackoverflow.com/questions/622140/calculate-bounding-box-coordinates-from-a-rotated-rectangle
export const getSizeAfterRotation = (w: number, h: number, rotationDegrees: number): Vector2 => {
    const theta = (rotationDegrees * Math.PI) / 180;
    const x0 = w / 2;
    const y0 = h / 2;
    const points: Vector2[] = [
        [0, 0],
        [w, 0],
        [w, h],
        [0, h],
    ];
    const newPoints = points.map(([x, y]) => {
        const x2 = x0 + (x - x0) * Math.cos(theta) + (y - y0) * Math.sin(theta);
        const y2 = y0 - (x - x0) * Math.sin(theta) + (y - y0) * Math.cos(theta);
        return [x2, y2];
    });
    const xCoords = newPoints.map((p) => p[0]);
    const yCoords = newPoints.map((p) => p[1]);

    return [max(xCoords)! - min(xCoords)!, max(yCoords)! - min(yCoords)!];
};

interface PassedProps {
    image: HTMLImageElement;
    rotationDegrees: number;
}

export type ImageRotatorProps = PassedProps & React.CanvasHTMLAttributes<HTMLCanvasElement>;

class ImageRotator extends React.Component<ImageRotatorProps> {
    canvas: HTMLCanvasElement | null;

    componentDidMount() {
        if (!this.canvas) {
            return;
        }
        this.rotateImage(this.props);
    }

    componentDidUpdate(prevProps: ImageRotatorProps) {
        if (!this.canvas) {
            return;
        }
        const { props } = this;
        if (prevProps.image !== props.image || prevProps.rotationDegrees !== props.rotationDegrees) {
            this.rotateImage(this.props);
        }
    }

    rotateImage(props: ImageRotatorProps) {
        if (!this.canvas) {
            return;
        }
        const { image, rotationDegrees } = props;
        const [finalWidth, finalHeight] = getSizeAfterRotation(image.width, image.height, rotationDegrees);
        const ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
        this.canvas.width = finalWidth;
        this.canvas.height = finalHeight;
        ctx.translate(finalWidth / 2, finalHeight / 2);
        ctx.rotate((rotationDegrees * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height);
    }

    render() {
        const { image, rotationDegrees, ...canvasProps } = this.props;
        return <canvas ref={(canvas) => (this.canvas = canvas)} {...canvasProps} />;
    }
}

export default ImageRotator;
