const loadImage = async (src = ''): Promise<HTMLImageElement> => {
    const img = new Image();
    const promise = new Promise<HTMLImageElement>((resolve) => {
        if (!src) {
            // eslint-disable-next-line no-promise-executor-return
            return Promise.reject(new Error(`Could not load image, no src provided`));
        }
        img.onload = () => resolve(img);
    });
    img.src = src;
    return promise;
};

export default loadImage;
