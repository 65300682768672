import { filter, keyBy, omitBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalRoleState from 'redux-modules/definitions/PortalRoleState';
import S from 'redux-modules/definitions/RootState';

export const emptyPortalRole: OvationsApi.Types.PortalRole = {
    programId: '',
    name: '',
};

export const initialState: PortalRoleState = {
    map: {},
};

const { reducer, update } = createReducer<PortalRoleState>('portalRole/UPDATE', initialState);
export const portalRoleReducer = reducer;

export const actions = {
    update,

    fetchAll: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch, getState, { clientContextManager }) => {
            const portalRoles = await OvationsApi.PortalRole.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const mapCleaned = omitBy(ctx.portalRole.map, { programId }) as typeof ctx.portalRole.map;
            const map = { ...mapCleaned, ...keyBy(portalRoles, 'id') };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    updateAll: (
        clientId: string,
        programId: string,
        portalRoles: OvationsApi.Types.PortalRole[],
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch, getState, { clientContextManager }) => {
            const updatedPortalRoles = await OvationsApi.PortalRole.updateAll(clientId, programId, portalRoles);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const mapCleaned = omitBy(ctx.portalRole.map, { programId }) as typeof ctx.portalRole.map;
            const map = { ...mapCleaned, ...keyBy(updatedPortalRoles, 'id') };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector(
    (state: PortalRoleState) => state.map,
    (map) => values(map),
);

export const selectors = {
    getList,

    getListForProgram: createSelector(getList, (list) =>
        createSelector(
            (programId: string) => programId,
            (programId) => filter(list, { programId }),
        ),
    ),
};
