import { ContainerProps } from 'containers/Container';
import { LoaderConfig } from 'lib/withData';
import * as claim from 'redux-modules/claim';
import { clientContextManager } from 'redux-modules/root';

export const claimLoader: LoaderConfig<
    ContainerProps<{
        clientId: string;
        programId: string;
        claimId: string;
    }>
> = {
    memoizer: (props) => props.match.params.claimId,
    needsData: (props) => {
        const { clientId, claimId } = props.match.params;
        const ctx = clientContextManager.getContext(props, clientId);

        return !ctx.claim.map[claimId];
    },
    fetchData: async (props) => {
        const { clientId, programId, claimId } = props.match.params;
        await props.dispatch(claim.actions.fetchSingle(clientId, programId, claimId));
    },
};
