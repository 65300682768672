export enum PortalContentCodes {
    EmailDomain = 'email.domain',
    FromEmail = 'email.from',
    ReplyToEmail = 'email.replyTo',
    NavbarColor = 'navBar.color',
    NavbarLinkTextColor = 'navBar.link.textColor',
    NavbarLinkHoverTextColor = 'navBar.link.hover.textColor',
    NavbarLinkHoverBackgroundColor = 'navBar.link.hover.backgroundColor',
    TagManagerId = 'tagManagerId',
    ContactReceivingEmail = 'contact.receivingEmail',
    ContactPhoneNumber = 'contact.phone',
    ContactHours = 'contact.hours',
    RegistrationSubject = 'registration.subject',
    RegistrationEmailContent = 'registration.email',
    RegistrationSmsContent = 'registration.sms',
    PasswordResetSubject = 'passwordReset.subject',
    PasswordResetEmailContent = 'passwordReset.email',
    PasswordResetSmsContent = 'passwordReset.sms',
    PasswordChangedSubject = 'passwordChanged.subject',
    PasswordChangedEmailContent = 'passwordChanged.email',
    PasswordChangedSmsContent = 'passwordChanged.sms',
    FrequentlyAskedQuestions = 'faqs',
}

export default PortalContentCodes;
