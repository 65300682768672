import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button, ButtonGroup, ButtonGroupProps } from 'reactstrap';

import elements from 'config/elements';
import FAIcon from 'core/ovations-components/FAIcon';

interface MiniPaginateProps {
    id?: string;
    className?: string;
    page: number;
    perPage: number;
    total: number;
    onArrowClick: (nextPage: number) => void;
    noun?: string;
    plural?: string;
    size?: ButtonGroupProps['size'];
    disabled?: boolean;
}

const getRangeText = (props: MiniPaginateProps): JSX.Element | undefined => {
    if (!props.total) {
        return;
    }
    const noun = props.noun || 'Result';
    if (props.total === 1) {
        return (
            <span className="range-text">
                {props.total} {noun}
            </span>
        );
    }
    const start = (props.page - 1) * props.perPage + 1;
    const end = Math.min(start + props.perPage - 1, props.total);
    const plural = props.plural || `${noun}s`;
    return (
        <span className="range-text">
            {start}
            {start !== end && `-${end}`}
            {` of ${props.total} ${plural}`}
        </span>
    );
};

const MiniPaginate: React.SFC<MiniPaginateProps> = (props) => {
    const hasPreviousPage = props.page > 1;
    const hasNextPage = props.total > props.page * props.perPage;
    const onPreviousClick = () => {
        props.onArrowClick(props.page - 1);
    };
    const onNextClick = () => {
        props.onArrowClick(props.page + 1);
    };
    return (
        <div id={props.id} className={props.className}>
            {getRangeText(props)}
            <ButtonGroup className="ms-3" size={props.size}>
                <Button
                    aria-label="previous"
                    color="primary"
                    disabled={!hasPreviousPage || props.disabled}
                    onClick={onPreviousClick}
                    className={`${elements.global.class.prev} minipaginate`}
                >
                    <FAIcon icon={faChevronLeft} />
                </Button>
                <Button
                    aria-label="next"
                    color="primary"
                    disabled={!hasNextPage || props.disabled}
                    onClick={onNextClick}
                    className={`${elements.global.class.next} minipaginate`}
                >
                    <FAIcon icon={faChevronRight} />
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default MiniPaginate;
