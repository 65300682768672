import { keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ClaimsSearchRequest from 'core/ovations-api/definitions/claims/ClaimsSearchRequest';
import ClaimStatus from 'core/ovations-api/enums/ClaimStatus';
import ClaimListState from 'redux-modules/definitions/ClaimListState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';

export const initialState: ClaimListState = {
    map: {},
    totalResults: 0,
};

const { reducer, update } = createReducer('claimList/UPDATE', initialState);
export const claimListReducer = reducer;

export const emptyClaimListItem: OvationsApi.Types.ClaimListItem = {
    id: '',
    number: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    rewardAmountTotal: null,
    rewardPointTotal: null,
    status: ClaimStatus.PendingValidation,
    promotionName: '',
    customerId: '',
    rewardPackageType: OvationsApi.Enums.RewardPackageType.SingleLoadUSA,
    submissionDate: '',
};

export const actions = {
    update,

    search(
        clientId: string,
        programId: string,
        request: Partial<ClaimsSearchRequest>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        batchId?: string,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.ClaimListItem>>,
        S,
        ExtraArg,
        Action
    > {
        return async (dispatch, getState, { clientContextManager }) => {
            const response = await OvationsApi.ClaimList.search(clientId, programId, request);
            const { page } = request;
            const map = keyBy(response.results, 'id');
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: response.totalResults, page })));
            return response;
        };
    },
};

const getList = createSelector([(state) => state.map], (map) => values(map));

export const selectors = {
    getList,
};
