import { Dictionary } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import PermissionState from 'redux-modules/definitions/PermissionState';
import S from 'redux-modules/definitions/RootState';
import { createSelector } from 'reselect';

type PermissionManifest = OvationsApi.Types.PermissionManifest;

const initialState: PermissionState = {
    manifests: {},
};

const { reducer, update } = createReducer<PermissionState>('profile/UPDATE', initialState);
export const permissionReducer = reducer;

export const actions = {
    fetchAll: (): ThunkAction<Promise<Dictionary<PermissionManifest>>, S, void, Action> => {
        return async (dispatch) => {
            const manifests = await OvationsApi.Permission.fetchAll();
            dispatch(update({ manifests }));
            return manifests;
        };
    },
};

export const selectors = {
    getSystemPerms: createSelector(
        (state: PermissionState) => state.manifests[OvationsApi.Enums.PermissionType.System],
        (manifest: PermissionManifest) => {
            if (!manifest) {
                return {};
            }
            return manifest.values;
        },
    ),

    getClientPerms: createSelector(
        (state: PermissionState) => state.manifests[OvationsApi.Enums.PermissionType.Client],
        (manifest: PermissionManifest) => {
            if (!manifest) {
                return {};
            }
            return manifest.values;
        },
    ),
};
