import { compact, includes } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { RewardPackageType } from 'core/ovations-api/enums';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalState from 'redux-modules/definitions/PortalState';
import S from 'redux-modules/definitions/RootState';

export const initialState: PortalState = {
    map: {},
    homePageMap: {},
};

const { reducer, update } = createReducer('portal/UPDATE', initialState);
export const portalReducer = reducer;

export const emptyPortal = {
    url: null,
    pwaSettings: { enabled: false, name: '', icon: '', iconUrl: '', iconFile: null },
};

export const emptyBanner: OvationsApi.Types.Banner = {
    altText: null,
    imageLocation: null,
    imageUrl: null,
};

export const emptyPortalHome: OvationsApi.Types.HomePage = {
    programId: '',
    banner: emptyBanner,
    advertisements: [],
    submitClaimTitle: null,
    submitClaimSubtitle: null,
    submitClaimButtonText: null,
    useEnglishAdvertisements: false,
    useEnglishBanner: false,
};

const emptyPwaSettings = {
    id: '',
    enabled: false,
    name: '',
    icon: '',
    iconUrl: null,
    iconFile: null,
};

export const getPortalCacheKey = (programId: string, culture?: string): string => {
    const delimiter = '+';
    return compact([programId, culture]).join(delimiter);
};

export const actions = {
    fetch(clientId: string, programId: string, culture?: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const portal = await OvationsApi.Portal.fetch(clientId, programId, culture);
            if (!portal) {
                return;
            }
            dispatch(actions.upsertPortal(clientId, portal, culture));
        };
    },

    updatePortal(
        clientId: string,
        portal: OvationsApi.Types.Portal,
        culture?: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Portal.update(clientId, portal, culture);
            dispatch(actions.upsertPortal(clientId, portal, culture));
        };
    },

    upsertPortal(
        clientId: string,
        portal: OvationsApi.Types.Portal,
        culture?: string,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            if (portal.pwaSettings === null) {
                portal.pwaSettings = emptyPwaSettings;
            }
            const ctx = clientContextManager.getContext(getState(), clientId);
            const cacheKey = getPortalCacheKey(portal.programId, culture);
            const map = { ...ctx.portal.map, [cacheKey]: portal };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    fetchHome(clientId: string, programId: string, culture?: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const portalHome = await OvationsApi.Portal.fetchHome(clientId, programId, culture);
            dispatch(actions.upsertPortalHome(clientId, portalHome, culture));
        };
    },

    updatePortalHome(
        clientId: string,
        portalHome: OvationsApi.Types.HomePage,
        culture?: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Portal.updateHome(clientId, portalHome, culture);
            dispatch(actions.upsertPortalHome(clientId, portalHome, culture));
        };
    },

    upsertPortalHome(
        clientId: string,
        portalHome: OvationsApi.Types.HomePage,
        culture?: string,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const cacheKey = getPortalCacheKey(portalHome.programId, culture);
            const homePageMap = { ...ctx.portal.homePageMap, [cacheKey]: portalHome };
            return dispatch(clientContextManager.action(clientId, update({ homePageMap })));
        };
    },
};

export const selectors = {
    hasReloadableCards: createSelector(
        (portal: OvationsApi.Types.Portal) => portal,
        (portal: OvationsApi.Types.Portal) => {
            return (
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableCAN) ||
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableUSA) ||
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableUSAGalileo) ||
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableCANVisa)
            );
        },
    ),
};
