/* eslint-disable max-classes-per-file */
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { debounce, stubTrue } from 'lodash';
import phoneFormatter from 'phone-formatter';
import React from 'react';
import { Menu, MenuItem, MenuProps, Typeahead } from 'react-bootstrap-typeahead';
import { Button } from 'reactstrap';

import elements from 'config/elements';
import { DEFAULT_TYPEAHEAD_RESULTS, PHONE_FORMAT_US } from 'config/global';
import { noResults, searchHelpPrompt, searchHelpPromptForAddresses } from 'config/messages';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';

interface CustomerMenuProps extends MenuProps {
    results: OvationsApi.Types.Customer[];
    allowNew?: boolean;
    onNewCustomerClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export class CustomerMenu extends React.Component<CustomerMenuProps> {
    renderResult = (customer: OvationsApi.Types.Customer, i: number) => {
        const phoneNumber = customer.phoneNumber ? phoneFormatter.format(customer.phoneNumber, PHONE_FORMAT_US) : '';

        return (
            <MenuItem key={customer.id} position={i} option={customer}>
                <div className="d-flex align-items-center">
                    <div>
                        <FAIcon icon={faUser} className="ghost" />
                    </div>
                    <div className="ps-2">
                        <div className="text-nowrap">
                            <span className={`fw-bold ${elements.customerTypeahead.class.customerName}`}>
                                {customer.firstName} {customer.lastName}
                            </span>
                            <span className={`small ${elements.customerTypeahead.class.customerEmail}`}>
                                {customer.email && ` • ${customer.email}`}
                            </span>
                        </div>
                        <div className="small">
                            {[customer.address.address1, phoneNumber].filter(Boolean).join(' • ')}
                        </div>
                    </div>
                </div>
            </MenuItem>
        );
    };

    renderNewCustomerButton() {
        return (
            <div className="p-2 pb-1">
                <Button
                    block
                    color="primary"
                    className={`text-uppercase ${elements.customerTypeahead.class.newCustomer}`}
                    type="button"
                    onClick={this.props.onNewCustomerClick}
                >
                    New Customer
                </Button>
            </div>
        );
    }

    render() {
        const { style, innerRef, results, allowNew, ...menuProps } = this.props;
        const ie11Fix = 'top-start-0';

        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <div ref={innerRef as React.Ref<any>} className="rounded rbt-menu bg-white" style={style}>
                <Menu {...menuProps} className={`w-100 mb-2 border-0 position-relative shadow-none ${ie11Fix}`}>
                    {results.map(this.renderResult)}
                </Menu>
                {allowNew && this.renderNewCustomerButton()}
            </div>
        );
    }
}

interface CustomerTypeaheadProps {
    clientId: string;
    programId: string;
    placeholder: string;
    onSelect: (customer: OvationsApi.Types.Customer) => void;
    onNewCustomerClick?: () => void;
    addressMode?: boolean;
    allowNew?: boolean;
    inputProps: React.InputHTMLAttributes<HTMLInputElement>;
}

interface CustomerTypeaheadState {
    isSearching: boolean;
    query: string;
    customers: OvationsApi.Types.Customer[];
}

class CustomerTypeahead extends React.Component<CustomerTypeaheadProps, CustomerTypeaheadState> {
    searchCustomers = debounce(async (query: string) => {
        const { clientId, programId } = this.props;
        this.setState({ isSearching: true, query });
        let customers: OvationsApi.Types.Customer[];
        try {
            if (this.props.addressMode) {
                const searchParams = { query, maxResults: DEFAULT_TYPEAHEAD_RESULTS };
                customers = await OvationsApi.Customer.searchByAddress(clientId, programId, searchParams);
            } else {
                const searchParams = { query, maxResults: DEFAULT_TYPEAHEAD_RESULTS };
                customers = await OvationsApi.Customer.search(clientId, programId, searchParams);
            }
        } catch (e) {
            customers = [];
        }

        this.setState({ customers, isSearching: false });
    }, 200);

    constructor(props: CustomerTypeaheadProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            isSearching: false,
            query: '',
            customers: [],
        };
    }

    getCustomerLabel(customer: OvationsApi.Types.Customer) {
        return `${customer.firstName} ${customer.lastName}`;
    }

    getHelpTextLabel(): string {
        if (this.state.isSearching) {
            return 'Searching...';
        }
        const hasQuery = Boolean(this.state.query.trim());
        if (hasQuery) {
            return noResults();
        }

        return this.props.addressMode ? searchHelpPromptForAddresses() : searchHelpPrompt();
    }

    onCustomerSearch = (query: string) => {
        if (!query.trim()) {
            this.setState({ isSearching: false, customers: [], query });
            return;
        }

        this.searchCustomers(query);
    };

    onChange = (selection: OvationsApi.Types.Customer[]) => this.props.onSelect(selection[0]);

    shouldShowNewButton(results: OvationsApi.Types.Customer[]): boolean {
        if (!this.props.allowNew) {
            return false;
        }
        if (!this.state.query.trim()) {
            return false;
        }
        if (results.length) {
            return true;
        }
        return !this.state.isSearching;
    }

    renderMenu = (results: OvationsApi.Types.Customer[], menuProps: MenuProps) => {
        return (
            <CustomerMenu
                {...menuProps}
                results={results}
                allowNew={this.shouldShowNewButton(results)}
                onNewCustomerClick={this.props.onNewCustomerClick}
            />
        );
    };

    render() {
        const { props } = this;

        const extraProps = { maxHeight: '250px' };
        return (
            <Typeahead
                key={`customers-${props.programId}`} // Forces re-mounting when program/type changes
                id={`customers-${props.programId}`}
                filterBy={stubTrue}
                labelKey={this.getCustomerLabel}
                placeholder={props.placeholder}
                emptyLabel={this.getHelpTextLabel()}
                inputProps={this.props.inputProps}
                // maxHeight="250px" this prop is on MenuProps
                isLoading={this.state.isSearching}
                onChange={this.onChange}
                onInputChange={this.onCustomerSearch}
                options={this.state.customers}
                renderMenu={this.renderMenu}
                {...extraProps}
            />
        );
    }
}

export default CustomerTypeahead;
