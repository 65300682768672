import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';

import AddressCertificationState from 'redux-modules/definitions/AddressCertificationState';
import S from 'redux-modules/definitions/RootState';
import ExtraArg from './definitions/ExtraArg';

export const emptyCertifiableAddress: OvationsApi.Types.CertifiableAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    status: OvationsApi.Enums.AddressCertificationStatus.NonCertified,
};

export const emptyNonCertifiedAddress: OvationsApi.Types.NonCertifiedAddress = {
    id: '',
    type: OvationsApi.Enums.CertifiableAddressType.Customer,
    address: emptyCertifiableAddress,
    firstName: '',
    lastName: '',
    phoneNumber: null,
    email: null,
};

export const initialState: AddressCertificationState = {
    addressCertificationQueue: [],
    map: {},
};

const { reducer, update } = createReducer('addressCertification/UPDATE', initialState);
export const addressCertificationReducer = reducer;

export const actions = {
    update,

    fetchNonCertified(
        clientId: string,
        programId: string,
        request: Partial<OvationsApi.Types.NonCertifiedAddressRequest>,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const addressCertificationQueue = await OvationsApi.AddressCertification.fetchNonCertified(
                clientId,
                programId,
                request,
            );
            dispatch(clientContextManager.action(clientId, update({ addressCertificationQueue })));
        };
    },

    fetchSingle(
        clientId: string,
        programId: string,
        addressId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const addresses = await OvationsApi.AddressCertification.fetchNonCertified(clientId, programId, {
                includeUnresolved: true,
            });
            const address = addresses.find((a) => a.id === addressId);
            if (address) {
                dispatch(this.upsertAddress(clientId, address));
            }
        };
    },

    updateAddress(
        clientId: string,
        programId: string,
        address: OvationsApi.Types.NonCertifiedAddress,
    ): ThunkAction<Promise<OvationsApi.Types.AddressCertificationActionResult>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const response = await OvationsApi.AddressCertification.update(clientId, programId, address);
            if (response.success) {
                const ctx = clientContextManager.getContext(getState(), clientId);
                const map = { ...ctx.addressCertification.map, [response.address.id]: response.address };
                await dispatch(clientContextManager.action(clientId, update({ map })));
            }
            return response;
        };
    },

    updateCertificationQueue(
        clientId: string,
        addressCertificationQueue: OvationsApi.Types.NonCertifiedAddress[],
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            if (ctx.addressCertification.addressCertificationQueue === addressCertificationQueue) {
                return;
            }
            dispatch(clientContextManager.action(clientId, update({ addressCertificationQueue })));
        };
    },

    upsertAddress(
        clientId: string,
        address: OvationsApi.Types.NonCertifiedAddress,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.addressCertification.map, [address.id]: address };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
