import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import UserDetailContainer from 'containers/users/UserDetailContainer';
import UserSearchContainer from 'containers/users/UserSearchContainer';

export class UserContainer extends React.Component<ContainerProps> {
    render() {
        const baseUrl = this.props.match.url;
        const breadcrumbs = [{ url: baseUrl, label: 'Users' }];
        return (
            <Switch>
                <Route
                    path={routes.USER_DETAIL}
                    render={(props) => <UserDetailContainer {...this.props} {...props} breadcrumbs={breadcrumbs} />}
                />
                <Route component={UserSearchContainer} />
            </Switch>
        );
    }
}

export default connect((state) => state)(UserContainer);
