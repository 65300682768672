import React from 'react';
import { connect } from 'react-redux';

import elements from 'config/elements';
import { ContainerProps } from 'containers/Container';
import * as profile from 'redux-modules/profile';

export type DashboardContainerProps = ContainerProps;

export class DashboardContainer extends React.Component<DashboardContainerProps> {
    componentDidMount() {
        document.body.classList.add('bg-x-light');
    }

    componentWillUnmount() {
        document.body.classList.remove('bg-x-light');
    }

    render() {
        const userProfile = this.props.profile.map[this.props.profile.me] || profile.emptyProfile;

        return (
            <div className="wrap mt-4">
                <h1 className="visually-hidden">Dashboard</h1>
                <div className="h4" id={elements.dashboardPage.id.welcomeMessage}>
                    Welcome, {userProfile.firstName}
                </div>
                <div id={elements.dashboardPage.id.quickLinks} className="card">
                    <div className="card-body">
                        <h2 className="card-title h5">Quick Links</h2>
                        <div className="hr" />
                        <p>You don't have any quick links available at this time.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(DashboardContainer);
