import classNames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import { PortalContainer } from 'asyncComponents/components';
import ClientCreator from 'components/client/ClientCreator';
import ClientNav from 'components/landmarks/ClientNav';
import GlobalNav from 'components/landmarks/GlobalNav';
import UserCreator from 'components/user/UserCreator';
import * as messages from 'config/messages';
import * as routes from 'config/routes';
import ClientsContainer from 'containers/clients/ClientsContainer';
import { ContainerProps } from 'containers/Container';
import DashboardContainer from 'containers/DashboardContainer';
import UserRolesContainer from 'containers/roles/UserRolesContainer';
import UsersContainer from 'containers/users/UsersContainer';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import ModalType from 'enums/ModalType';
import { ClientLevel, SystemLevel } from 'enums/Permission';
import { Color } from 'enums/Theme';
import PrivateRoute from 'lib/PrivateRoute';
import * as claim from 'redux-modules/claim';
import * as client from 'redux-modules/client';
import * as layout from 'redux-modules/layout';
import * as notification from 'redux-modules/notification';
import * as program from 'redux-modules/program';
import { clientContextManager, selectors } from 'redux-modules/root';
import * as router from 'redux-modules/router';

export type HomeContainerProps = ContainerProps;

export class HomeContainer extends React.Component<HomeContainerProps> {
    componentDidMount() {
        const clientId = router.selectors.getClientId(this.props.router);
        if (clientId) {
            this.fetchPrograms(clientId);
        }
    }

    componentDidUpdate(prevProps: HomeContainerProps) {
        const pervClientId = router.selectors.getClientId(prevProps.router);
        const currentClientId = router.selectors.getClientId(this.props.router);
        if (currentClientId && pervClientId !== currentClientId) {
            this.fetchPrograms(currentClientId);
        }

        if (router.selectors.isPortal(this.props.router)) {
            document.body.classList.add('has-portal-nav');
        } else {
            document.body.classList.remove('has-portal-nav');
        }
    }

    onAddUserSuccess = (newUserId: string, adResult: OvationsApi.Types.ActiveDirectoryResult) => {
        this.props.dispatch(
            notification.actions.add({
                type: Color.Success,
                heading: messages.userAddedHeading(),
                body: messages.userAddedBody(`${adResult.firstName} ${adResult.lastName}`),
                duration: 10 * 1000,
            }),
        );
        this.props.dispatch(layout.actions.toggleModal(ModalType.NewUser));
        this.props.dispatch(push(toPath(routes.USER_DETAIL, { userId: newUserId })));
    };

    onAddClientSuccess = (newClientId: string, clientName: string) => {
        this.props.dispatch(
            notification.actions.add({
                type: Color.Success,
                heading: messages.clientAddedHeading(),
                body: messages.clientAddedBody(clientName),
                duration: 10 * 1000,
            }),
        );
        this.props.dispatch(layout.actions.toggleModal(ModalType.NewClient));
        this.props.dispatch(push(toPath(routes.CLIENT_SETTINGS, { clientId: newClientId })));
    };

    onLogout = () => {
        OvationsApi.Auth.logout();
    };

    onCustomerSelect = (customer: OvationsApi.Types.Customer | undefined) => {
        if (!customer) {
            return;
        }
        const path = selectors.getCustomerDetailPath(this.props, customer.id);
        if (path) {
            this.props.dispatch(push(path));
        }
    };

    onClaimSelect = (selectedClaim: OvationsApi.Types.ClaimDetail | undefined) => {
        const clientId = router.selectors.getClientId(this.props.router);
        if (!selectedClaim || !clientId) {
            return;
        }
        const programId = router.selectors.getProgramId(this.props.router);
        const customerId = selectedClaim.customer.id;
        const claimId = selectedClaim.id;
        const path = selectors.userHasAccess(this.props, [ClientLevel.AcceptCalls])
            ? toPath(routes.IN_CALL_CLAIM_DETAIL, { clientId, programId, customerId, claimId })
            : toPath(routes.PROGRAM_CLAIM_DETAIL, { clientId, programId, claimId });
        this.props.dispatch(claim.actions.upsertClaimDetail(clientId, selectedClaim));
        this.props.dispatch(push(path));
    };

    toggleNewUserModal = () => this.props.dispatch(layout.actions.toggleModal(ModalType.NewUser));

    toggleNewClientModal = () => this.props.dispatch(layout.actions.toggleModal(ModalType.NewClient));

    hasRequiredPermissions = (permissions: string[]) => selectors.userHasAccess(this.props, permissions);

    async fetchPrograms(clientId: string) {
        try {
            await this.props.dispatch(program.actions.fetchAll(clientId));
        } catch {
            this.props.dispatch(
                notification.actions.add({
                    type: Color.Danger,
                    body: messages.programFetchFailure(),
                }),
            );
        }
    }

    isInProgramNavContext(): boolean {
        // tslint:disable max-line-length
        const regex =
            /^\/clients\/(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)\/programs\/(new|(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)(\/datasets|\/documents|\/reward-packages|\/calltypes|\/reason-codes|\/languages|\/promotion-groups|\/promotion-groups\/[0-9]+|\/promotion-groups\/new|\/file-import|\/api-keys|))$/;
        const currentLocation = window.location.pathname;
        const regexEval = regex.exec(currentLocation);

        if (!regexEval) {
            return false;
        }

        return regexEval.length > 0;
    }

    render() {
        const clientId = router.selectors.getClientId(this.props.router);
        const ctx = clientContextManager.getContext(this.props, clientId || '');
        let activeClient;
        if (clientId) {
            activeClient = this.props.client.map[clientId];
        }
        const isPortal = router.selectors.isPortal(this.props.router);

        return (
            <div
                className={classNames('has-global-nav', {
                    'has-client-nav': !!clientId && !isPortal && !this.isInProgramNavContext(),
                })}
            >
                <GlobalNav
                    onNewClientClick={this.toggleNewClientModal}
                    onNewUserClick={this.toggleNewUserModal}
                    activeClient={activeClient}
                    clients={client.selectors.getList(this.props.client)}
                    user={this.props.profile.map[this.props.profile.me]}
                    programId={router.selectors.getProgramId(this.props.router)}
                    onCustomerSelect={this.onCustomerSelect}
                    onClaimSelect={this.onClaimSelect}
                    onLogout={this.onLogout}
                />
                <Switch>
                    <Route path={routes.PORTAL_SETTINGS} component={PortalContainer} />
                    <Route>
                        <>
                            {clientId && !this.isInProgramNavContext() && (
                                <ClientNav
                                    clientId={clientId}
                                    currentPath={this.props.location.pathname}
                                    logoUrl={(activeClient || client.emptyClient).logoUrl}
                                    programId={router.selectors.getProgramId(this.props.router)}
                                    programs={program.selectors.getList(ctx.program)}
                                    hasRequiredPermissions={this.hasRequiredPermissions}
                                />
                            )}
                            <Switch>
                                <Route path={routes.CLIENTS} component={ClientsContainer} />
                                <PrivateRoute
                                    path={routes.ROLES}
                                    component={UserRolesContainer}
                                    permissions={[SystemLevel.ConfigureRoles]}
                                />
                                <Route path={routes.USERS} component={UsersContainer} />
                                <Route exact path="/" component={DashboardContainer} />
                            </Switch>
                        </>
                    </Route>
                </Switch>
                <UserCreator
                    isOpen={this.props.layout.modal === ModalType.NewUser}
                    onModalClose={this.toggleNewUserModal}
                    onAddSuccess={this.onAddUserSuccess}
                />
                <ClientCreator
                    isOpen={this.props.layout.modal === ModalType.NewClient}
                    onModalClose={this.toggleNewClientModal}
                    onAddSuccess={this.onAddClientSuccess}
                />
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(HomeContainer);
