import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import FAIcon from 'core/ovations-components/FAIcon';
import React from 'react';

const Spinner = () => (
    <div className="p-5 text-center">
        <FAIcon icon={faCircleNotch} size="2x" className="text-secondary spin" />
    </div>
);

export default Spinner;
