/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
    UncontrolledDropdown,
} from 'reactstrap';

import GlobalSettingsDropdown from 'components/landmarks/GlobalSettingsDropdown';
import PrimarySearch from 'components/landmarks/PrimarySearch';
import elements from 'config/elements';
import * as routes from 'config/routes';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import ScrollbarSpacer from 'core/ovations-components/ScrollbarSpacer';
import { ClientLevel } from 'enums/Permission';
import Authorize from 'lib/Authorize';
import logo from 'media/images/ovations-logo.svg';

const { globalHeader } = elements;
export interface GlobalNavProps {
    clients?: OvationsApi.Types.Client[];
    user: OvationsApi.Types.PlatformUserProfile;
    activeClient?: OvationsApi.Types.Client;
    programId?: string;
    onCustomerSelect: (customer: OvationsApi.Types.Customer) => void;
    onClaimSelect?: (claimDetail: OvationsApi.Types.ClaimDetail) => void;
    onNewUserClick?: () => void;
    onNewClientClick?: () => void;
    onLogout?: () => void;
}

export default class GlobalNav extends React.Component<GlobalNavProps> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps: Partial<GlobalNavProps> = {
        clients: [],
    };

    renderClientToolbar() {
        const { activeClient, programId } = this.props;
        if (!activeClient) {
            return <div className="d-flex flex-grow" />;
        }
        const clientId = activeClient.id;
        return (
            <div className="d-flex flex-grow">
                <div className="ms-1">
                    <UncontrolledDropdown className={globalHeader.class.manageClientDropdown}>
                        <DropdownToggle className="btn--soft" caret color="x-light">
                            Manage Client
                        </DropdownToggle>
                        <DropdownMenu>
                            <Authorize permissions={[ClientLevel.ManagePrograms, ClientLevel.ViewPrograms]}>
                                <DropdownItem tag={Link} {...{ to: toPath(routes.CLIENT_PROGRAMS, { clientId }) }}>
                                    Programs
                                </DropdownItem>
                            </Authorize>
                            <DropdownItem tag={Link} {...{ to: toPath(routes.CLIENT_SETTINGS, { clientId }) }}>
                                Client Settings
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                {programId && (
                    <PrimarySearch
                        clientId={clientId}
                        programId={programId}
                        onCustomerSelect={this.props.onCustomerSelect}
                        onClaimSelect={this.props.onClaimSelect}
                    />
                )}
            </div>
        );
    }

    render() {
        const { activeClient } = this.props;
        return (
            <div className="global-nav">
                <div className="d-flex flex-grow align-items-center">
                    {!activeClient && (
                        <div className="me-3">
                            <Link to="/">
                                <img alt="navigate to homepage" src={logo} className="global-nav__logo" />
                            </Link>
                        </div>
                    )}
                    {this.props.clients!.length > 0 && (
                        <div>
                            <UncontrolledDropdown className={globalHeader.class.clientsDropdown}>
                                <DropdownToggle className="btn--soft" caret size="lg" color="x-light">
                                    {activeClient ? activeClient.name : 'Select a Client'}
                                </DropdownToggle>
                                <DropdownMenu flip={false} className="global-nav__dropdown-menu-scrollY">
                                    {this.props.clients!.map((client) => (
                                        <DropdownItem
                                            key={client.id}
                                            tag={Link}
                                            {...{ to: toPath(routes.CLIENT_DETAIL, { clientId: client.id }) }}
                                        >
                                            {client.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    )}
                    {this.renderClientToolbar()}
                    <div className="vr ms-4" />
                    <div className="text-nowrap">
                        <Link
                            aria-label="navigate to homepage"
                            className={`text-dark ms-3 ${globalHeader.class.homeLink}`}
                            to="/"
                        >
                            <FAIcon icon={faHome} size="lg" />
                        </Link>
                    </div>
                    <div className="ms-1">
                        <GlobalSettingsDropdown
                            onNewClientClick={this.props.onNewClientClick}
                            onNewUserClick={this.props.onNewUserClick}
                            user={this.props.user}
                        />
                    </div>
                    <div>
                        <UncontrolledButtonDropdown className={`${globalHeader.class.username}`}>
                            <DropdownToggle className="btn--soft" color="x-light" caret>
                                <FAIcon icon={faUserCircle} />{' '}
                                {(this.props.user.userName || '').replace('@groupo.com', '')}
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={this.props.onLogout}>Logout</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                    <ScrollbarSpacer />
                </div>
            </div>
        );
    }
}
