import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, ListGroup, ListGroupItem } from 'reactstrap';

import elements from 'config/elements';
import FAIcon from 'core/ovations-components/FAIcon';

export interface SubNavProps {
    label: string;
    icon: IconDefinition;
    clientId: string;
    programId: string | undefined;
    currentPath: string;
    parentPath: string | void;
    options: SubNavLinkDef[];
}

interface SubNavState {
    isDropdownOpen: boolean;
}

export interface SubNavLinkDef {
    label: string;
    href: (params: { clientId: string; programId?: string }) => string | void;
    permissions?: string[];
    exact?: boolean;
}

class SubNav extends React.PureComponent<SubNavProps, SubNavState> {
    constructor(props: SubNavProps) {
        super(props);
        this.state = { isDropdownOpen: false };
    }

    componentDidUpdate(prevProps: SubNavProps) {
        const { parentPath, currentPath } = this.props;
        if (prevProps.currentPath !== this.props.currentPath) {
            const isParentActive = !!parentPath && currentPath.startsWith(parentPath);
            this.setState({ isDropdownOpen: isParentActive });
        }
    }

    getActiveItem() {
        const { props } = this;
        const activeItem = props.options.find((subNavLink) => {
            const subNavItemHref = subNavLink.href(props);
            if (!subNavItemHref) {
                return false;
            }
            if (subNavLink.exact) {
                return props.currentPath === subNavLink.href(props);
            }
            return props.currentPath.startsWith(subNavItemHref);
        });

        return activeItem;
    }

    toggleDropdown = () => {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    };

    renderSubNavAccordion() {
        const { props, state } = this;
        const activeItem = this.getActiveItem();
        return (
            <Collapse isOpen={state.isDropdownOpen}>
                <ListGroup tag="nav">
                    {props.options.map((subNav: SubNavLinkDef) => {
                        const subNavItemHref = subNav.href(props);
                        const linkProps = { to: subNavItemHref || '' };
                        const navItemClassNames = classNames(`text-decoration-none ${elements.subNav.class.item}`, {
                            [`${elements.subNav.class.item}--active fw-bold`]: activeItem === subNav,
                        });

                        return (
                            <ListGroupItem key={subNav.label} className={navItemClassNames} tag={Link} {...linkProps}>
                                <small className={classNames({ 'fw-bold': activeItem === subNav })}>
                                    {subNav.label}
                                </small>
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            </Collapse>
        );
    }

    render() {
        const { label, icon, parentPath, currentPath } = this.props;

        const isParentActive = !!parentPath && currentPath.startsWith(parentPath);

        const iconClassName = classNames('me-2', { 'text-primary': isParentActive });
        const buttonClassName = classNames(elements.subNav.class.trigger, {
            disabled: !parentPath,
            btn: !!parentPath,
        });
        const chevronClassNames = classNames('ms-auto me-1 sub-nav__trigger__chevron', {
            active: this.state.isDropdownOpen,
        });

        // Dropdown needs to be disabled when user is not within a program path
        const isPathValidForNav = !!parentPath;
        const onClick = isPathValidForNav ? this.toggleDropdown : noop;

        return (
            <>
                <ListGroupItem
                    tag="button"
                    action
                    className={buttonClassName}
                    onClick={onClick}
                    active={isParentActive}
                >
                    <div className="d-flex align-items-center">
                        <FAIcon icon={icon} fixedWidth className={iconClassName} />
                        <span
                            className={classNames('text-uppercase text-smaller', {
                                'fw-bold': isParentActive,
                            })}
                        >
                            {label}
                        </span>
                        <FAIcon icon={faAngleDown} fixedWidth className={chevronClassNames} />
                    </div>
                </ListGroupItem>
                {this.renderSubNavAccordion()}
            </>
        );
    }
}

export default SubNav;
