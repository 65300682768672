import { includes } from 'lodash';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import elements from 'config/elements';
import * as OvationsApi from 'core/ovations-api';
import { removeFirst } from 'core/util/arrays';

interface UserPlatformPermissionsProps {
    selected: string[];
    values: OvationsApi.Types.PermissionManifest['values'];
    onPermissionsChange: (permissions: string[]) => void;
    disabled?: boolean;
}

const UserPlatformPermissions: React.SFC<UserPlatformPermissionsProps> = (props) => {
    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { checked, value } = e.currentTarget;
        const newPermissions: string[] = checked
            ? [...props.selected, value]
            : removeFirst(props.selected, (p) => p === value);
        props.onPermissionsChange(newPermissions);
    };
    return (
        <div>
            <ul id={elements.userSettingsPage.id.userPermissions} className="list-unstyled">
                {Object.keys(props.values).map((key) => (
                    <li key={key} className="p-1">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    value={key}
                                    checked={includes(props.selected, key)}
                                    onChange={onChange}
                                    disabled={props.disabled}
                                />{' '}
                                {props.values[key]}
                            </Label>
                        </FormGroup>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default UserPlatformPermissions;
