import { keyBy, uniq, values } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import QuestionState from 'redux-modules/definitions/QuestionState';
import S from 'redux-modules/definitions/RootState';
import { Action } from 'redux';

type Question = OvationsApi.Types.Question;

export const emptyQuestion: Question = {
    id: '',
    title: '',
    description: '',
    helpText: '',
    programId: '',
    internalDescription: '',
    reportingGroup: '',
    type: OvationsApi.Enums.QuestionType.None,
    createDate: new Date().toISOString(),
};

export const emptyTextQuestion: OvationsApi.Types.TextQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.Text,
    validationRegex: null,
    formattingErrorMessage: null,
};

export const emptyDateTimeQuestion: OvationsApi.Types.DateTimeQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.DateTime,
};

export const emptyNumberQuestion: OvationsApi.Types.NumberQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.Number,
};

export const emptyLayer: OvationsApi.Types.CascadingDropdownLayer = {
    dataSetPropertyId: 0,
    label: null,
    displayAsCurrency: false,
};

export const emptyCascadingDropdownQuestion: OvationsApi.Types.CascadingDropdownQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.CascadingDropdown,
    dataSetId: '',
    layers: [emptyLayer],
};

export const emptyDropdownQuestion: OvationsApi.Types.DropdownQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.Dropdown,
};

export const emptyFileUploadQuestion: OvationsApi.Types.FileUploadQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.FileUpload,
    maxFileSizeMb: 5,
};

export const emptyMultiSelectQuestion: OvationsApi.Types.MultiSelectQuestion = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.MultiSelect,
};

export const emptyReferredIndividualNameQuestion: OvationsApi.Types.ReferredIndividualName = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.ReferredIndividualName,
    validationRegex: null,
    formattingErrorMessage: null,
};

export const emptyReferredIndividualEmailQuestion: OvationsApi.Types.ReferredIndividualEmail = {
    ...emptyQuestion,
    type: OvationsApi.Enums.QuestionType.ReferredIndividualEmail,
    validationRegex: null,
    formattingErrorMessage: null,
};

export const initialState: QuestionState = {
    map: {},
};

const { reducer, update } = createReducer<QuestionState>('question/UPDATE', initialState);
export const questionReducer = reducer;

export const actions = {
    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.QuestionLibrary.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const programQuestions = keyBy(list, 'id');
            const map = { ...ctx.question.map, ...programQuestions };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    createNewQuestion(clientId: string, question: Question): ThunkAction<Promise<Question>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newQuestion = await OvationsApi.QuestionLibrary.create(clientId, question);
            dispatch(actions.upsertQuestion(clientId, newQuestion));
            return newQuestion;
        };
    },

    updateQuestion(clientId: string, question: Question): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.QuestionLibrary.update(clientId, question);
            dispatch(actions.upsertQuestion(clientId, question));
        };
    },

    upsertQuestion(clientId: string, question: Question): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.question.map, [question.id]: question };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector([(state: QuestionState) => state.map], (map) => values(map));

export const selectors = {
    getList,

    getListForProgram: (state: QuestionState, programId: string) => {
        const questions = selectors.getList(state);
        return questions.filter((question) => question.programId === programId);
    },

    getReportingGroups: createSelector([getList], (list: Question[]) => {
        const reportingGroups = list.map((question) => question.reportingGroup || '').filter((g) => g !== '');
        return uniq(reportingGroups);
    }),
};
