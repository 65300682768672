import { push } from 'connected-react-router';
import { values } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import UserRolesList from 'components/role/UserRolesList';
import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import UserRoleDetailContainer from 'containers/roles/UserRoleDetailContainer';
import toPath from 'core/lib/toPath';
import * as role from 'redux-modules/role';

class UserRolesContainer extends React.Component<ContainerProps> {
    initialFetch = Promise.resolve({});

    componentDidMount() {
        this.initialFetch = this.props.dispatch(role.actions.fetchAll());
    }

    getRows = async () => {
        await this.initialFetch;
        return values(this.props.role.map);
    };

    render() {
        const baseUrl = this.props.match.url;
        const breadcrumbs = [{ label: 'O-vations Roles', url: baseUrl }];
        const breadcrumbsForCreateRole = [...breadcrumbs, { label: 'New', url: baseUrl }];
        const breadcrumbsForEditRole = [...breadcrumbs, { label: 'Edit', url: baseUrl }];

        return (
            <Switch>
                <Route
                    path={routes.ROLE_CREATE}
                    render={(props) => (
                        <UserRoleDetailContainer
                            {...this.props}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            {...(props as any)}
                            breadcrumbs={breadcrumbsForCreateRole}
                        />
                    )}
                />
                <Route
                    path={routes.ROLE_DETAIL}
                    render={(props) => (
                        <UserRoleDetailContainer {...this.props} {...props} breadcrumbs={breadcrumbsForEditRole} />
                    )}
                />
                <Route
                    exact
                    path={routes.ROLES}
                    render={() => (
                        <UserRolesList
                            getRows={this.getRows}
                            onTableRowClick={(row) => {
                                const editUrl = toPath(routes.ROLE_DETAIL, { roleId: row.id });
                                this.props.dispatch(push(editUrl));
                            }}
                        />
                    )}
                />
            </Switch>
        );
    }
}

export default connect((state) => state)(UserRolesContainer);
