import * as OvationsApi from 'core/ovations-api';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalFAQState from 'redux-modules/definitions/PortalFAQState';
import S from 'redux-modules/definitions/RootState';

export const initialState: PortalFAQState = {
    map: {},
};

const emptyPortalFAQSectionItem: OvationsApi.Types.PortalFAQSectionItem = {
    id: 0,
    title: '',
    responseText: '',
};

const emptyPortalFAQSection: OvationsApi.Types.PortalFAQSection = {
    id: 0,
    title: '',
    items: [emptyPortalFAQSectionItem],
};

export const emptyPortalFAQ: OvationsApi.Types.PortalFAQ = {
    programId: '',
    faqSections: [emptyPortalFAQSection],
};

const { reducer, update } = createReducer('portalFAQ/UPDATE', initialState);
export const portalFAQReducer = reducer;

export const actions = {
    fetch: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const portalFAQ = await OvationsApi.Portal.fetchFAQ(clientId, programId);
            if (!portalFAQ) {
                return;
            }
            dispatch(actions.upsert(clientId, portalFAQ));
        };
    },

    update(clientId: string, portalFAQ: OvationsApi.Types.PortalFAQ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Portal.updateFAQ(clientId, portalFAQ);
            dispatch(actions.upsert(clientId, portalFAQ));
        };
    },

    upsert(clientId: string, portalFAQ: OvationsApi.Types.PortalFAQ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.portalFAQ.map, [portalFAQ.programId]: portalFAQ };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
