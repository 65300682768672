/* eslint-disable max-classes-per-file */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import {
    BatchContainer,
    ClaimsContainer,
    ProgramDetailContainer,
    PromotionsContainer,
    SalesIncentivesContainer,
} from 'asyncComponents/components';
import elements from 'config/elements';
import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import CustomersContainer from 'containers/customers/CustomersContainer';
import ProgramSearchContainer from 'containers/programs/ProgramSearchContainer';
import { ClientLevel } from 'enums/Permission';
import PrivateRoute from 'lib/PrivateRoute';
import { selectors } from 'redux-modules/root';

export class SelectProgramPrompt extends React.Component {
    componentDidMount() {
        document.body.classList.add('bg-x-light');
    }

    componentWillUnmount() {
        document.body.classList.remove('bg-x-light');
    }

    render() {
        return (
            <div className="h5 m-5 pt-3 text-center text-dark">
                <span id={elements.programDetailsPage.id.selectProgramPrompt}>
                    Select a program from the menu on the left to get started.
                </span>
            </div>
        );
    }
}

export class ProgramsContainer extends React.Component<ContainerProps> {
    render() {
        const userHasProgramPermission = selectors.userHasAccess(this.props, [
            ClientLevel.ViewPrograms,
            ClientLevel.ManagePrograms,
        ]);

        return (
            <Switch>
                {/* Claims */}
                <Route path={routes.PROGRAM_CLAIMS} component={ClaimsContainer} />

                {/* Customers */}
                <Route path={routes.PROGRAM_CUSTOMERS} component={CustomersContainer} />

                {/* Batches / Appeasements */}
                <PrivateRoute path={routes.PROGRAM_BATCHES} component={BatchContainer} />

                {/* Promotions */}
                <PrivateRoute
                    permissions={[ClientLevel.ViewPromotions, ClientLevel.ManagePromotions]}
                    path={routes.PROGRAM_PROMOTIONS}
                    component={PromotionsContainer}
                />

                {/* Sales Incentives */}
                <PrivateRoute
                    permissions={[ClientLevel.ViewSalesIncentives, ClientLevel.ManageSalesIncentives]}
                    path={routes.PROGRAM_SALES_INCENTIVES}
                    component={SalesIncentivesContainer}
                />

                {/* Programs */}
                {userHasProgramPermission && [
                    // React.Fragment does not work like how you'd expect it to in a Switch
                    <Route key="create" path={routes.CLIENT_PROGRAM_CREATE} component={ProgramDetailContainer} />,
                    <Route key="edit" path={routes.CLIENT_PROGRAM_DETAIL} component={ProgramDetailContainer} />,
                    <Route key="search" exact path={routes.CLIENT_PROGRAMS} component={ProgramSearchContainer} />,
                ]}
                <Route component={SelectProgramPrompt} />
            </Switch>
        );
    }
}

export default connect((state) => state)(ProgramsContainer);
