import { MapCache, memoize } from 'lodash';
import { stringify } from 'querystring';
import React from 'react';

import * as OvationsApi from 'core/ovations-api';
import { API_URL } from 'core/ovations-api/constants';

export interface ClientPdfViewerProps {
    clientId: string;
    file: File | null;
    fileLocation: string | null;
    rotationDegrees: number;
}

interface ClientPdfViewerState {
    isLoading: boolean;
    fileLocation: string | null;
}

const MAX_CACHED_FILES = 3;

export const memoizedUploadTempFile = memoize(
    (clientId: string, file: File) => OvationsApi.FileHandler.uploadTempFile(clientId, file),
    (clientId: string, file: File) => file,
);

class ClientPdfViewer extends React.Component<ClientPdfViewerProps, ClientPdfViewerState> {
    constructor(props: ClientPdfViewerProps) {
        super(props);
        this.state = this.getInitialState(props);
    }

    getInitialState(props: ClientPdfViewerProps): ClientPdfViewerState {
        if (props.file) {
            return { isLoading: true, fileLocation: null };
        }
        return { isLoading: false, fileLocation: props.fileLocation };
    }

    componentDidMount() {
        if (this.props.file) {
            this.savePdfAsTempFile(this.props.clientId, this.props.file);
        }
    }

    componentDidUpdate(prevProps: ClientPdfViewerProps) {
        const { fileLocation } = this.props;
        if (this.props.file && this.props.file !== prevProps.file) {
            this.savePdfAsTempFile(this.props.clientId, this.props.file);
        } else if (fileLocation !== prevProps.fileLocation) {
            this.setState({ fileLocation });
        }
    }

    async savePdfAsTempFile(clientId: string, file: File) {
        this.setState({ isLoading: true });
        // Typescript declaration for _.MapCache is wrong (lacks `size` property).
        const cache = memoizedUploadTempFile.cache as MapCache & { size: number };
        if (cache.size > MAX_CACHED_FILES) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            memoizedUploadTempFile.cache.clear!();
        }
        const fileLocation = await memoizedUploadTempFile(clientId, file);
        this.setState({ fileLocation, isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return 'Loading...';
        }
        const { fileLocation } = this.state;
        if (this.props.file && !fileLocation) {
            return <span className="text-danger">There was a problem loading the file.</span>;
        }
        const { rotationDegrees, clientId } = this.props;
        const query = stringify({ fileLocation, rotationDegrees });
        const src = `${API_URL}/api/v1/Pdf/client/${clientId}/?${query}`;

        return (
            <div className="ratio ratio---8.5-11">
                <iframe className="ratio__inner" title="PDF Preview" src={src} />
            </div>
        );
    }
}

export default ClientPdfViewer;
