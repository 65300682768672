import { faChevronLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { difference, last } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { Button, Form, Input } from 'reactstrap';
import { createSelector } from 'reselect';

import BreadcrumbTrail, { BreadcrumbTrailItem } from 'components/BreadcrumbTrail';
import UserPlatformPermissions from 'components/user/UserPlatformPermissions';
import * as messages from 'config/messages';
import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import toPath from 'core/lib/toPath';
import { Comparison } from 'core/definitions/HasChangesSelector';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { Color } from 'enums/Theme';
import * as notification from 'redux-modules/notification';
import * as permission from 'redux-modules/permission';
import * as role from 'redux-modules/role';

export interface UserRoleDetailProps extends ContainerProps<{ roleId: string }> {
    breadcrumbs: BreadcrumbTrailItem[];
}

export interface UserRoleDetailContainerState {
    isLoading: boolean;
    isSavingChanges: boolean;
    userRoleDetail: OvationsApi.Types.PlatformRole;
    wasValidated: boolean;
}

const hasChanges = createSelector(
    (state: Comparison<OvationsApi.Types.PlatformRole>) => state.initial,
    (state: Comparison<OvationsApi.Types.PlatformRole>) => state.current,
    (initial, current) => {
        if (!initial || !current) {
            return initial !== current;
        }
        if (initial.name !== current.name) {
            return true;
        }
        const missingInCurrent = difference(initial.rolePermissions, current.rolePermissions);
        if (missingInCurrent.length) {
            return true;
        }
        const onlyInCurrent = difference(current.rolePermissions, initial.rolePermissions);
        if (onlyInCurrent.length) {
            return true;
        }
        return false;
    },
);

export class UserRoleDetailContainer extends React.Component<UserRoleDetailProps, UserRoleDetailContainerState> {
    constructor(props: UserRoleDetailProps) {
        super(props);

        const { roleId } = props.match.params;

        this.state = {
            isLoading: false,
            isSavingChanges: false,
            userRoleDetail: props.role.map[roleId] || role.emptyRole,
            wasValidated: false,
        };
    }

    componentDidMount() {
        const promises = [];
        if (!this.isNewRole()) {
            promises.push(this.props.dispatch(role.actions.fetchRoleDetail(this.props.match.params.roleId)));
        }
        const systemPermsManifest = this.props.permission.manifests[OvationsApi.Enums.PermissionType.Client];
        if (!systemPermsManifest) {
            promises.push(this.props.dispatch(permission.actions.fetchAll()));
        }
        this.loadWhile(promises);
    }

    componentDidUpdate(prevProps: UserRoleDetailProps) {
        const { roleId } = this.props.match.params;
        if (roleId !== prevProps.match.params.roleId) {
            // From /:roleId to /new
            if (!roleId) {
                this.setState({ userRoleDetail: role.emptyRole });
                return;
            }
            // From /new to /:roleId
            this.loadWhile([prevProps.dispatch(role.actions.fetchRoleDetail(roleId))]);
            return;
        }

        const nextUserRoleDetail = this.props.role.map[roleId];
        const roleDetailHasChanged = nextUserRoleDetail !== prevProps.role.map[roleId];
        if (roleDetailHasChanged) {
            this.setState({ userRoleDetail: nextUserRoleDetail });
        }
    }

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({
            userRoleDetail: {
                ...this.state.userRoleDetail,
                name: e.currentTarget.value,
            },
        });
    };

    onPermissionsChange = (platformPermissions: string[]) => {
        if (!this.state.userRoleDetail) {
            return;
        }
        this.setState({
            userRoleDetail: {
                ...this.state.userRoleDetail,
                rolePermissions: platformPermissions,
            },
        });
    };

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        const isValid = e.currentTarget.checkValidity();
        if (isValid) {
            this.saveChanges();
        } else {
            this.setState({ wasValidated: true });
        }
    };

    // We only care *that* all the promises finish, not *what* promises finsh
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadWhile(promises: Promise<any>[]) {
        if (!promises.length) {
            return;
        }
        this.setState({ isLoading: true });

        Promise.all(promises)
            .then(() => this.setState({ isLoading: false }))
            .catch(() => {
                this.props.dispatch(
                    notification.actions.add({
                        type: Color.Danger,
                        body: messages.generalFailure(),
                    }),
                );
                this.setState({ isLoading: false });
            });
    }

    isNewRole(props: UserRoleDetailProps = this.props) {
        return !props.match.params.roleId;
    }

    async saveChanges() {
        if (!this.state.userRoleDetail) {
            return;
        }
        this.setState({ isSavingChanges: true });
        let type;
        let body;
        try {
            if (this.isNewRole()) {
                const createdRole = await this.props.dispatch(role.actions.createNewRole(this.state.userRoleDetail));
                body = messages.userRoleAddSuccess();
                this.setState({ userRoleDetail: role.emptyRole }); // Revert to silence unsaved changes popup
                this.props.dispatch(push(toPath(routes.ROLE_DETAIL, { roleId: createdRole.id })));
            } else {
                await this.props.dispatch(role.actions.updateRole(this.state.userRoleDetail));
                body = messages.userRoleUpdateSuccess();
            }
            type = Color.Success;
        } catch (e) {
            type = Color.Danger;
            body = messages.userRoleUpdateFailure();
            if (e.response && e.response.status === 409) {
                body = messages.userRoleDuplicateFailure();
            }
        }
        this.setState({ isSavingChanges: false });
        this.props.dispatch(notification.actions.add({ type, body, duration: 4000 }));
    }

    renderActionBar() {
        const { props, state } = this;
        const lastBreadcrumb = last(props.breadcrumbs);
        const initial = props.role.map[this.props.match.params.roleId] || role.emptyRole;
        const current = state.userRoleDetail;
        return (
            <div className="wrap">
                {lastBreadcrumb && lastBreadcrumb.url && (
                    <Link to={lastBreadcrumb.url} className="me-3 btn btn-outline-primary">
                        <FAIcon icon={faChevronLeft} /> Back
                    </Link>
                )}
                <Button
                    key="action-bar__primary"
                    color="primary"
                    className="me-3"
                    type="submit"
                    disabled={state.isSavingChanges || !hasChanges({ initial, current })}
                >
                    {state.isSavingChanges ? (
                        <span>
                            Saving... <FAIcon icon={faCircleNotch} className="spin" />
                        </span>
                    ) : (
                        'Save Changes'
                    )}
                </Button>
            </div>
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="pt-5 text-center">
                    <FAIcon icon={faCircleNotch} size="2x" className="text-secondary spin" />
                </div>
            );
        }
        const { props, state } = this;
        const initial = props.role.map[props.match.params.roleId] || role.emptyRole;
        const current = state.userRoleDetail;
        return (
            <div>
                <Prompt when={hasChanges({ initial, current })} message={messages.unsavedChanges()} />
                <BreadcrumbTrail breadcrumbs={props.breadcrumbs} />
                <Form
                    className={classNames({ 'was-validated': state.wasValidated })}
                    noValidate
                    onSubmit={this.onFormSubmit}
                >
                    <div className="wrap">
                        <Input
                            type="text"
                            name="roleName"
                            id="roleName"
                            placeholder="Enter New Role"
                            value={state.userRoleDetail.name}
                            onChange={this.onInputChange}
                            maxLength={50}
                            required
                            bsSize="lg"
                        />
                        <span className="invalid-feedback">Role name is required.</span>
                    </div>
                    <hr />
                    {this.renderActionBar()}
                    <hr />
                </Form>
                <div className="wrap">
                    <h4>Client Permissions</h4>
                    <UserPlatformPermissions
                        selected={this.state.userRoleDetail.rolePermissions}
                        values={permission.selectors.getClientPerms(this.props.permission)}
                        onPermissionsChange={this.onPermissionsChange}
                    />
                </div>
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(UserRoleDetailContainer);
