import { keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import CallDispositionState from 'redux-modules/definitions/CallDispositionState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';

export const initialState: CallDispositionState = {
    map: {},
};

const { reducer, update } = createReducer('callDisposition/UPDATE', initialState);
export const callDispositionReducer = reducer;

export const emptyCallDisposition: OvationsApi.Types.CallDisposition = {
    id: 0,
    programId: '',
    name: '',
};

export const actions = {
    update,

    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.CallDisposition.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const callDispositions = keyBy(list, 'name');
            const map = { ...ctx.callDisposition.map, ...callDispositions };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    createCallDisposition(
        clientId: string,
        programId: string,
        callDisposition: OvationsApi.Types.CallDisposition,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newId = await OvationsApi.CallDisposition.create(clientId, programId, callDisposition);
            const newDisposition = { ...callDisposition, id: newId };
            dispatch(actions.upsertCallDisposition(clientId, newDisposition));
        };
    },

    updateCallDisposition(
        clientId: string,
        programId: string,
        callDisposition: OvationsApi.Types.CallDisposition,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.CallDisposition.update(clientId, programId, callDisposition);
            dispatch(actions.upsertCallDisposition(clientId, callDisposition));
        };
    },

    upsertCallDisposition(
        clientId: string,
        callDisposition: OvationsApi.Types.CallDisposition,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.callDisposition.map, [callDisposition.id]: callDisposition };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector([(state: CallDispositionState) => state.map], (map) => values(map));

export const selectors = {
    getList,

    getListForProgram: createSelector(
        (state: CallDispositionState) => getList(state),
        (list) =>
            createSelector(
                (programId: string) => programId,
                (programId) => list.filter((callDisposition) => callDisposition.programId === programId),
            ),
    ),
};
