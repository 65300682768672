import { faChevronLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import * as client from 'redux-modules/client';
import * as notification from 'redux-modules/notification';
import { createSelector } from 'reselect';

import elements from 'config/elements';
import * as messages from 'config/messages';
import { ContainerProps } from 'containers/Container';
import * as OvationsApi from 'core/ovations-api';
import { Comparison } from 'core/definitions/HasChangesSelector';
import FAIcon from 'core/ovations-components/FAIcon';
import FileUploader, { ImageFile, setPreview } from 'core/ovations-components/FileUploader';
import Tab from 'definitions/Tab';
import { Color } from 'enums/Theme';
import withData, { LoaderConfig } from 'lib/withData';

const { clientSettingsPage } = elements;

export interface ClientSettingsProps extends ContainerProps<{ clientId: string }> {}

export interface ClientSettingsState {
    isRemovingLogo: boolean;
    isSavingChanges: boolean;
    client: OvationsApi.Types.Client;
    logoFileError: boolean;
    wasValidated: boolean;
}

const hasChanges = createSelector(
    (state: Comparison<OvationsApi.Types.Client>) => state.initial,
    (state: Comparison<OvationsApi.Types.Client>) => state.current,
    (initial, current) => {
        if (!initial || !current) {
            return initial !== current;
        }
        return (
            initial.name !== current.name ||
            current.logoUrl !== initial.logoUrl ||
            current.cardEngineClientId !== initial.cardEngineClientId
        );
    },
);

export class ClientSettingsContainer extends React.Component<ClientSettingsProps, ClientSettingsState> {
    tabs: Tab[] = [{ id: 'client-details', label: 'Client Details', path: '' }];

    constructor(props: ClientSettingsProps) {
        super(props);
        this.state = {
            isSavingChanges: false,
            client: props.client.map[props.match.params.clientId] || client.emptyClient,
            isRemovingLogo: false,
            logoFileError: false,
            wasValidated: false,
        };
    }

    componentDidUpdate(prevProps: ClientSettingsProps) {
        const currentClient = this.props.client.map[this.props.match.params.clientId];
        const clientHasChanged = currentClient !== prevProps.client.map[prevProps.match.params.clientId];
        if (clientHasChanged) {
            this.setState({ client: currentClient || client.emptyClient });
        }
    }

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const isValid = e.currentTarget.checkValidity();
        if (isValid) {
            this.saveChanges();
        } else {
            this.setState({ wasValidated: true });
        }
    };

    onConfirmRemove: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.setState({
            isRemovingLogo: false,
            client: {
                ...this.state.client,
                logoFile: undefined,
                logoUrl: null,
                logo: null,
            },
        });
    };

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        this.setState({ client: { ...this.state.client, [name]: value } });
    };

    onLogoFileDrop = (files: ImageFile[]) => {
        if (!files.length) {
            return false;
        }
        const file = files[0];
        setPreview(file);
        this.setState({
            logoFileError: false,
            client: {
                ...this.state.client,
                logoFile: files[0],
                logoUrl: file.preview || null,
            },
        });
    };

    toggleRemoveConfirmation = () => {
        this.setState({
            isRemovingLogo: !this.state.isRemovingLogo,
        });
    };

    async saveChanges() {
        this.setState({ isSavingChanges: true });
        let type;
        let body;
        try {
            await this.props.dispatch(client.actions.update(this.state.client));
            body = messages.clientSettingsUpdateSuccess();
            type = Color.Success;
        } catch (e) {
            type = Color.Danger;
            body = messages.clientSettingsUpdateFailure();
            if (e.response && e.response.status === 409) {
                body = messages.clientExists();
            }
        }
        this.setState({ isSavingChanges: false, wasValidated: false });
        this.props.dispatch(notification.actions.add({ type, body, duration: 4000 }));
    }

    renderActionBar() {
        const { state, props } = this;
        const initial = props.client.map[props.match.params.clientId] || client.emptyClient;
        const current = state.client;

        return (
            <div className="wrap">
                <Button
                    onClick={props.history.goBack}
                    className="me-3 btn btn-outline-primary"
                    color="white"
                    id={clientSettingsPage.id.backButton}
                >
                    <FAIcon icon={faChevronLeft} /> Back
                </Button>
                <Button
                    key="action-bar__primary"
                    color="primary"
                    className="me-3"
                    disabled={state.isSavingChanges || !hasChanges({ initial, current })}
                    type="submit"
                    id={clientSettingsPage.id.saveChanges}
                >
                    {state.isSavingChanges ? (
                        <span>
                            Saving... <FAIcon icon={faCircleNotch} className="spin" />
                        </span>
                    ) : (
                        'Save Changes'
                    )}
                </Button>
            </div>
        );
    }

    renderClientSettings() {
        const { props, state } = this;
        const initial = props.client.map[props.match.params.clientId] || client.emptyClient;

        return (
            <TabContent activeTab="client-details">
                <TabPane tabId="client-details">
                    <div className="row">
                        <div className="col-6 col-md-4">
                            <h4>Basic</h4>
                            <div className="hr mt-0 mb-4" />
                            <FormGroup>
                                <Label for={clientSettingsPage.id.clientName}>Client Name</Label>
                                <Input
                                    name="name"
                                    id={clientSettingsPage.id.clientName}
                                    value={state.client.name}
                                    maxLength={50}
                                    required
                                    readOnly
                                    onChange={this.onInputChange}
                                />
                                <span className="invalid-feedback">Client name is required</span>
                            </FormGroup>
                            <FormGroup>
                                <Label for="client-settings__number">Client ID</Label>
                                <Input id="client-settings__number" value={state.client.number} readOnly />
                            </FormGroup>
                            <FormGroup>
                                <Label for={clientSettingsPage.id.cardEngineClientId}>Card Engine Client ID</Label>
                                <Input
                                    id={clientSettingsPage.id.cardEngineClientId}
                                    name="cardEngineClientId"
                                    type="text"
                                    pattern="^([1-9]|[1-9][0-9]+)$"
                                    value={state.client.cardEngineClientId || ''}
                                    onChange={this.onInputChange}
                                />
                                <span className="invalid-feedback">
                                    Card Engine Client ID must be a whole number greater than zero
                                </span>
                            </FormGroup>
                        </div>
                        <div className="col-6 col-md-4">
                            <h4>Customization</h4>
                            <div className="hr mt-0 mb-4" />
                            <Label for="client-settings__client-logo" className="d-block mb-2">
                                Client Logo
                            </Label>
                            {state.client.logoUrl ? (
                                <div id="client-settings__preview-area" className="text-center">
                                    <img
                                        id={clientSettingsPage.id.logoPreview}
                                        src={state.client.logoUrl}
                                        alt="Preview"
                                        width="100%"
                                        height="auto"
                                    />
                                    <Button
                                        key="action-bar__primary"
                                        color="primary"
                                        className="mt-3"
                                        disabled={state.isSavingChanges}
                                        onClick={initial.logoUrl ? this.toggleRemoveConfirmation : this.onConfirmRemove}
                                        id={clientSettingsPage.id.removeLogo}
                                    >
                                        Remove Image
                                    </Button>

                                    <Modal isOpen={state.isRemovingLogo}>
                                        <ModalHeader>Remove Logo File?</ModalHeader>
                                        <ModalBody>
                                            If you remove this file you will have to re-upload a new file.
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                id={clientSettingsPage.id.confirmCancel}
                                                color="secondary"
                                                outline
                                                onClick={this.toggleRemoveConfirmation}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                id={clientSettingsPage.id.confirmRemove}
                                                color="warning"
                                                outline
                                                onClick={this.onConfirmRemove}
                                            >
                                                Remove
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            ) : (
                                <div>
                                    <FileUploader
                                        id={clientSettingsPage.id.logoUpload}
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                        }}
                                        className="btn btn-primary"
                                        acceptClassName="btn btn-success"
                                        rejectClassName="btn btn-danger"
                                        disabled={state.isSavingChanges}
                                        maxSize={2 * 1024 * 1024}
                                        onDropRejected={() => this.setState({ logoFileError: true })}
                                        onDrop={this.onLogoFileDrop}
                                    >
                                        Upload / Drop Logo File
                                    </FileUploader>
                                    <div className={classNames('mt-2', { 'text-danger': state.logoFileError })}>
                                        Only jpeg and png images will be accepted. Maximum allowed file size is 2 MB.
                                    </div>
                                    <small className="mt-2 d-block text-secondary">
                                        Logo will display in the client sidebar. Image will scale to max width of 200px
                                        or max height of 100px.
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                </TabPane>
            </TabContent>
        );
    }

    render() {
        const { state, props } = this;
        const clientDetailTab = this.tabs[0];
        const initial = props.client.map[props.match.params.clientId] || client.emptyClient;
        const current = state.client;
        return (
            <div>
                <Form
                    className={classNames({ 'was-validated': state.wasValidated })}
                    noValidate
                    onSubmit={this.onFormSubmit}
                >
                    <Prompt when={hasChanges({ initial, current })} message={messages.unsavedChanges()} />
                    <div className="wrap mt-4">
                        <h1 className="h3 m-0">Client Settings</h1>
                    </div>
                    <hr />
                    {this.renderActionBar()}
                    <hr />
                    <div className="wrap">
                        <Nav tabs>
                            <NavItem key={clientDetailTab.id}>
                                <NavLink id={clientDetailTab.id} active={!!clientDetailTab}>
                                    {clientDetailTab.label}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="wrap pt-4">
                        <div aria-labelledby={this.tabs[0].id}>{this.renderClientSettings()}</div>
                    </div>
                </Form>
            </div>
        );
    }
}

export const clientConfig: LoaderConfig<ClientSettingsProps> = {
    memoizer: (props) => props.match.params.clientId,
    needsData: (props, prevProps) => !prevProps || !props.client.map[props.match.params.clientId],
    fetchData: (props) => props.dispatch(client.actions.fetch(props.match.params.clientId)),
};

const ClientSettingsContainerWithData = withData(clientConfig)(ClientSettingsContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ClientSettingsContainerWithData);
