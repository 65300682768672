/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-classes-per-file */
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Progress, Table } from 'reactstrap';

import Paginate from 'components/Paginate';
import { DEFAULT_PAGE_SIZE } from 'config/global';
import FAIcon from 'core/ovations-components/FAIcon';
import { closestAncestor } from 'core/util/dom';

export interface TableColumn<T = {}> {
    key: string;
    header: JSX.Element | string | ((column: TableColumn<T>) => JSX.Element);
    cell?: (row: T, key: string) => JSX.Element;
    sortable?: boolean;
    headerClassName?: string;
}

export type SortChangeHandler = (sortKey?: string, sortDescending?: boolean) => void;

export interface DataTableProps<T> {
    id?: string;
    className?: string;
    columns?: TableColumn<T>[];
    rows?: T[];
    page?: number;
    isLoading?: boolean;
    onSortChange?: SortChangeHandler;
    onPageClick?: (page: number) => void;
    onRowClick?: (row: T) => void;
    rowClassName?: (row: T, index: number) => string;
    sortDescending?: boolean;
    sortKey?: string;
    totalPages?: number;
    emptyLabel?: string;
    rowHeaderElement?: (row: T) => JSX.Element | null;
}

export const IGNORE_ROW_CLICK = 'ignore-row-click';

// eslint-disable-next-line func-names
export const getPage = function <T = {}>(allRows: T[], page = 1, perPage: number = DEFAULT_PAGE_SIZE): T[] {
    const start = (page - 1) * perPage;
    return allRows.slice(start, start + perPage);
};

export default class DataTable<T = {}> extends React.Component<DataTableProps<T>> {
    onHeaderClick: React.MouseEventHandler<HTMLElement> = (e) => {
        const key = e.currentTarget.getAttribute('data-key');
        if (this.props.onSortChange && key) {
            let sortKey;
            let sortDescending;
            if (key !== this.props.sortKey) {
                sortKey = key;
                sortDescending = false;
            } else if (this.props.sortDescending === false) {
                sortKey = key;
                sortDescending = true;
            }
            this.props.onSortChange(sortKey, sortDescending);
        }
    };

    getRowClickHandler(row: T): React.MouseEventHandler<HTMLTableRowElement> | undefined {
        const { onRowClick } = this.props;
        if (!onRowClick) {
            return;
        }
        return (e) => {
            const target = e.target as HTMLElement;
            if (!closestAncestor(target, `.${IGNORE_ROW_CLICK}`)) {
                onRowClick(row);
            }
        };
    }

    renderHeaderCell(column: TableColumn<T>) {
        const header = typeof column.header === 'function' ? column.header(column) : column.header;
        if (!column.sortable) {
            return (
                <th key={column.key} className={column.headerClassName}>
                    {header}
                </th>
            );
        }
        const className = classNames('p-0', column.headerClassName);
        return (
            <th className={className} key={column.key}>
                <button
                    type="button"
                    className="btn btn--tableHeader"
                    data-key={column.key}
                    onClick={this.onHeaderClick}
                >
                    {header}
                    <span className="ms-2">{this.renderSortIcon(column)}</span>
                </button>
            </th>
        );
    }

    renderSortIcon(column: TableColumn<T>) {
        const { sortKey, sortDescending } = this.props;
        if (sortKey && sortDescending !== undefined && column.key !== sortKey) {
            return <FAIcon icon={faSort} className="text-secondary" />;
        }
        if (sortDescending === false) {
            return <FAIcon icon={faSortUp} />;
        }
        if (sortDescending === true) {
            return <FAIcon icon={faSortDown} />;
        }
        return <FAIcon icon={faSort} />;
    }

    renderEmptyLabel() {
        return <div className="no-results text-center">{this.props.emptyLabel || 'No results found.'}</div>;
    }

    render() {
        const { props } = this;
        const { columns = [], rows = [] } = props;

        if (rows && columns) {
            return (
                <div id={props.id}>
                    <Table hover className={classNames(props.className, { 'table--loading': props.isLoading })}>
                        <thead>
                            <tr>{columns.map((column) => this.renderHeaderCell(column))}</tr>
                            <tr>
                                <td colSpan={columns.length} className="p-0">
                                    <div className="table__spinner">
                                        <Progress animated value={100} className="progress--squared progress--thin" />
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, i) => (
                                <React.Fragment key={i}>
                                    {this.props.rowHeaderElement && this.props.rowHeaderElement(row)}
                                    <tr
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                        onClick={this.getRowClickHandler(row)}
                                        className={classNames(props.rowClassName && props.rowClassName(row, i), {
                                            clickable: !!this.props.onRowClick,
                                        })}
                                    >
                                        {columns.map((column) => {
                                            if (typeof column.cell === 'function') {
                                                return column.cell(row, column.key);
                                            }
                                            return <td key={column.key}>{row[column.key]}</td>;
                                        })}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                    {!rows.length && !props.isLoading && this.renderEmptyLabel()}
                    {props.page && (
                        <Paginate
                            className="d-flex justify-content-center"
                            totalPages={props.totalPages}
                            page={props.page}
                            onPageClick={props.onPageClick}
                        />
                    )}
                </div>
            );
        }
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
}

interface SearchResult<T> {
    total: number;
    rows: T[];
}

interface UncontrolledProps<T> extends DataTableProps<T> {
    perPage?: number;
    search: (page: number, perPage: number, sortKey?: string, sortDescending?: boolean) => Promise<SearchResult<T>>;
}

interface UncontrolledState<T> {
    isLoading: boolean;
    page: number;
    columns?: TableColumn<T>[];
    rows?: T[];
    sortDescending?: boolean;
    sortKey?: string;
    totalPages?: number;
}

export class UncontrolledDataTable<T = {}> extends React.Component<UncontrolledProps<T>, UncontrolledState<T>> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        perPage: DEFAULT_PAGE_SIZE,
    };

    constructor(props: UncontrolledProps<T>) {
        super(props);
        this.state = {
            columns: props.columns,
            isLoading: true,
            page: 1,
            rows: props.rows,
            sortDescending: props.sortDescending,
            sortKey: props.sortKey,
        };
    }

    componentDidMount() {
        this.search();
    }

    onPageClick = (page: number) => {
        this.setState({ page }, () => this.search());
    };

    onSortChange: SortChangeHandler = (sortKey, sortDescending) => {
        const page = 1;
        this.setState({ page, sortKey, sortDescending }, () => this.search());
    };

    async search(page: number = this.state.page) {
        const { perPage } = this.props;
        const { sortKey, sortDescending } = this.state;
        this.setState({ isLoading: true });

        const searchResult = await this.props.search(page, perPage!, sortKey, sortDescending);
        this.setState({
            page,
            isLoading: false,
            rows: searchResult.rows,
            totalPages: Math.ceil(searchResult.total / this.props.perPage!),
        });
    }

    render() {
        const { state, props } = this;
        return (
            <DataTable
                id={this.props.id}
                columns={state.columns}
                isLoading={state.isLoading}
                page={state.page}
                rows={state.rows}
                rowClassName={props.rowClassName}
                sortDescending={state.sortDescending}
                sortKey={state.sortKey}
                totalPages={state.totalPages}
                emptyLabel={props.emptyLabel}
                onPageClick={this.onPageClick}
                onRowClick={this.props.onRowClick}
                onSortChange={this.onSortChange}
            />
        );
    }
}
