import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { replace } from 'connected-react-router';
import { Dictionary } from 'lodash';
import * as qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';

import { generalFailure, ovationsAccess } from 'config/messages';
import { ContainerProps } from 'containers/Container';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import * as call from 'redux-modules/call';
import * as client from 'redux-modules/client';
import * as profile from 'redux-modules/profile';
import * as role from 'redux-modules/role';

interface LoginState {
    error: string;
}

export interface LoginProps extends ContainerProps<{}> {}

export class LoginContainer extends React.Component<LoginProps, LoginState> {
    essentialDataPromise: Promise<
        [
            OvationsApi.Types.CallState | undefined,
            OvationsApi.Types.PlatformUserProfile,
            Dictionary<OvationsApi.Types.PlatformRole>,
            void,
        ]
    >;

    constructor(props: LoginProps) {
        super(props);

        this.state = {
            error: '',
        };
    }

    async componentDidMount() {
        this.essentialDataPromise = Promise.all([
            this.props.dispatch(call.actions.retrieve()),
            this.props.dispatch(profile.actions.fetchMe()),
            this.props.dispatch(role.actions.fetchAll()),
            this.props.dispatch(client.actions.fetchAll()),
        ]);
        try {
            await this.essentialDataPromise;
            this.props.dispatch(replace(qs.parse(this.props.location.search).redirect as string));
        } catch (e) {
            if (e.response && (e.response.status === 403 || e.response.status === 404)) {
                this.setState({ error: ovationsAccess() });
            } else {
                this.setState({ error: generalFailure() });
            }
        }
    }

    render() {
        // error
        if (this.state.error) {
            const error = <p className="p-4">{this.state.error}</p>;
            return error;
        }

        // loadingSpinner
        const loadingSpinner = (
            <div className="pt-5 text-center loading-spinner">
                <FAIcon icon={faCircleNotch} size="2x" className="text-secondary spin" />
            </div>
        );
        return loadingSpinner;
    }
}

/* istanbul ignore next */
export default connect((state) => state)(LoginContainer);
