import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';

import App from 'App';
import configureStore from 'lib/configureStore';
import { unregister } from 'registerServiceWorker';

import 'styles/index.scss';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Route component={App} />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement,
);
unregister();
