import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, UncontrolledDropdown } from 'reactstrap';

import { clientNavLinkDefs } from 'components/landmarks/LandmarkConstants';
import SubNav, { SubNavLinkDef } from 'components/landmarks/SubNav';
import elements from 'config/elements';
import * as routes from 'config/routes';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { isShallowEqual } from 'core/util/objects';
import Authorize from 'lib/Authorize';
import primaryLogo from 'media/images/ovations-logo.svg';

export interface LinkDef {
    icon: IconDefinition;
    label: string;
    href: (params: { clientId: string; programId?: string }) => string | void;
    permissions?: string[];
    labelWhenInSubNav?: string;
    subNavOptions?: SubNavLinkDef[];
}

interface ClientNavProps {
    clientId: string;
    currentPath: string;
    logoUrl: string | null;
    programs: OvationsApi.Types.Program[];
    programId?: string;
    hasRequiredPermissions: (permissions: string[] | undefined) => boolean;
}

export default class ClientNav extends React.Component<ClientNavProps> {
    shouldComponentUpdate(nextProps: ClientNavProps) {
        return !isShallowEqual(this.props, nextProps);
    }

    getAuthorizedSubNavOptions(linkDef: LinkDef) {
        if (!linkDef.subNavOptions) {
            return [];
        }

        return linkDef.subNavOptions.filter(
            (subNavOption) => !subNavOption.permissions || this.props.hasRequiredPermissions(subNavOption.permissions),
        );
    }

    renderProgramDropdown() {
        const { clientId, programId } = this.props;
        const activeProgram = this.props.programs.find((program) => program.id === programId);
        const activeProgramName = activeProgram ? activeProgram.name : undefined;

        return (
            <UncontrolledDropdown className={elements.clientNav.class.programDropdown}>
                <DropdownToggle
                    color="primary"
                    caret
                    size="lg"
                    className="d-flex justify-content-between align-items-center w-100 rectified py-3 btn--soft"
                >
                    <span className="client-nav__dropdown-toggle" title={activeProgramName}>
                        {activeProgramName || 'Select a program'}
                    </span>
                </DropdownToggle>
                {this.props.programs.length > 0 && (
                    <DropdownMenu
                        flip={false}
                        className={classNames('client-nav__dropdown-menu', 'client-nav__dropdown-menu-scrollY')}
                    >
                        {this.props.programs.map((program) => (
                            <DropdownItem
                                key={program.id}
                                tag={Link}
                                {...{ to: toPath(routes.PROGRAM_CLAIMS, { clientId, programId: program.id }) }}
                            >
                                {program.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                )}
            </UncontrolledDropdown>
        );
    }

    renderStaticNavItem(linkDef: LinkDef) {
        const href = linkDef.href(this.props);
        let linkProps;
        let active = false;
        if (href) {
            active = this.props.currentPath.startsWith(href);
            // Program details will never be an active link due to the context switching
            if (linkDef.label === 'Program Settings') {
                active = false;
            }
            linkProps = { tag: Link, to: href, active };
        }
        const iconClassName = classNames('me-2', { 'text-primary': active });
        return (
            <ListGroupItem action className={classNames({ disabled: !href })} tag="div" {...linkProps}>
                {linkDef.icon && <FAIcon icon={linkDef.icon} fixedWidth className={iconClassName} />}
                <span className={classNames('text-uppercase text-smaller', { 'fw-bold': active })}>
                    {linkDef.label}
                </span>
            </ListGroupItem>
        );
    }

    renderNavItemWithSubNav(linkDef: LinkDef, subNavOptions: SubNavLinkDef[]) {
        if (!linkDef.subNavOptions) {
            return null;
        }

        const { clientId, programId, currentPath } = this.props;
        const parentPath = linkDef.href(this.props);

        const parentLinkAsSubNavOption: SubNavLinkDef = {
            label: linkDef.labelWhenInSubNav || linkDef.label,
            href: linkDef.href,
            exact: true,
        };

        return (
            <SubNav
                label={linkDef.label}
                icon={linkDef.icon}
                options={[parentLinkAsSubNavOption, ...subNavOptions]}
                clientId={clientId}
                programId={programId}
                currentPath={currentPath}
                parentPath={parentPath}
            />
        );
    }

    renderNavItem(linkDef: LinkDef) {
        const authorizedOptions = this.getAuthorizedSubNavOptions(linkDef);

        return isEmpty(authorizedOptions)
            ? this.renderStaticNavItem(linkDef)
            : this.renderNavItemWithSubNav(linkDef, authorizedOptions);
    }

    renderProgramLinks() {
        return (
            <>
                {clientNavLinkDefs.map((linkDef, i) => (
                    <Authorize key={i} permissions={linkDef.permissions}>
                        {this.renderNavItem(linkDef)}
                    </Authorize>
                ))}
            </>
        );
    }

    render() {
        const { logoUrl, programId } = this.props;
        const backgroundImage = logoUrl ? `url(${logoUrl})` : undefined;
        const { programLinks } = elements.clientNav.class;
        const activeProgram = this.props.programs.find((program) => program.id === programId);

        return (
            <div className="client-nav">
                <div className="client-nav__client-logo" style={{ backgroundImage }} aria-hidden="true" />
                <div className="client-nav__program-dropdown">{this.renderProgramDropdown()}</div>
                <div className="client-nav__inner">
                    {activeProgram && (
                        <ListGroup tag="nav" className={`list-group-flush list-group--accent pt-4 ${programLinks}`}>
                            {this.renderProgramLinks()}
                        </ListGroup>
                    )}
                </div>
                <div className="p-3 text-center">
                    <img src={primaryLogo} alt="" className="client-nav__primary-logo" />
                </div>
            </div>
        );
    }
}
