export enum SystemLevel {
    ManageClients = 'client.manage',
    ManageUsers = 'platformUsers.manage',
    ConfigureRoles = 'roles.configure',
}

export enum ClientLevel {
    AddressCertification = 'address.certify',
    ManagePrograms = 'programs.manage',
    ViewPrograms = 'programs.view',
    ManagePromotions = 'promotions.manage',
    ViewPromotions = 'promotions.view',
    ManagePortal = 'portal.manage',
    ViewPortal = 'portal.view',
    ManageCallTypes = 'callTypes.manage',
    ViewCallTypes = 'callTypes.view',
    AcceptCalls = 'calls.accept',
    ValidateClaims = 'claims.validate',
    ProcessMailInClaims = 'claims.mailIns',
    ReviewDuplicates = 'claims.duplicates.review',
    ViewBatchConfigurations = 'batchConfigurations.view',
    ManageBatchConfigurations = 'batchConfigurations.manage',
    ViewBatches = 'batches.view',
    ManageBatches = 'batches.manage',
    ManageReasonCodes = 'reasons.manage',
    VerifyFunding = 'batches.verifyFunding',
    CancelBatches = 'batches.cancel',
    SendBatches = 'batches.send',
    CreateAppeasement = 'appeasement.create',
    ReviewAppeasements = 'appeasement.review',
    ViewSalesIncentives = 'salesIncentives.view',
    ManageSalesIncentives = 'salesIncentives.manage',
    ManageReports = 'reports.manage',
    SSNEdit = 'ssn.edit',
}
