import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { includes } from 'lodash';
import React from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import DataTable from 'components/DataTable';
import elements from 'config/elements';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { removeFirst } from 'core/util/arrays';

interface UserClientRolesProps {
    clients: OvationsApi.Types.Client[];
    roles: OvationsApi.Types.PlatformRole[];
    userRoles: OvationsApi.Types.ClientRole[];
    onRolesChange: (userRoles: OvationsApi.Types.ClientRole[]) => void;
}

interface UserClientRolesState {
    clientBeingEdited?: string;
}

export default class UserClientRoles extends React.Component<UserClientRolesProps, UserClientRolesState> {
    constructor(props: UserClientRolesProps) {
        super(props);
        this.state = {
            clientBeingEdited: undefined,
        };
    }

    onRowClick = (client: OvationsApi.Types.Client) => {
        this.setState({ clientBeingEdited: client.id });
    };

    onRoleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!this.state.clientBeingEdited) {
            return;
        }
        const { clientBeingEdited } = this.state;
        const { checked, value } = e.currentTarget;
        let userRoles;
        if (checked) {
            userRoles = [...this.props.userRoles, { clientId: clientBeingEdited, roleId: value }];
        } else {
            userRoles = removeFirst(
                this.props.userRoles,
                ({ roleId, clientId }) => roleId === value && clientId === clientBeingEdited,
            );
        }

        this.props.onRolesChange(userRoles);
    };

    onModalClose = () => {
        this.setState({ clientBeingEdited: undefined });
    };

    renderRoleCell = (client: OvationsApi.Types.Client, key: string) => {
        const { props } = this;
        const clientUserRoles = props.roles.filter((role) => {
            const userHasRole = props.userRoles.some((userRole) => {
                return userRole.clientId === client.id && userRole.roleId === role.id;
            });
            return userHasRole;
        });
        return <td key={key}>{clientUserRoles.length ? clientUserRoles.map((r) => r.name).join(', ') : 'None'}</td>;
    };

    render() {
        const { props, state } = this;
        const activeClient = props.clients.find((c) => c.id === state.clientBeingEdited);
        const activeClientRoles = !activeClient
            ? []
            : props.userRoles.filter((r) => r.clientId === activeClient.id).map((r) => r.roleId);

        return (
            <div>
                <Modal
                    className={elements.editUserClientRolesModal.class.modal}
                    isOpen={activeClient !== undefined}
                    autoFocus={false}
                >
                    <ModalHeader tag="h5" toggle={this.onModalClose}>
                        {activeClient && activeClient.name} Roles
                    </ModalHeader>
                    <ModalBody>
                        <Row id={elements.editUserClientRolesModal.id.userClientRoles}>
                            {this.props.roles.map((role, i) => (
                                <Col key={role.id} xs={6} className="pb-2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="role"
                                                value={role.id}
                                                checked={includes(activeClientRoles, role.id)}
                                                autoFocus={i === 0}
                                                onChange={this.onRoleChange}
                                            />
                                            {role.name}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                </Modal>
                <DataTable
                    id={elements.userSettingsPage.id.userClientRoleTable}
                    columns={[
                        {
                            key: 'name',
                            header: 'Client',
                            cell: (row, key) => (
                                <td key={key} className="text-nowrap">
                                    {row[key]}
                                </td>
                            ),
                        },
                        {
                            key: 'roles',
                            header: 'Roles',
                            headerClassName: 'w-100',
                            cell: this.renderRoleCell,
                        },
                        {
                            key: 'edit',
                            header: '',
                            cell: (row, key) => (
                                <td key={key} className="pt-0 pb-0 text-center align-middle">
                                    <button className="interactive-icon">
                                        <FAIcon icon={faPencilAlt} />
                                    </button>
                                </td>
                            ),
                        },
                    ]}
                    rows={this.props.clients}
                    onRowClick={this.onRowClick}
                />
            </div>
        );
    }
}
