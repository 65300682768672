import * as OvationsApi from 'core/ovations-api';
import { API_URL } from 'core/ovations-api/constants';
import UpdateCallRequest from 'ovations-hubs/definitions/UpdateCallRequest';

import configureHub, { HubConfiguration, HubListener } from './configureHub';

let _hubConfig: HubConfiguration | null = null;
const _getHubConfig = (): HubConfiguration => {
    if (!_hubConfig) {
        _hubConfig = configureHub(`${API_URL}/hubs/callhub`);
    }
    return _hubConfig;
};

export default class CallHub {
    static async beginCall(clientId: string, programId: string, customerId: string) {
        const { request } = _getHubConfig();
        const response = await request<number>('beginCall', clientId, programId, customerId);
        return response;
    }

    static async onCallStarted(listener: HubListener<OvationsApi.Types.CallState>) {
        const { on } = _getHubConfig();
        const unlistener = await on('callStarted', listener);
        return unlistener;
    }

    static async endCall(clientId: string, call: UpdateCallRequest) {
        const { request } = _getHubConfig();
        await request('endCall', clientId, call);
    }

    static async updateCall(clientId: string, call: UpdateCallRequest) {
        const { request } = _getHubConfig();
        await request('updateCall', clientId, call);
    }
}
