/* tslint:disable max-line-length */ /* for clarity's sake */
import { NEW_TOKEN } from 'config/global';

const clientPath = (path: string) => `/clients/:clientId/${path}`;
const programPath = (path: string) => clientPath(`programs/:programId/${path}`);
const inCallPath = (path: string) => `/call${programPath(`customers/:customerId${path}`)}`;

export const CLIENTS = '/clients';
export const CLIENT_DETAIL = '/clients/:clientId';

export const CLIENT_REPORTS = '/clients/:clientId/reports';
export const CLIENT_SETTINGS = '/clients/:clientId/settings';

export const CLIENT_PROGRAMS = '/clients/:clientId/programs';
export const CLIENT_PROGRAM_CREATE = '/clients/:clientId/programs/new';
export const CLIENT_PROGRAM_DETAIL = '/clients/:clientId/programs/:programId';
export const PROGRAM_DATA_SETS = programPath('datasets');
export const PROGRAM_DOCUMENTS = programPath('documents');
export const PROGRAM_REWARD_PACKAGES = programPath('reward-packages');
export const PROGRAM_CALL_TYPES = programPath('calltypes');
export const PROGRAM_REASON_CODES = programPath('reason-codes');
export const PROGRAM_LANGUAGES = programPath('languages');
export const PROGRAM_PROMOTION_GROUPS = programPath('promotion-groups');
export const PROGRAM_PROMOTION_GROUP_CREATE = programPath(`promotion-groups/${NEW_TOKEN}`);
export const PROGRAM_PROMOTION_GROUP_DETAIL = programPath('promotion-groups/:promotionGroupId');
export const PROGRAM_FILE_IMPORT = programPath('file-import');
export const PROGRAM_API_KEYS = programPath('api-keys');

export const PROGRAM_CLAIMS = programPath('claims');
export const PROGRAM_CLAIMS_VALIDATE = programPath('claims/validate/:claimId?');
export const PROGRAM_CLAIMS_PROCESS_MAIL_IN = programPath('claims/process-mail-in/:customerId?');
export const PROGRAM_CLAIMS_PROCESS_MAIL_IN_PROMOTION = programPath(
    'claims/process-mail-in/:customerId?/promotion/:promotionId',
);
export const PROGRAM_CLAIM_DETAIL = programPath('claims/:claimId');
export const PROGRAM_CLAIM_INDEX = programPath('claims/index/:index');
export const PROGRAM_CLAIM_HISTORY = programPath('claims/:claimId/history');
export const PROGRAM_CLAIM_REWARDS_DETAIL = programPath('claims/:claimId/rewards-detail');
export const PROGRAM_CLAIM_RECONCILIATION = programPath('claims/:claimId/reconciliation');

export const PROGRAM_CUSTOMERS = programPath('customers');

export const PROGRAM_BATCHES = programPath('batches');
export const PROGRAM_BATCHED_ADDRESS_CERTIFICATION = programPath('batches/address-certification/:addressId?');
export const PROGRAM_BATCHED_ADDRESS_CERTIFICATION_SINGLE = programPath('batches/address-certification/:addressId');
export const PROGRAM_BATCHED_ADDRESS_CERTIFICATION_QUEUE = programPath(
    'batches/address-certification/:addressId/queue',
);
export const PROGRAM_BATCHES_BATCH_DETAIL = programPath('batches/:batchId');
export const PROGRAM_BATCHES_BATCH_INDEX = programPath('batches/index/:index');
export const PROGRAM_BATCHES_BATCH_DETAIL_DETAILS = programPath('batches/:batchId');
export const PROGRAM_BATCHES_BATCH_DETAIL_CLAIMS = programPath('batches/:batchId/claims');
export const PROGRAM_BATCHES_BATCH_DETAIL_APPEASEMENTS = programPath('batches/:batchId/appeasements');
export const PROGRAM_BATCHES_BATCH_DETAIL_CUSTOMER_LIST = programPath('batches/:batchId/customer-list');
export const PROGRAM_BATCHES_FULFILLMENT_SETTINGS = programPath('batches/fulfillment-settings');
export const PROGRAM_BATCHES_FULFILLMENT_SETTINGS_APPEASEMENT = programPath('batches/fulfillment-settings');
export const PROGRAM_BATCHES_FULFILLMENT_SETTINGS_BATCH_JOBS = programPath('batches/fulfillment-settings/batch-jobs');

export const PROGRAM_PROMOTIONS = programPath('promotions');

export const PORTAL_SETTINGS = programPath('portal');

export const PORTAL_USER_SETTINGS = programPath('portal/user-settings');
export const PORTAL_PROFILE_SETTINGS = programPath('portal/profile-settings');
export const PORTAL_GLOBAL = programPath('portal/:culture?/global');
export const PORTAL_GLOBAL_HEADER = programPath('portal/global/header');
export const PORTAL_GLOBAL_FOOTER = programPath('portal/global/footer');
export const PORTAL_GLOBAL_FAVICON_LOGO = programPath('portal/:culture?/global/favicon-logo');
export const PORTAL_GLOBAL_FONTS_BUTTONS = programPath('portal/global/fonts-buttons');
export const PORTAL_GLOBAL_CHANNELS = programPath('portal/global/channels');

export const PORTAL_HOME = programPath('portal/:culture?/home');
export const PORTAL_HOME_BANNER = programPath('portal/:culture?/home/banner');
export const PORTAL_HOME_PROMOTIONAL_CONTENT = programPath('portal/:culture?/home/promotional-content');
export const PORTAL_HOME_COPYWRITING = programPath('portal/home/copywriting');

export const PORTAL_NOTIFICATIONS = programPath('portal/notifications');
export const PORTAL_NOTIFICATIONS_SUBMISSION_DISQUAL = programPath('portal/notifications/submission');
export const PORTAL_NOTIFICATIONS_INCOMPLETE_ADDITIONAL = programPath('portal/notifications/incomplete');
export const PORTAL_NOTIFICATIONS_REGISTRATION_SUCCESS = programPath('portal/notifications/registration-success');
export const PORTAL_NOTIFICATIONS_PASSWORD_UPDATES = programPath('portal/notifications/password-updates');
export const PORTAL_REFERRED_INDIVIDUAL_CONFIRMATION = programPath(
    'portal/notifications/referred-individual-confirmation',
);
export const PORTAL_READY_TO_REDEEM = programPath('portal/notifications/ready-to-redeem');
export const PORTAL_NOTIFICATIONS_REWARD_FULFILLMENT = programPath('portal/notifications/reward-fulfillment');
export const PORTAL_NOTIFICATIONS_ACCOUNT_UPDATED = programPath('portal/notifications/account-updated');

export const PORTAL_TIER_SETS = programPath('portal/tierSets');
export const PORTAL_TIER_SETS_DETAIL = programPath('portal/tierSets-detail/:tierSetId?');

export const PORTAL_DASHBOARD = programPath('portal/:culture?/dashboard');

export const PORTAL_CONTESTS = programPath('portal/contests');
export const PORTAL_CONTEST_DETAIL = programPath('portal/contest-detail/:leaderboardId');
export const PORTAL_CONTEST_CREATE = programPath(`portal/contests/${NEW_TOKEN}`);

export const PORTAL_CONTACT = programPath('portal/:culture?/contact');

export const PORTAL_FAQ = programPath('portal/faq');

export const PORTAL_REPORTS = programPath('portal/reports');

export const PORTAL_POINTS_CATALOG = programPath('portal/points-catalog');

export const PORTAL_REFERRAL_RESPONSE_PAGE = programPath('portal/referral-response-page');

export const PROGRAM_PROMOTION_CREATE = programPath('promotions/new');
export const PROGRAM_PROMOTION_INDEX = programPath('promotions/index/:index');
export const PROGRAM_PROMOTION_DETAIL = programPath('promotions/:promotionId');
export const PROGRAM_PROMOTION_CLAIM_FORM = programPath('promotions/:promotionId/claim-form');
export const PROGRAM_PROMOTION_CLAIM_FORM_CONFIG = programPath(
    'promotions/:promotionId/claim-form/config/:formConfigType?',
);
export const PROGRAM_PROMOTION_REWARDS = programPath('promotions/:promotionId/rewards');
export const PROGRAM_PROMOTION_REWARD_LIMITS = programPath('promotions/:promotionId/reward-limits');
export const PROGRAM_PROMOTION_ELIGIBILIY = programPath('promotions/:promotionId/eligibility');
export const PROGRAM_PROMOTION_BILLING = programPath('promotions/:promotionId/billing');

export const CUSTOMER_DETAIL = programPath('customers/:customerId');
export const CUSTOMER_LIST = programPath('customers/list');

export const PROGRAM_APPEASEMENTS = programPath('batches/appeasements');
export const PROGRAM_APPEASEMENT_DETAIL = programPath('batches/appeasements/:appeasementId');
export const PROGRAM_APPEASEMENT_INDEX = programPath('batches/appeasements/index/:index');

export const PROGRAM_SALES_INCENTIVES = programPath('sales-incentives');
export const PROGRAM_SALES_INCENTIVE_INDEX = programPath('sales-incentives/index/:index');
export const PROGRAM_SALES_INCENTIVE_CREATE = programPath(`sales-incentives/${NEW_TOKEN}`);
export const PROGRAM_SALES_INCENTIVE_DETAIL = programPath('sales-incentives/:salesIncentiveId');
export const PROGRAM_SALES_INCENTIVE_BILLING = programPath('sales-incentives/:salesIncentiveId/billing');

export const ROLES = '/roles';
export const ROLE_CREATE = '/roles/new';
export const ROLE_DETAIL = '/roles/:roleId';

export const USERS = '/users';
export const USER_DETAIL = '/users/:userId';
export const USER_ROLES = '/users/:userId/client-roles';
export const USER_HISTORY = '/users/:userId/history';

export const IN_CALL = inCallPath('');
export const IN_CALL_CLAIM_DETAIL = inCallPath('/claims/:claimId');

export const VIEW_DUPE_ATTACHMENTS = programPath('claims/:claimId/attachments');
export const VIEW_MISSING_FIELDS = '/view-missing-fields';
