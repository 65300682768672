import { debounce, stubTrue } from 'lodash';
import React, { useState } from 'react';

import { DEFAULT_TYPEAHEAD_RESULTS } from 'config/global';
import { noResults } from 'config/messages';
import * as OvationsApi from 'core/ovations-api';
import { Program, SearchResultsResponse } from 'core/ovations-api/definitions';
import { Typeahead } from 'react-bootstrap-typeahead';

interface ProgramSearchTypeaheadProps {
    clientId: string;
    updateProgramResults: (programResults: Program[]) => void;
    page?: number;
}

export const ProgramSearchTypeahead: React.FC<ProgramSearchTypeaheadProps> = (props) => {
    const [isSearching, setisSearching] = useState(false);
    const [programs, setPrograms] = useState<Program[]>([]);

    const onOptionSelected = async (selectedProgram: Program | undefined) => {
        let searchQuery = '';
        if (selectedProgram) {
            searchQuery = selectedProgram.name;
        }
        let searchResultsResponse: SearchResultsResponse<Program> = { totalResults: 0, results: [] };
        try {
            searchResultsResponse = await OvationsApi.Program.search(props.clientId, {
                query: searchQuery,
            });
        } catch (error) {
            searchResultsResponse.results = [];
        } finally {
            props.updateProgramResults(searchResultsResponse.results);
        }
    };

    const searchPrograms = debounce(async (query: string) => {
        setisSearching(true);
        try {
            const filteredPrograms = await OvationsApi.Program.search(props.clientId, {
                query,
                maxResults: DEFAULT_TYPEAHEAD_RESULTS,
            });
            setPrograms(filteredPrograms.results);
        } catch (e) {
            setPrograms([]);
        } finally {
            setisSearching(false);
            if (query === '') {
                onOptionSelected(undefined);
            }
        }
    }, 200);

    const onProgramSearchInputChange = (query: string) => {
        searchPrograms(query);
    };

    return (
        <Typeahead
            id="program-search-typeahead"
            options={programs}
            onChange={(data: Program[]) => onOptionSelected(data[0])} // when the drop down card is clicked
            placeholder="Search by name"
            emptyLabel={noResults()}
            onInputChange={onProgramSearchInputChange} // when you type in the input, and the card should drop down with populated results
            isLoading={isSearching}
            filterBy={stubTrue}
            labelKey={(program: Program) => program.name}
            maxResults={DEFAULT_TYPEAHEAD_RESULTS}
        />
    );
};
