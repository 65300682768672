import { faChevronRight, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import phoneFormatter from 'phone-formatter';
import React from 'react';
import { Input, Label, Button } from 'reactstrap';

import DataTable, { TableColumn } from 'components/DataTable';
import MiniPaginate from 'components/MiniPaginate';
import elements from 'config/elements';
import { DEFAULT_PAGE_SIZE, PHONE_FORMAT_US } from 'config/global';
import * as messages from 'config/messages';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';

export interface CustomerTableProps {
    customerList: OvationsApi.Types.Customer[];
    isSearching: boolean;
    totalResults: number;
    onPageChange: (page: number) => void;
    onRowClick?: (row: OvationsApi.Types.Customer) => void;
    onSearch: (key: string, value: string) => void;
    onReset: () => void;
    searchRequest: OvationsApi.Types.CustomerSearchRequest;
}

export default class CustomerTable extends React.Component<CustomerTableProps> {
    columns: Array<TableColumn<OvationsApi.Types.Customer>>;

    constructor(props: CustomerTableProps) {
        super(props);
        this.columns = this.generateColumns();
    }

    onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;
        this.props.onSearch(name, value);
    };

    // eslint-disable-next-line react/function-component-definition
    getHeaderRenderer = (label: string) => (column: TableColumn<OvationsApi.Types.Customer>) =>
        (
            <Label>
                <span className="d-block label">{label}</span>
                <Input
                    autoComplete="off"
                    // eslint-disable-next-line react/no-this-in-sfc
                    onChange={this.onSearch}
                    name={column.key}
                    // eslint-disable-next-line react/no-this-in-sfc
                    value={this.props.searchRequest[column.key] || ''}
                />
            </Label>
        );

    generateColumns(): Array<TableColumn<OvationsApi.Types.Customer>> {
        return [
            {
                key: 'firstName',
                header: this.getHeaderRenderer('First Name'),
                headerClassName: 'text-nowrap',
            },
            {
                key: 'lastName',
                header: this.getHeaderRenderer('Last Name'),
                headerClassName: 'text-nowrap',
            },
            {
                key: 'phone',
                header: this.getHeaderRenderer('Phone Number'),
                headerClassName: 'text-nowrap',
                cell: (row, key) => (
                    <td key={key}>{row.phoneNumber && phoneFormatter.format(row.phoneNumber, PHONE_FORMAT_US)}</td>
                ),
            },
            {
                key: 'email',
                header: this.getHeaderRenderer('Email'),
                headerClassName: 'text-nowrap',
            },
            {
                key: 'address1',
                header: this.getHeaderRenderer('Address 1'),
                headerClassName: 'text-nowrap',
                cell: (row, key) => <td key={key}>{row.address.address1}</td>,
            },
            {
                key: 'navigate',
                header: (
                    <>
                        {this.renderPager()}
                        <div className="mb-2 text-end">
                            <Button outline color="danger" onClick={this.props.onReset}>
                                <FAIcon icon={faRedoAlt} /> Reset
                            </Button>
                        </div>
                    </>
                ),
                headerClassName: 'text-nowrap',
                cell: (row, key) => (
                    <td key={key} className="pt-2 text-end">
                        <button className="interactive-icon">
                            <FAIcon icon={faChevronRight} />
                        </button>
                    </td>
                ),
            },
        ];
    }

    renderPager() {
        return (
            <div className="ms-auto pb-4">
                <MiniPaginate
                    id={elements.customerList.id.pager}
                    page={this.props.searchRequest.page || 1}
                    perPage={this.props.searchRequest.pageSize || DEFAULT_PAGE_SIZE}
                    total={this.props.totalResults}
                    onArrowClick={this.props.onPageChange}
                    noun="Customer"
                />
            </div>
        );
    }

    render() {
        const pageSize = this.props.searchRequest.pageSize || DEFAULT_PAGE_SIZE;
        return (
            <DataTable
                id={elements.customerList.id.table}
                isLoading={this.props.isSearching}
                columns={this.columns}
                rows={this.props.customerList}
                rowClassName={() => elements.customerList.class.customerSearchResult}
                page={this.props.searchRequest.page || 1}
                totalPages={Math.ceil(this.props.totalResults / pageSize)}
                onPageClick={this.props.onPageChange}
                onRowClick={this.props.onRowClick}
                emptyLabel={this.props.totalResults <= 0 ? messages.noResults() : undefined}
            />
        );
    }
}
