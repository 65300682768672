import { History } from 'history';
import { flatten, includes, keyBy, uniq } from 'lodash';
import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { createSelector } from 'reselect';

import { connectRouter } from 'connected-react-router';
import { ClientLevel } from 'enums/Permission';
import ReduxContextManager from 'lib/ReduxContextManager';
import { addressCertificationReducer } from 'redux-modules/addressCertification';
import { appeasementReducer } from 'redux-modules/appeasement';
import { batchReducer } from 'redux-modules/batch';
import { batchConfigurationReducer } from 'redux-modules/batchConfiguration';
import { callReducer } from 'redux-modules/call';
import { callDispositionReducer } from 'redux-modules/callDisposition';
import { callTypeReducer } from 'redux-modules/callType';
import { claimReducer } from 'redux-modules/claim';
import { fileImportReducer } from 'redux-modules/fileImport';
import { claimListReducer } from 'redux-modules/claimList';
import { clientReducer } from 'redux-modules/client';
import { customAttributeValuesReducer } from 'redux-modules/customAttributeValues';
import { customerReducer } from 'redux-modules/customer';
import { dataSetReducer } from 'redux-modules/dataSet';
import ClientContext from 'redux-modules/definitions/ClientContext';
import S from 'redux-modules/definitions/RootState';
import { documentReducer } from 'redux-modules/document';
import { layoutReducer } from 'redux-modules/layout';
import { leaderboardReducer } from 'redux-modules/leaderboard';
import { notificationReducer } from 'redux-modules/notification';
import { permissionReducer } from 'redux-modules/permission';
import { portalReducer } from 'redux-modules/portal';
import { portalContactReducer } from 'redux-modules/portalContact';
import { portalDashboardReducer } from 'redux-modules/portalDashboard';
import { portalFAQReducer } from 'redux-modules/portalFAQ';
import { portalNotificationReducer } from 'redux-modules/portalNotification';
import { portalPointsCatalogReducer } from 'redux-modules/portalPointsCatalog';
import { portalRoleReducer } from 'redux-modules/portalRole';
import { profileReducer, selectors as profileSelectors } from 'redux-modules/profile';
import { profileSettingsReducer } from 'redux-modules/profileSettings';
import { programReducer } from 'redux-modules/program';
import { promotionReducer } from 'redux-modules/promotion';
import { questionReducer } from 'redux-modules/question';
import { reasonReducer } from 'redux-modules/reason';
import { reportReducer } from 'redux-modules/report';
import { rewardPackageReducer } from 'redux-modules/rewardPackage';
import { roleReducer } from 'redux-modules/role';
import { selectors as routerSelectors } from 'redux-modules/router';
import { salesIncentiveReducer } from 'redux-modules/salesIncentive';
import { settingsReducer } from 'redux-modules/settings';

export const clientContextManager = new ReduxContextManager<S, ClientContext>(
    'clientContexts/UPDATE',
    (state) => state.clientContexts,
);

const clientContextsReducer = clientContextManager.combineReducers({
    addressCertification: addressCertificationReducer,
    appeasement: appeasementReducer,
    batch: batchReducer,
    batchConfiguration: batchConfigurationReducer,
    callType: callTypeReducer,
    callDisposition: callDispositionReducer,
    claim: claimReducer,
    claimList: claimListReducer,
    fileImport: fileImportReducer,
    customer: customerReducer,
    dataSet: dataSetReducer,
    document: documentReducer,
    portal: portalReducer,
    portalContact: portalContactReducer,
    leaderboard: leaderboardReducer,
    portalDashboard: portalDashboardReducer,
    customAttributeValues: customAttributeValuesReducer,
    portalFAQ: portalFAQReducer,
    portalNotification: portalNotificationReducer,
    portalPointsCatalog: portalPointsCatalogReducer,
    portalRole: portalRoleReducer,
    profileSettings: profileSettingsReducer,
    program: programReducer,
    promotion: promotionReducer,
    question: questionReducer,
    reason: reasonReducer,
    report: reportReducer,
    rewardPackage: rewardPackageReducer,
    salesIncentive: salesIncentiveReducer,
});

export const createRootReducer = (history: History): Reducer<S> =>
    combineReducers({
        call: callReducer,
        client: clientReducer,
        layout: layoutReducer,
        notification: notificationReducer,
        permission: permissionReducer,
        profile: profileReducer,
        role: roleReducer,
        router: connectRouter(history),
        settings: settingsReducer,
        clientContexts: clientContextsReducer,
    } as ReducersMapObject);

const getFlattenedPerms = createSelector(
    [
        (state: S) => routerSelectors.getClientId(state.router),
        (state: S) => state.role.map,
        (state: S) => profileSelectors.getMe(state.profile).clientRoles,
        (state: S) => profileSelectors.getMe(state.profile).platformPermissions,
    ],
    (clientId, roleMap, userRoles, userPerms) => {
        let rolePermissions: string[] = [];
        if (clientId) {
            const filteredRoles = userRoles.filter((r) => r.clientId === clientId);
            const rolePermissionsGrouped = filteredRoles.map(({ roleId }) => roleMap[roleId].rolePermissions);
            rolePermissions = uniq(flatten(rolePermissionsGrouped));
        }
        return [...rolePermissions, ...userPerms];
    },
);

const getActiveProgram = (state: S) => {
    const clientId = routerSelectors.getClientId(state.router);
    const programId = routerSelectors.getProgramId(state.router);
    if (!clientId || !programId) {
        return;
    }
    const ctx = clientContextManager.getContext(state, clientId);
    return ctx.program.map[programId];
};

export const selectors = {
    getActiveProgram,
    getFlattenedPerms,
    userHasAccess: (state: S, permissions: string[]) => {
        const flattenedPerms = getFlattenedPerms(state);
        return permissions.some((perm) => includes(flattenedPerms, perm));
    },

    getCustomerDetailPath: (state: S, customerId: string, programId?: string): string | undefined => {
        const canAcceptCalls = selectors.userHasAccess(state, [ClientLevel.AcceptCalls]);
        return routerSelectors.getCustomerDetailPath(state.router, customerId, canAcceptCalls, programId);
    },

    getEnabledCountries: createSelector(
        (state: S) => getActiveProgram(state),
        (state: S) => state.settings.supportedCountries,
        (program, countries) => {
            if (!program) {
                return [];
            }
            const countryMap = keyBy(countries, (c) => c.countryCode);
            return program.enabledCountries.map((countryCode) => countryMap[countryCode]);
        },
    ),
};
