import { faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

import { getPage, TableColumn, UncontrolledDataTable } from 'components/DataTable';
import MiniPaginate from 'components/MiniPaginate';
import * as routes from 'config/routes';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import CreateButton from 'core/ovations-components/CreateButton';

type PlatformRole = OvationsApi.Types.PlatformRole;

interface UserRolesListProps {
    onTableRowClick: (row: PlatformRole) => void;
    getRows: () => Promise<PlatformRole[]>;
}

interface UserRolesListState {
    error: string | undefined;
    searchTerm: string;
    searchedTerm: string;
    page: number;
    perPage: number;
    total: number;
}
export default class UserRolesList extends React.Component<UserRolesListProps, UserRolesListState> {
    columns: Array<TableColumn<PlatformRole>> = [
        {
            key: 'name',
            header: 'Role Name',
            headerClassName: 'w-100',
            cell: (row, key) => (
                <th key={key} scope="row">
                    {row[key]}
                </th>
            ),
            sortable: true,
        },
        {
            key: 'edit',
            header: '',
            cell: (row, key) => (
                <td key={key}>
                    <FAIcon icon={faPencilAlt} />
                </td>
            ),
        },
    ];

    userRolestable: UncontrolledDataTable | null;

    constructor(props: UserRolesListProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            error: undefined,
            searchTerm: '',
            searchedTerm: '',
            page: 1,
            perPage: UncontrolledDataTable.defaultProps.perPage,
            total: 0,
        };
    }

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({ searchTerm: e.currentTarget.value }, () => {
            if (this.userRolestable) {
                this.userRolestable.search(1);
            }
        });
    };

    search = async (page: number, perPage: number, sortKey: string, sortDescending: boolean) => {
        const allRows = await this.props.getRows();
        const re = new RegExp(this.state.searchTerm, 'i');
        const filteredRows = allRows.filter((row) => re.test(row.name));
        let rows = filteredRows;
        if (sortKey) {
            rows = sortBy(rows, sortKey);
        }
        if (sortDescending === true) {
            rows = rows.reverse();
        }
        rows = getPage(rows, page, perPage);
        this.setState({
            page,
            perPage,
            total: filteredRows.length,
        });
        return { total: filteredRows.length, rows };
    };

    onRowClick = (row: PlatformRole) => {
        this.props.onTableRowClick(row);
    };

    onMiniPageClick = (page: number) => {
        if (this.userRolestable) {
            this.userRolestable.search(page);
        }
    };

    render() {
        const { state } = this;
        return (
            <div className="wrap mt-4">
                <div className="d-flex justify-content-between align-content-center">
                    <div>
                        <h1 className="h3 m-0">O-vations Roles</h1>
                    </div>
                    <div>
                        <Link to={routes.ROLE_CREATE}>
                            <CreateButton id="createRoleButton" name="New Role" />
                        </Link>
                    </div>
                </div>
                <hr />
                <Row>
                    <Col className="d-flex">
                        <div className="flex-grow">
                            <InputGroup>
                                <InputGroupText>
                                    <FAIcon icon={faSearch} />
                                </InputGroupText>
                                <Input
                                    type="search"
                                    placeholder="Search Roles"
                                    value={state.searchTerm}
                                    onChange={this.onInputChange}
                                />
                            </InputGroup>
                        </div>
                    </Col>
                    <Col className="text-end">
                        <MiniPaginate
                            page={state.page}
                            perPage={state.perPage}
                            total={state.total}
                            onArrowClick={this.onMiniPageClick}
                            noun="Role"
                        />
                    </Col>
                </Row>
                <hr />

                <UncontrolledDataTable
                    ref={(component) => {
                        this.userRolestable = component;
                    }}
                    columns={this.columns}
                    search={this.search}
                    onRowClick={this.onRowClick}
                />
            </div>
        );
    }
}
