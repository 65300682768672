import { faChevronRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import elements from 'config/elements';
import * as messages from 'config/messages';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';

const { newClientModal, confirmNewClientModal } = elements;

export interface ClientCreatorProps {
    isOpen: boolean;
    onAddSuccess: (newClientId: string, clientName: string) => void;
    onModalClose: () => void;
}

interface ClientCreatorState {
    clientName: string;
    error: string;
    isAdding: boolean;
    isConfirming: boolean;
}

export default class ClientCreator extends React.Component<ClientCreatorProps, ClientCreatorState> {
    constructor(props: ClientCreatorProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            clientName: '',
            error: '',
            isAdding: false,
            isConfirming: false,
        };
    }

    onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({ clientName: e.currentTarget.value });
    };

    onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        this.toggleClientConfirmation();
    };

    toggleClientConfirmation = () => {
        this.setState({
            isConfirming: !this.state.isConfirming,
        });
    };

    addClient = async () => {
        const { clientName } = this.state;
        this.setState({ isAdding: true, isConfirming: false });
        try {
            const id = await OvationsApi.Client.create({ name: clientName });
            this.setState(this.getInitialState());
            this.props.onAddSuccess(id, clientName);
        } catch (e) {
            let error = messages.generalFailure();
            if (e.response) {
                if (e.response.status === 409) {
                    error = messages.clientExists();
                } else if (e.response.status === 403) {
                    error = messages.insufficientPermissions();
                }
            }
            this.setState({ isAdding: false, error });
        }
    };

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className={newClientModal.class.modal} autoFocus={false}>
                    <ModalHeader toggle={this.props.onModalClose} tag="h5">
                        New Client
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={this.onSubmit}>
                            <Label htmlFor={newClientModal.id.clientName}>Name this Client</Label>
                            <InputGroup>
                                <Input
                                    autoFocus
                                    type="text"
                                    placeholder="e.g. BFGoodrich"
                                    value={this.state.clientName}
                                    onChange={this.onChange}
                                    id={newClientModal.id.clientName}
                                    maxLength={50}
                                />
                                <Button
                                    color="primary"
                                    disabled={!this.state.clientName.trim() || this.state.isAdding}
                                    id={newClientModal.id.submit}
                                >
                                    {this.state.isAdding ? (
                                        <span>
                                            <span className="pe-2">Creating...</span>
                                            <FAIcon icon={faCircleNotch} className="spin" />
                                        </span>
                                    ) : (
                                        <span>
                                            <span className="pe-2">Create</span>
                                            <FAIcon icon={faChevronRight} />
                                        </span>
                                    )}
                                </Button>
                            </InputGroup>
                        </form>
                        {this.state.error && <div className="text-danger pt-3">{this.state.error}</div>}
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.isConfirming} className={confirmNewClientModal.class.modal}>
                    <ModalHeader tag="h5" toggle={this.toggleClientConfirmation}>
                        Confirm Create Client
                    </ModalHeader>
                    <ModalBody>
                        <dl className="m-0">
                            <dt className="fw-normal">Client to be created:</dt>
                            <dd id={confirmNewClientModal.id.name} className="text-center h3 m-2">
                                {this.state.clientName}
                            </dd>
                        </dl>
                        <p id={confirmNewClientModal.id.message}>
                            Are you ready to create the new client {this.state.clientName}? Once you create this client
                            you will not be able to edit the name of the client.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            id={confirmNewClientModal.id.cancel}
                            color="secondary"
                            outline
                            onClick={this.toggleClientConfirmation}
                        >
                            Back
                        </Button>
                        <Button id={confirmNewClientModal.id.submit} color="primary" onClick={this.addClient}>
                            Confirm New Client
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
