import { DEFAULT_TYPEAHEAD_RESULTS } from 'config/global';
import { noResults } from 'config/messages';
import { Customer, ClientCustomerSearchResultsResponse } from 'core/ovations-api/definitions';
import { debounce, stubTrue, uniqWith, flatten } from 'lodash';
import React, { useState } from 'react';
import { Typeahead, MenuProps, MenuItem, Menu } from 'react-bootstrap-typeahead';
import * as OvationsApi from 'core/ovations-api';
import { emptyClientCustomerSearchResultsResponse } from 'containers/programs/ProgramSearchContainer';
import FAIcon from 'core/ovations-components/FAIcon';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Container, Row, Col } from 'reactstrap';

interface ClientCustomerProgramSearchTypeaheadProps {
    clientId: string;
    updateClientCustomerResults: (clientCustomerResponse: ClientCustomerSearchResultsResponse) => void;
    page?: number;
}

export const ClientCustomerProgramSearchTypeahead: React.FC<ClientCustomerProgramSearchTypeaheadProps> = (props) => {
    const [isSearching, setisSearching] = useState(false);
    const [customers, setCustomers] = useState<Customer[]>([]);

    const onOptionSelected = async (selectedCustomer: Customer | undefined) => {
        let searchQuery = '';
        if (selectedCustomer) {
            searchQuery = `${selectedCustomer.firstName} ${selectedCustomer.lastName}`;
        }
        let searchResultsResponse: ClientCustomerSearchResultsResponse = emptyClientCustomerSearchResultsResponse;
        try {
            searchResultsResponse = await OvationsApi.Customer.searchClientCustomers(props.clientId, {
                query: searchQuery,
            });
        } catch (error) {
            searchResultsResponse = emptyClientCustomerSearchResultsResponse;
        } finally {
            props.updateClientCustomerResults(searchResultsResponse);
        }
    };

    const searchClientCustomers = debounce(async (query: string) => {
        setisSearching(true);
        try {
            const response = await OvationsApi.Customer.searchClientCustomers(props.clientId, {
                query,
                maxResults: DEFAULT_TYPEAHEAD_RESULTS,
            });
            const flattenedCustomers = flatten(response.results.map((r) => r.customers));
            const uniqueCustomers = uniqWith(
                flattenedCustomers,
                (customerA, customerB) =>
                    customerA.firstName === customerB.firstName && customerA.lastName === customerB.lastName,
            );
            setCustomers(uniqueCustomers);
        } catch (e) {
            setCustomers([]);
        } finally {
            setisSearching(false);
            if (query === '') {
                onOptionSelected(undefined);
            }
        }
    }, 200);

    const onClientCustomerSearchInputChange = (query: string) => {
        searchClientCustomers(query);
    };

    const renderMenu = (results: OvationsApi.Types.Customer[], menuProps: MenuProps) => {
        return (
            <Menu {...menuProps}>
                {results.map((item, index) => (
                    <MenuItem key={index} option={item} position={index}>
                        <Container>
                            <Row>
                                <Col>
                                    <FAIcon icon={faUser} size="lg" className="ghost pe-1" />
                                    {item.firstName} {item.lastName}
                                </Col>
                            </Row>
                        </Container>
                    </MenuItem>
                ))}
            </Menu>
        );
    };

    return (
        <Typeahead
            id="program-search-typeahead"
            options={customers}
            onChange={(data: Customer[]) => onOptionSelected(data[0])} // when the drop down card is clicked
            placeholder="Search by name"
            emptyLabel={noResults()}
            onInputChange={onClientCustomerSearchInputChange} // when you type in the input, and the card should drop down with populated results
            isLoading={isSearching}
            filterBy={stubTrue}
            labelKey={(customer: Customer) => `${customer.firstName} ${customer.lastName}`}
            maxResults={DEFAULT_TYPEAHEAD_RESULTS}
            renderMenu={(results, menuProps) => renderMenu(results, menuProps)}
        />
    );
};
