import { Dictionary } from 'lodash';
import { Action, combineReducers, Reducer, ReducersMapObject } from 'redux';

interface GetContexts<RootState, Context> {
    (state: RootState): Dictionary<Context>;
}

interface WrappedAction {
    type: string;
    payload: {
        key: string;
        action: Action;
    };
}

export default class ReduxContextManager<RootState, Context> {
    actionType: string;

    getContexts: GetContexts<RootState, Context>;

    contextReducer: Reducer<Context>;

    constructor(actionType: string, getContexts: GetContexts<RootState, Context>) {
        this.actionType = actionType;
        this.getContexts = getContexts;
    }

    combineReducers(reducers: ReducersMapObject): Reducer<Dictionary<Context>> {
        this.contextReducer = combineReducers(reducers);
        const reducer: Reducer<Dictionary<Context>> = (state = {}, action) => {
            if (action.type !== this.actionType) {
                return state;
            }
            const unwrappedAction = action.payload.action;
            const context = state[action.payload.key];
            const newState = {
                ...state,
                [action.payload.key]: this.contextReducer(context, unwrappedAction),
            };
            return newState;
        };

        return reducer;
    }

    action(key: string, action: Action) {
        const wrappedAction: WrappedAction = {
            type: this.actionType,
            payload: { key, action },
        };
        return wrappedAction;
    }

    getContext(state: RootState, key: string): Context {
        const context = this.getContexts(state)[key];
        if (!context) {
            const emptyContext = this.contextReducer({} as Context, { type: '' });
            return emptyContext;
        }
        return context;
    }
}
