import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { keyBy, values } from 'lodash';
import { ThunkAction } from 'redux-thunk';

import { emptyBatchConfiguration } from 'redux-modules/batchConfiguration';
import BatchState from 'redux-modules/definitions/BatchState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { createSelector } from 'reselect';
import { Action } from 'redux';

export const emptyBatch: OvationsApi.Types.Batch = {
    id: '',
    number: 0,
    configuration: emptyBatchConfiguration,
    status: OvationsApi.Enums.BatchStatus.Created,
    createDate: new Date().toISOString(),
    lastUpdate: new Date().toISOString(),
};

export const initialState: BatchState = {
    map: {},
    batchDetailsMap: {},
    totalResults: 0,
};

const { reducer, update } = createReducer('batch/UPDATE', initialState);
export const batchReducer = reducer;

export const actions = {
    fetchSingle(clientId: string, programId: string, batchId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const batch = await OvationsApi.Batch.fetchSingle(clientId, programId, batchId);
            if (!batch) {
                return;
            }
            dispatch(actions.upsertBatch(clientId, batch));
        };
    },

    fetchDetails(
        clientId: string,
        programId: string,
        batchId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const batchDetails = await OvationsApi.Batch.fetchDetails(clientId, programId, batchId);
            if (!batchDetails) {
                return;
            }
            dispatch(actions.upsertBatchDetails(clientId, batchDetails));
        };
    },

    fetchAll(
        clientId: string,
        programId: string,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.Batch> | undefined>,
        S,
        ExtraArg,
        Action
    > {
        return async (dispatch) => {
            const response = await OvationsApi.Batch.fetchAll(clientId, programId);
            if (!response) {
                return;
            }
            dispatch(actions.upsertBatchList(clientId, response));
            return response;
        };
    },

    search(
        clientId: string,
        programId: string,
        request: Partial<OvationsApi.Types.BatchSearchRequest>,
    ): ThunkAction<Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.Batch>>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const batches = await OvationsApi.Batch.search(clientId, programId, request);
            dispatch(actions.upsertBatchList(clientId, batches));
            return batches;
        };
    },

    cancel(
        clientId: string,
        programId: string,
        batchId: string,
        notes = '',
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Batch.cancel(clientId, programId, batchId, notes);
            await dispatch(actions.fetchDetails(clientId, programId, batchId));
        };
    },

    approveFunds(
        clientId: string,
        programId: string,
        batchId: string,
        notes = '',
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Batch.approveFunds(clientId, programId, batchId, notes);
            await dispatch(actions.fetchDetails(clientId, programId, batchId));
        };
    },

    requestFunds(
        clientId: string,
        programId: string,
        batchId: string,
        notes = '',
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Batch.requestFunds(clientId, programId, batchId, notes);
            await dispatch(actions.fetchDetails(clientId, programId, batchId));
        };
    },

    approveExport(
        clientId: string,
        programId: string,
        batchId: string,
        notes = '',
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Batch.approveExport(clientId, programId, batchId, notes);
            await dispatch(actions.fetchDetails(clientId, programId, batchId));
        };
    },

    receiveFunds(
        clientId: string,
        programId: string,
        batchId: string,
        notes = '',
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Batch.receiveFunds(clientId, programId, batchId, notes);
            await dispatch(actions.fetchDetails(clientId, programId, batchId));
        };
    },

    send(
        clientId: string,
        programId: string,
        batchId: string,
        notes = '',
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Batch.send(clientId, programId, batchId, notes);
            await dispatch(actions.fetchDetails(clientId, programId, batchId));
        };
    },

    upsertBatchDetails(
        clientId: string,
        batchDetails: OvationsApi.Types.BatchDetails,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const batchDetailsMap = { ...ctx.batch.batchDetailsMap, [batchDetails.id]: batchDetails };
            return dispatch(clientContextManager.action(clientId, update({ batchDetailsMap })));
        };
    },

    upsertBatch(clientId: string, batch: OvationsApi.Types.Batch): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.batch.map, [batch.id]: batch };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    upsertBatchList(
        clientId: string,
        response: OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.Batch>,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const map = keyBy(response.results, 'id');
            return dispatch(
                clientContextManager.action(clientId, update({ map, totalResults: response.totalResults })),
            );
        };
    },
};

const getList = createSelector([(state) => state.map], (map) => values(map));

export const selectors = {
    getList,
};
