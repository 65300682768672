import React from 'react';
import { connect } from 'react-redux';

import S from 'redux-modules/definitions/RootState';
import { selectors } from 'redux-modules/root';

export interface AuthorizeProps {
    rootState: S;
    permissions?: string[];
    children?: React.ReactNode;
}

export const Authorize: React.FC<AuthorizeProps> = (props) => {
    if (props.permissions && !selectors.userHasAccess(props.rootState, props.permissions)) {
        return null;
    }
    return <>{props.children}</>;
};

/* istanbul ignore next */
export default connect((state: S) => ({ rootState: state }))(Authorize);
