import React from 'react';

import Spinner from 'components/Spinner';

type AsyncComponentState = {
    component: React.ComponentClass | null;
};

const createAsyncComponent = (importComponent: () => Promise<{ default: React.ComponentClass }>) => {
    class AsyncComponent extends React.Component<{}, AsyncComponentState> {
        constructor(props: {}) {
            super(props);
            this.state = { component: null };
        }

        async componentDidMount() {
            const { default: component } = await importComponent();
            this.setState({ component });
        }

        render() {
            const Component = this.state.component;
            return Component ? <Component {...this.props} /> : <Spinner />;
        }
    }
    return AsyncComponent;
};

export default createAsyncComponent;
