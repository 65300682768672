/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import FlipMove from 'react-flip-move';

import FAIcon from 'core/ovations-components/FAIcon';
import Notification from 'definitions/Notification';
import * as Theme from 'enums/Theme';

interface NotificationsProps {
    items?: Notification[];
    onDismiss?: (id: number) => void;
}

class Notifications extends React.Component<NotificationsProps> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        items: [],
    };

    onDismissClick = (item: Notification) => () => {
        this.props.onDismiss!(item.id);
    };

    renderIcon(item: Notification) {
        switch (item.type) {
            case Theme.Color.Danger:
                return <FAIcon icon={faExclamationCircle} />;
            case Theme.Color.Success:
                return <FAIcon icon={faCheckCircle} />;
            case Theme.Color.Warning:
                return <FAIcon icon={faExclamationTriangle} />;
            default:
                return <FAIcon icon={faInfoCircle} />;
        }
    }

    renderNotification(item: Notification) {
        return (
            <div key={item.id} className={`card card--accent card--accent--${item.type} mb-3`}>
                <div className="m-2">
                    <div className="d-flex">
                        <div className="mt-1 me-2">
                            <div className={`h5 text-${item.type}`}>{this.renderIcon(item)}</div>
                        </div>
                        <div className="mt-1 me-1 flex-grow">
                            {item.heading && <div className={`h5 text-${item.type}`}>{item.heading}</div>}
                            <p className="card-text">{item.body}</p>
                        </div>
                        <div>
                            {this.props.onDismiss && (
                                <button
                                    type="button"
                                    className="btn-close close"
                                    onClick={this.onDismissClick(item)}
                                    aria-label="Close"
                                >
                                    <FAIcon icon={faTimes} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="notifications">
                <FlipMove
                    duration={300}
                    enterAnimation={{
                        from: { transform: 'translateY(10px)', opacity: '0' },
                        to: { transform: 'translateY(0)', opacity: '1' },
                    }}
                    leaveAnimation={{
                        to: { transform: 'scale(0.85)', opacity: '0' },
                        from: { transform: 'scale(1)', opacity: '1' },
                    }}
                >
                    {this.props.items!.map((item) => this.renderNotification(item))}
                </FlipMove>
            </div>
        );
    }
}

export default Notifications;
