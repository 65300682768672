import * as OvationsApi from 'core/ovations-api';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import CustomAttributeValuesState from 'redux-modules/definitions/CustomAttributeValuesState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';

export const initialState: CustomAttributeValuesState = {
    map: {},
};

export const emptyProfileSettings: OvationsApi.Types.CustomAttributeValues = [];

const { reducer, update } = createReducer('customAttributeValues/UPDATE', initialState);
export const customAttributeValuesReducer = reducer;

export const actions = {
    fetch: (
        clientId: string,
        programId: string,
        customerAttributeId: number,
        isCustom = true,
    ): ThunkAction<Promise<string[]>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const customAttributeValues = await OvationsApi.Customer.fetchCustomAttributeValues(
                clientId,
                programId,
                customerAttributeId,
                isCustom,
            );

            dispatch(actions.upsert(clientId, programId, customAttributeValues));

            return customAttributeValues;
        };
    },

    upsert(
        clientId: string,
        programId: string,
        customAttributeValues: string[],
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.customAttributeValues.map, [programId]: customAttributeValues };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
