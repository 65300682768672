import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';

import ExtraArg from 'redux-modules/definitions/ExtraArg';
import ReportState from 'redux-modules/definitions/ReportState';
import S from 'redux-modules/definitions/RootState';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

export const initialState: ReportState = {
    map: {},
};

export const emptyReport: OvationsApi.Types.Report = {
    id: '',
    name: 'Report name',
    allowedRoleIds: [],
};

export const emptyReports: OvationsApi.Types.Report[] = [emptyReport];

const { reducer, update } = createReducer('reports/UPDATE', initialState);
export const reportReducer = reducer;

export const actions = {
    fetchAll: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const reports = await OvationsApi.Report.fetchAll(clientId, programId);
            if (reports == null) {
                return;
            }
            dispatch(actions.upsertReports(clientId, programId, reports));
        };
    },

    update(
        clientId: string,
        programId: string,
        reports: OvationsApi.Types.Report[],
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Report.update(clientId, programId, reports);
            dispatch(actions.upsertReports(clientId, programId, reports));
        };
    },

    upsertReport(
        clientId: string,
        programId: string,
        report: OvationsApi.Types.Report,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const reports = [...ctx.report.map[programId], report];
            dispatch(actions.upsertReports(clientId, programId, reports));
        };
    },

    upsertReports(
        clientId: string,
        programId: string,
        reports: OvationsApi.Types.Report[],
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.report.map, [programId]: reports };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
