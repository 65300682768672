import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import { ClientServicesCustomerDetailContainer } from 'asyncComponents/components';
import { CUSTOMER_DETAIL, PROGRAM_CUSTOMERS } from 'config/routes';
import CustomerListContainer from 'containers/customers/CustomerListContainer';

class CustomersContainer extends React.Component {
    render() {
        return (
            <Switch>
                <Route path={CUSTOMER_DETAIL} component={ClientServicesCustomerDetailContainer} />
                <Route path={PROGRAM_CUSTOMERS} component={CustomerListContainer} />
            </Switch>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(CustomersContainer);
