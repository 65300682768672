import { filter } from 'lodash';
import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup } from 'reactstrap';
import elements from 'config/elements';
import { ClientCustomerSearchResultsResponse, Program } from 'core/ovations-api/definitions';
import { ClientCustomerProgramSearchTypeahead } from './ClientCustomerProgramSearchTypeahead';
import { ProgramSearchTypeahead } from './ProgramSearchTypeahead';

export enum SearchType {
    Customers = 'Customers',
    Programs = 'Programs',
}

interface ProgramSearchBarProps {
    clientId: string;
    updateProgramResults: (programResults: Program[]) => void;
    updateClientCustomerResults: (clientCustomerResponse: ClientCustomerSearchResultsResponse) => void;
    onSelectedSearchTypeChange: (selectedSearchType: SearchType) => Promise<void>;
    page?: number;
}

export const ProgramSearchBar: React.FC<ProgramSearchBarProps> = (props) => {
    const [searchDropDownIsOpen, setSearchDropDownIsOpen] = useState(false);
    const [selectedSearchType, setSelectedSearchType] = useState(SearchType.Programs);

    const getSearchTypeChangeHandler = (searchType: SearchType) => {
        setSelectedSearchType(searchType);
        props.onSelectedSearchTypeChange(searchType);
    };

    const renderSearchTypeDropdown = () => {
        return (
            <Dropdown
                addonType="prepend"
                isOpen={searchDropDownIsOpen}
                toggle={() => setSearchDropDownIsOpen(!searchDropDownIsOpen)}
            >
                <DropdownToggle data-testid="selected-search-type" className="btn--soft" caret color="primary">
                    {selectedSearchType}
                </DropdownToggle>
                <DropdownMenu>
                    {filter(SearchType, (type) => type !== selectedSearchType).map((type) => (
                        <DropdownItem
                            data-testid="search-type-option"
                            key={type}
                            onClick={() => getSearchTypeChangeHandler(type)}
                        >
                            {type}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const renderProgramsSearch = () => {
        return (
            <InputGroup id={elements.globalHeader.id.searchBar}>
                {renderSearchTypeDropdown()}
                <ProgramSearchTypeahead
                    clientId={props.clientId}
                    updateProgramResults={props.updateProgramResults}
                    page={props.page}
                />
            </InputGroup>
        );
    };

    const renderClientCustomersSearch = () => {
        return (
            <InputGroup id={elements.globalHeader.id.searchBar}>
                {renderSearchTypeDropdown()}
                <ClientCustomerProgramSearchTypeahead
                    clientId={props.clientId}
                    updateClientCustomerResults={props.updateClientCustomerResults}
                    page={props.page}
                />
            </InputGroup>
        );
    };

    const renderSearchBar = () => {
        switch (selectedSearchType) {
            case SearchType.Programs:
                return renderProgramsSearch();
            case SearchType.Customers:
                return renderClientCustomersSearch();
        }
    };

    return <div>{renderSearchBar()}</div>;
};
