/* eslint-disable @typescript-eslint/no-explicit-any */
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, StoreEnhancer, Store, AnyAction } from 'redux';
import persistState, { mergePersistedState } from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import thunk from 'redux-thunk';

import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { clientContextManager, createRootReducer } from 'redux-modules/root';
import { Reducer } from 'react';

export default (history: History): Store<S> => {
    const reducer = mergePersistedState()(createRootReducer(history));
    const storage = filter(['settings'])(adapter(window.localStorage));
    let composeEnhancers = compose;
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development') {
        // https://github.com/zalmoxisus/redux-devtools-extension
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    const extraArg: ExtraArg = { clientContextManager };
    const enhancer = composeEnhancers(
        applyMiddleware(routerMiddleware(history), thunk.withExtraArgument(extraArg)),
        persistState(storage, 'ovations-platform'),
    );
    return createStore<S, AnyAction, unknown, AnyAction>(reducer as Reducer<S, AnyAction>, enhancer as StoreEnhancer);
};
