import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import * as routes from 'config/routes';
import ClientSettingsContainer from 'containers/clients/ClientSettingsContainer';
import ProgramsContainer from 'containers/programs/ProgramsContainer';
import toPath from 'core/lib/toPath';

export class ClientsContainer extends React.Component {
    render() {
        return (
            <Route path={routes.CLIENT_DETAIL}>
                <Switch>
                    <Route path={routes.CLIENT_PROGRAMS} component={ProgramsContainer} />
                    <Route path={routes.CLIENT_SETTINGS} component={ClientSettingsContainer} />
                    <Route
                        exact
                        path={routes.CLIENT_DETAIL}
                        render={(props) => <Redirect to={toPath(routes.CLIENT_PROGRAMS, props.match.params)} />}
                    />
                </Switch>
            </Route>
        );
    }
}

export default connect((state) => state)(ClientsContainer);
