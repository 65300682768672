/* eslint-disable @typescript-eslint/no-explicit-any */
import * as signalr from '@aspnet/signalr';

// Hubs docs: https://appgroupodev01ovations.azurewebsites.net/hubs/docs

export interface HubListener<T> {
    (eventData: T): void;
}
interface Unlistener {
    (): void;
}

export interface HubConfiguration {
    // tslint:disable-next-line no-any
    request: <S = undefined, F = undefined>(eventName: string, ...args: any[]) => Promise<HubResponse<S, F>>;
    on: <T>(eventName: string, listener: HubListener<T>) => Promise<Unlistener>;
}

interface HubResponseBase<T> {
    success: boolean;
    statusCode: number;
    result: T;
}

interface FailureResponse<T> extends HubResponseBase<T> {
    success: false;
}

interface SuccessResponse<T> extends HubResponseBase<T> {
    success: true;
}

export type HubResponse<S, F> = SuccessResponse<S> | FailureResponse<F>;

const configureHub = (hubUrl: string): HubConfiguration => {
    let _connection: signalr.HubConnection | null = null;
    let _startHubPromise: Promise<void> | null = null;
    const _getConnection = async (): Promise<signalr.HubConnection> => {
        if (_connection) {
            await _startHubPromise;
            return _connection;
        }
        const connectionBuilder = new signalr.HubConnectionBuilder().withUrl(hubUrl);
        _connection = connectionBuilder.build();
        _startHubPromise = _connection.start();
        await _startHubPromise;
        return _connection;
    };

    // tslint:disable-next-line no-any
    const request = async <S, F>(eventName: string, ...args: any[]): Promise<HubResponse<S, F>> => {
        const connection = await _getConnection();
        const hubResponse: HubResponse<S, F> = await connection.invoke(eventName, ...args);
        return hubResponse;
    };

    const on = async <T>(eventName: string, listener: HubListener<T>) => {
        const connection = await _getConnection();
        connection.on(eventName, listener);
        const unlistener = () => connection.off(eventName, listener);
        return unlistener;
    };

    return { request, on };
};

export default configureHub;
