import { faCircleNotch, faSearch } from '@fortawesome/free-solid-svg-icons';
import TypeaheadComponent from 'definitions/TypeaheadComponent';
import { filter } from 'lodash';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupText } from 'reactstrap';

import CustomerTypeahead from 'components/customer/CustomerTypeahead';
import elements from 'config/elements';
import { noResults, searchHelpPromptForClaim } from 'config/messages';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';

export enum SearchType {
    Customer = 'Customers',
    Claim = 'Claims',
    Addresses = 'Addresses',
}

interface PrimarySearchProps {
    clientId: string;
    programId: string;
    onCustomerSelect: (customer: OvationsApi.Types.Customer) => void;
    onClaimSelect?: (claimDetail: OvationsApi.Types.ClaimDetail) => void;
}

interface PrimarySearchState {
    searchingType: SearchType | undefined;
    searchType: SearchType;
    query: string;
    lastSearchedQuery: string;
    searchTypeDropdownOpen: boolean;
    resultClaimNumber: number | undefined;
}

class PrimarySearch extends React.Component<PrimarySearchProps, PrimarySearchState> {
    typeahead: TypeaheadComponent | null;

    constructor(props: PrimarySearchProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState(): PrimarySearchState {
        return {
            searchingType: undefined,
            searchType: SearchType.Customer,
            query: '',
            searchTypeDropdownOpen: false,
            resultClaimNumber: undefined,
            lastSearchedQuery: '',
        };
    }

    onClaimSearch = () => {
        const { query } = this.state;
        const searchQuery = Number(query);
        if (isNaN(searchQuery) || !searchQuery) {
            this.focusSearchInput();
            return;
        }
        this.setState({ searchingType: this.state.searchType, query });
        this.searchClaims(searchQuery);
    };

    onClaimSearchKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            this.onClaimSearch();
        }
    };

    getSearchTypeChangeHandler = (searchType: SearchType) => () => {
        this.setState({ searchType, query: '', searchingType: undefined, resultClaimNumber: undefined });
    };

    getClaimEmptyLabel(): string {
        const { state } = this;
        if (state.searchingType === SearchType.Claim) {
            return '';
        }
        if (state.resultClaimNumber && state.resultClaimNumber.toString() === state.query) {
            return '';
        }
        if (!state.resultClaimNumber && state.lastSearchedQuery && state.query === state.lastSearchedQuery) {
            return noResults();
        }
        return searchHelpPromptForClaim();
    }

    onClaimSearchInputChange = (query: string) => {
        this.setState({ query, resultClaimNumber: undefined });
    };

    toggleSearchTypeDropDown = () => {
        this.setState({
            searchTypeDropdownOpen: !this.state.searchTypeDropdownOpen,
        });
    };

    async searchClaims(query: number) {
        const { clientId, programId } = this.props;
        const claimSearchRequest: OvationsApi.Types.ClaimsSearchRequest = {
            claimNumber: query,
        };
        const claimsResult = await OvationsApi.Claim.search(clientId, programId, claimSearchRequest);
        if (claimsResult && claimsResult.results.length > 0 && this.props.onClaimSelect) {
            const claim = claimsResult.results[0];
            this.setState({
                searchingType: undefined,
                resultClaimNumber: claim.number,
                lastSearchedQuery: query.toString(),
            });
            return this.props.onClaimSelect(claim);
        }
        this.setState({
            searchingType: undefined,
            resultClaimNumber: undefined,
            lastSearchedQuery: query.toString(),
        });
        this.focusSearchInput();
    }

    focusSearchInput() {
        if (this.typeahead) {
            this.typeahead.getInstance().focus();
        }
    }

    renderSearchTypeDropdown() {
        const { state } = this;
        return (
            <Dropdown
                addonType="prepend"
                isOpen={this.state.searchTypeDropdownOpen}
                toggle={this.toggleSearchTypeDropDown}
            >
                <DropdownToggle className="btn--soft" caret color="primary">
                    {state.searchType}
                </DropdownToggle>
                <DropdownMenu>
                    {filter(SearchType, (searchType) => searchType !== state.searchType).map((searchType) => (
                        <DropdownItem key={searchType} onClick={this.getSearchTypeChangeHandler(searchType)}>
                            {searchType}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }

    renderAddressSearch() {
        return (
            <InputGroup id={elements.globalHeader.id.searchBar}>
                {this.renderSearchTypeDropdown()}
                <CustomerTypeahead
                    addressMode
                    clientId={this.props.clientId}
                    programId={this.props.programId}
                    placeholder="Search by address"
                    onSelect={this.props.onCustomerSelect}
                    inputProps={{ id: elements.globalHeader.id.searchInput }}
                />
            </InputGroup>
        );
    }

    renderCustomerSearch() {
        return (
            <InputGroup id={elements.globalHeader.id.searchBar}>
                {this.renderSearchTypeDropdown()}
                <CustomerTypeahead
                    clientId={this.props.clientId}
                    programId={this.props.programId}
                    placeholder="Search by name or email"
                    onSelect={this.props.onCustomerSelect}
                    inputProps={{ id: elements.globalHeader.id.searchInput }}
                />
            </InputGroup>
        );
    }

    renderClaimsSearch() {
        return (
            <InputGroup id={elements.globalHeader.id.searchBar}>
                {this.renderSearchTypeDropdown()}
                <Typeahead
                    ref={(ref: unknown) => {
                        // eslint-disable-line @typescript-eslint/no-explicit-any
                        this.typeahead = ref as TypeaheadComponent;
                    }}
                    key={`claims-${this.props.programId}`} // Forces re-mounting when program/type changes
                    id={`claims-${this.props.programId}`}
                    inputProps={{ id: elements.globalHeader.id.searchInput }}
                    placeholder="Search by claim #"
                    emptyLabel={this.getClaimEmptyLabel()}
                    options={[]}
                    onInputChange={this.onClaimSearchInputChange}
                    onKeyDown={this.onClaimSearchKeyDown}
                />
                {this.state.searchingType ? (
                    <InputGroupText>
                        <FAIcon icon={faCircleNotch} className="spin" />
                    </InputGroupText>
                ) : (
                    <button
                        id={elements.globalHeader.id.searchButton}
                        className="btn btn-outline-light"
                        type="button"
                        onClick={this.onClaimSearch}
                    >
                        <FAIcon icon={faSearch} className="text-secondary" />
                    </button>
                )}
            </InputGroup>
        );
    }

    renderSearchBar() {
        switch (this.state.searchType) {
            case SearchType.Addresses:
                return this.renderAddressSearch();
            case SearchType.Customer:
                return this.renderCustomerSearch();
            case SearchType.Claim:
                return this.renderClaimsSearch();
        }
    }

    render() {
        return (
            <div className="flex-grow d-flex align-items-center ms-2">
                <div className="me-1">
                    <label className="m-0" htmlFor={elements.globalHeader.id.searchInput}>
                        Search:
                    </label>
                </div>
                {this.renderSearchBar()}
            </div>
        );
    }
}

export default PrimarySearch;
