import { keyBy, uniqBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';
import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ClaimDetail from 'core/ovations-api/definitions/claims/ClaimDetail';
import ClaimsSearchRequest from 'core/ovations-api/definitions/claims/ClaimsSearchRequest';
import ClaimStatus from 'core/ovations-api/enums/ClaimStatus';
import ReconciliationStatus from 'core/ovations-api/enums/ReconciliationStatus';
import { dateTimeCurrent } from 'core/util/datetime';
import { monetize } from 'core/util/strings';
import { emptyCustomer } from 'redux-modules/customer';
import ClaimState from 'redux-modules/definitions/ClaimState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { emptyPromotion } from 'redux-modules/promotion';

interface RewardDeterminants {
    claimDetail: OvationsApi.Types.ClaimDetail;
    rewardCalculation: OvationsApi.Types.RewardCalculation | null;
}

export const initialState: ClaimState = {
    duplicateFlags: {},
    map: {},
    totalResults: 0,
};

const { reducer, update } = createReducer('claim/UPDATE', initialState);
export const claimReducer = reducer;

export const emptyClaim: OvationsApi.Types.Claim = {
    id: '',
    number: 0,
    submissionDate: dateTimeCurrent(),
    isMailIn: false,
    status: ClaimStatus.Disqualified,
    reasonId: null,
    systemReason: null,
    customerId: '',
    promotionId: '',
    answers: {},
    notes: '',
    rewards: null,
    isForPrefund: false,
    reconciliationSubmissionDate: null,
    reconciliationStatus: ReconciliationStatus.PendingSubmission,
    alternatePayee: null,
    hasEsignAgreementAcknowledgement: false,
};

export const emptyClaimDetail: OvationsApi.Types.ClaimDetail = {
    id: '',
    number: 0,
    submissionDate: '',
    createDate: '',
    updateDate: '',
    status: ClaimStatus.PendingValidation,
    reason: null,
    systemReason: null,
    batchExclusions: [],
    customer: emptyCustomer,
    promotion: emptyPromotion,
    createdBy: null,
    changedBy: null,
    answers: {},
    rewardAmountTotal: 0,
    rewardPointTotal: 0,
    rewards: null,
    isForPrefund: false,
    isPartialReward: false,
    reconciliationStatus: ReconciliationStatus.PendingSubmission,
    reconciliationSubmissionDate: '',
    alternatePayee: null,
    hasEsignAgreementAcknowledgement: false,
};

export const actions = {
    update,

    fetchAll(clientId: string, programId: string, customerId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const claimDetails = await OvationsApi.Claim.fetchAll(clientId, programId, customerId);
            if (claimDetails) {
                const map = keyBy(claimDetails, 'id');
                dispatch(clientContextManager.action(clientId, update({ map, totalResults: claimDetails.length })));
            }
        };
    },

    fetchSingle(
        clientId: string,
        programId: string,
        claimId: string,
    ): ThunkAction<Promise<OvationsApi.Types.ClaimDetail>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const claimDetail = await OvationsApi.Claim.fetchSingle(clientId, programId, claimId);
            dispatch(actions.upsertClaimDetail(clientId, claimDetail));
            return claimDetail;
        };
    },

    fetchDuplicateFlags(
        clientId: string,
        programId: string,
        claimId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const duplicateFlags = await OvationsApi.Claim.fetchDuplicateFlags(clientId, programId, claimId);
            const updatedDuplicateFlags = { ...ctx.claim.duplicateFlags, [claimId]: duplicateFlags };
            dispatch(clientContextManager.action(clientId, update({ duplicateFlags: updatedDuplicateFlags })));
        };
    },

    updateDuplicateFlags(
        clientId: string,
        programId: string,
        claimId: string,
        duplicateStatus: OvationsApi.Types.DuplicateStatusUpdateRequest,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Claim.updateDuplicateFlags(clientId, programId, claimId, duplicateStatus);
            await dispatch(actions.fetchDuplicateFlags(clientId, programId, claimId));
        };
    },

    search(
        clientId: string,
        programId: string,
        request: Partial<ClaimsSearchRequest>,
        batchId?: string,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.ClaimDetail>>,
        S,
        ExtraArg,
        Action
    > {
        return async (dispatch, getState, { clientContextManager }) => {
            const response = await OvationsApi.Claim.search(clientId, programId, request, batchId);
            const map = keyBy(response.results, 'id');
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: response.totalResults })));
            return response;
        };
    },

    updateClaim(
        clientId: string,
        programId: string,
        claim: OvationsApi.Types.Claim,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Claim.update(clientId, programId, claim);
            // While it's technically possible to derive a ClaimDetail from its constituent data, it's easier and
            // less error-prone to just re-fetch it.
            await dispatch(actions.fetchSingle(clientId, programId, claim.id));
        };
    },

    processRedemptionClaim(
        clientId: string,
        programId: string,
        claim: OvationsApi.Types.Claim,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Claim.processRedemption(clientId, programId, claim);
            // While it's technically possible to derive a ClaimDetail from its constituent data, it's easier and
            // less error-prone to just re-fetch it.
            await dispatch(actions.fetchSingle(clientId, programId, claim.id));
        };
    },

    upsertClaimDetail(
        clientId: string,
        claimDetail: OvationsApi.Types.ClaimDetail,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.claim.map, [claimDetail.id]: claimDetail };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    sendReminderEmail(clientId: string, programId: string, claimId: string): ThunkAction<void, S, ExtraArg, Action> {
        return async () => {
            const result = await OvationsApi.Claim.sendReminderEmail(clientId, programId, claimId);
            return result;
        };
    },
};

const getList = createSelector([(state) => state.map], (map) => values(map));

export const selectors = {
    getList,

    toClaim: (claimDetail: OvationsApi.Types.ClaimDetail): OvationsApi.Types.Claim => {
        const claim: OvationsApi.Types.Claim = {
            ...emptyClaim,
            id: claimDetail.id,
            number: claimDetail.number,
            submissionDate: claimDetail.submissionDate,
            status: claimDetail.status,
            reasonId: claimDetail.reason ? claimDetail.reason.id : null,
            systemReason: claimDetail.systemReason,
            customerId: claimDetail.customer.id,
            promotionId: claimDetail.promotion.id,
            answers: claimDetail.answers,
            isForPrefund: claimDetail.isForPrefund,
            reconciliationStatus: claimDetail.reconciliationStatus,
            reconciliationSubmissionDate: claimDetail.reconciliationSubmissionDate,
            alternatePayee: claimDetail.alternatePayee,
            hasEsignAgreementAcknowledgement: claimDetail.hasEsignAgreementAcknowledgement,
            rewards: claimDetail.rewards,
        };

        return claim;
    },

    getRewardAmount: createSelector(
        (rewardDeterminants: RewardDeterminants) => rewardDeterminants.claimDetail,
        (rewardDeterminants: RewardDeterminants) => rewardDeterminants.rewardCalculation,
        (claimDetail, reward): number | null => {
            // tslint:disable-line cyclomatic-complexity
            if (!reward || !OvationsApi.Claim.isStatusSameOrAfter(claimDetail.status, ClaimStatus.Validated)) {
                return null;
            }
            switch (reward.type) {
                case OvationsApi.Enums.RewardCalculationType.Static: {
                    const staticReward = reward as OvationsApi.Types.StaticRewardCalculation;
                    return staticReward.amount;
                }
                case OvationsApi.Enums.RewardCalculationType.Variable: {
                    const questionId = reward.dropdownQuestion ? reward.dropdownQuestion.id : '';
                    const answer = claimDetail.answers[questionId] as OvationsApi.Types.PromotionDropDownQuestionAnswer;
                    return answer ? reward.values[answer.value] : null;
                }
                case OvationsApi.Enums.RewardCalculationType.Percentage: {
                    let sum = 0;
                    uniqBy(reward.promotionQuestionRewardCalculations, (q) => {
                        return q.promotionQuestionId;
                    }).forEach((question) => {
                        const answer = claimDetail.answers[
                            question.promotionQuestionId
                        ] as OvationsApi.Types.PromotionNumberQuestionAnswer;
                        sum = answer && answer.value ? (sum += answer.value) : 0;
                    });
                    return sum === 0 || !reward.percentage ? null : sum * reward.percentage;
                }
                case OvationsApi.Enums.RewardCalculationType.UserDefined: {
                    const questionId = reward.numberQuestion ? reward.numberQuestion.id : '';
                    const answer = claimDetail.answers[questionId] as OvationsApi.Types.PromotionNumberQuestionAnswer;
                    return answer ? answer.value : null;
                }
                default:
                    // TODO account for StaticPoints in future
                    return null;
            }
        },
    ),

    getValidatedRewardDisplayValue: createSelector(
        (claim: OvationsApi.Types.ClaimDetail | OvationsApi.Types.ClaimListItem) => claim,
        (claim: OvationsApi.Types.ClaimDetail | OvationsApi.Types.ClaimListItem) => {
            // eslint-disable-next-line no-nested-ternary
            let displayValue = '';
            if (claim.rewardAmountTotal || claim.rewardPointTotal) {
                if (claim.rewardAmountTotal) {
                    displayValue += monetize(claim.rewardAmountTotal);
                }
                if (claim.rewardPointTotal) {
                    if (displayValue !== '') {
                        displayValue += ' | ';
                    }
                    displayValue += `${Math.round(claim.rewardPointTotal)} Points`;
                }
            }
            return displayValue;
        },
    ),

    hasIncompleteChoiceSelection: createSelector(
        (claimDetail: ClaimDetail) => claimDetail,
        (claimDetail): boolean => {
            let completedReward = null;
            let hasIncompleteChoices = false;
            const choiceRewardCalcs = claimDetail.promotion.rewardCalculations?.filter(
                (rc) => rc.type === OvationsApi.Enums.RewardCalculationType.Choice,
            );

            if (claimDetail.rewards && choiceRewardCalcs && choiceRewardCalcs.length > 0) {
                // look through each parent choice calc
                choiceRewardCalcs.find((calc: OvationsApi.Types.ChoiceRewardCalculation) => {
                    // check that at least one choice is selected
                    completedReward = calc.choices.find((choice) =>
                        // check that there is a reward matching the selected choice
                        claimDetail.rewards?.some((r) => r.rewardCalculation.id === choice.id),
                    );
                    // it found an incomplete choice, so return and stop looking
                    if (!completedReward) return true;
                    // did not find any incomplete choices, so keep looking
                    return false;
                });
                if (!completedReward) hasIncompleteChoices = true;
            }
            return hasIncompleteChoices;
        },
    ),
};
