import { goBack, push, RouterAction, RouterState } from 'connected-react-router';
import { History } from 'history';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';

import * as routes from 'config/routes';
import toPath from 'core/lib/toPath';

export const actions = {
    goBackWithFallback: (path: string, history: History): RouterAction => {
        const canGoBack = history.length > 2;
        if (canGoBack) {
            return goBack();
        }
        return push(path);
    },
};

const getNormalizedPathname = (state: RouterState) => {
    if (!state.location) {
        return '';
    }
    return state.location.pathname.replace(/^\/call/, '');
};

export const selectors = {
    getClientId: createSelector(getNormalizedPathname, (pathname) => {
        const match = matchPath<{ clientId: string }>(pathname, { path: routes.CLIENT_DETAIL });
        if (match) {
            return match.params.clientId;
        }
    }),

    getProgramId: createSelector(getNormalizedPathname, (pathname) => {
        const isNewProgram = matchPath(pathname, { path: routes.CLIENT_PROGRAM_CREATE });
        if (isNewProgram) {
            return;
        }
        const match = matchPath<{ programId: string }>(pathname, { path: routes.CLIENT_PROGRAM_DETAIL });
        if (match) {
            return match.params.programId;
        }
    }),

    isPortal: createSelector(getNormalizedPathname, (pathname) => {
        const match = matchPath(pathname, { path: routes.PORTAL_SETTINGS });
        return match !== null;
    }),

    getCustomerDetailPath: (
        state: RouterState,
        customerId: string,
        inCall?: boolean,
        programId?: string,
    ): string | undefined => {
        const clientId = selectors.getClientId(state);
        const programIdToUse = programId || selectors.getProgramId(state);
        if (!clientId || !programIdToUse) {
            return;
        }
        const path = inCall
            ? toPath(routes.IN_CALL, { clientId, programId: programIdToUse, customerId })
            : toPath(routes.CUSTOMER_DETAIL, { clientId, programId: programIdToUse, customerId });

        return path;
    },
};
