/* eslint-disable */
// This is a .js file because https://github.com/wmonk/create-react-app-typescript/issues/359
import createAsyncComponent from 'asyncComponents/createAsyncComponent';

// App
export const InCallContainer = createAsyncComponent(() =>
    import('containers/InCallContainer' /* webpackChunkName: "InCall" */),
);

// Programs
export const ProgramDetailContainer = createAsyncComponent(() =>
    import('containers/programs/ProgramDetailContainer' /* webpackChunkName: "ProgramDetail" */),
);

// Portal
export const PortalContainer = createAsyncComponent(() =>
    import('containers/portal/PortalContainer' /* webpackChunkName: "Portal" */),
);

// Batches/Appeasements
export const BatchContainer = createAsyncComponent(() =>
    import('containers/batches/BatchContainer' /* webpackChunkName: "Batches" */),
);

// Promotions
export const PromotionsContainer = createAsyncComponent(() =>
    import('containers/promotions/PromotionsContainer' /* webpackChunkName: "Promotions" */),
);

// Claims
export const ClaimsContainer = createAsyncComponent(() =>
    import('containers/claims/ClaimsContainer' /* webpackChunkName: "Claims" */),
);

// Customers
export const ClientServicesCustomerDetailContainer = createAsyncComponent(() =>
    import('containers/customers/ClientServicesCustomerDetailContainer' /* webpackChunkName: "CSCustomerDetail" */),
);

// Patterns
export const PatternsContainer = createAsyncComponent(() =>
    import('patterns/PatternsContainer' /* webpackChunkName: "Patterns" */),
);

// Sales Incentives
export const SalesIncentivesContainer = createAsyncComponent(() =>
    import('containers/sales-incentives/SalesIncentivesContainer' /* webpackChunkName: "SalesIncentives" */),
);
