import React from 'react';

import { ContainerProps } from 'containers/Container';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import ClaimDocumentViewer from 'components/claim/ClaimDocumentViewer';
import * as OvationsApi from 'core/ovations-api';
import withData from 'lib/withData';
import { claimLoader } from 'loaders/claimLoaders';

export type PotentialDuplicateContainerProps = ContainerProps<{ clientId: string; claimId: string }>;
interface PotentialDuplicateContainerState {
    selectedFileQuestionId?: string;
}

export class PotentialDuplicateContainer extends React.Component<
    PotentialDuplicateContainerProps,
    PotentialDuplicateContainerState
> {
    static getFileQuestions = createSelector(
        (promoQuestions: OvationsApi.Types.PromotionQuestion[]) => promoQuestions,
        (promoQuestions) => {
            const fileUploadQuestions = promoQuestions.filter((promoQuestion) => {
                return promoQuestion.question.type === OvationsApi.Enums.QuestionType.FileUpload;
            });
            return fileUploadQuestions as OvationsApi.Types.PromotionFileUploadQuestion[];
        },
    );

    constructor(props: PotentialDuplicateContainerProps) {
        super(props);
        this.state = {
            selectedFileQuestionId: '',
        };
    }

    componentDidUpdate(prevProps: PotentialDuplicateContainerProps) {
        const { clientId } = this.props.match.params;
        const ctx = this.props.clientContexts[clientId];
        const prevCtx = prevProps.clientContexts[clientId];

        if (ctx === prevCtx) {
            return;
        }

        this.setState({ selectedFileQuestionId: this.getSelectedFileQuestionId(this.props) });
    }

    getSelectedFileQuestionId(props: PotentialDuplicateContainerProps): string | undefined {
        const { claimId, clientId } = props.match.params;
        const ctx = this.props.clientContexts[clientId];
        const { questions } = ctx.claim.map[claimId].promotion;
        const fileQuestions = PotentialDuplicateContainer.getFileQuestions(questions);
        if (fileQuestions[0]) {
            return fileQuestions[0].id;
        }
    }

    onSelectedQuestionChange = (selectedFileQuestionId: string) => {
        this.setState({ selectedFileQuestionId });
    };

    render() {
        const { clientId, claimId } = this.props.match.params;
        const ctx = this.props.clientContexts[clientId];
        const claim = ctx.claim.map[claimId];
        const { questions } = claim.promotion;
        const fileUploadQuestions = PotentialDuplicateContainer.getFileQuestions(questions);
        const selectedFileQuestionId = this.state.selectedFileQuestionId ? this.state.selectedFileQuestionId : '';
        const answer = claim.answers[selectedFileQuestionId];
        return (
            <div className="p-3">
                <ClaimDocumentViewer
                    answer={answer as OvationsApi.Types.PromotionFileUploadQuestionAnswer}
                    clientId={clientId}
                    questions={fileUploadQuestions}
                    selectedFileQuestionId={this.state.selectedFileQuestionId ? this.state.selectedFileQuestionId : ''}
                    onSelectedQuestionChange={this.onSelectedQuestionChange}
                />
            </div>
        );
    }
}

const ContainerWithData = withData<ContainerProps>(claimLoader)(PotentialDuplicateContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ContainerWithData);
