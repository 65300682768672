import { debounce } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import CallHub from 'ovations-hubs/CallHub';
import UpdateCallRequest from 'ovations-hubs/definitions/UpdateCallRequest';
import { emptyCustomer } from 'redux-modules/customer';
import CallState from 'redux-modules/definitions/CallState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { emptyProfile } from 'redux-modules/profile';

export const emptyCallState: OvationsApi.Types.CallState = {
    id: 0,
    clientId: '',
    programId: '',
    platformUser: emptyProfile,
    customer: emptyCustomer,
    callType: null,
    callDisposition: null,
    promotion: null,
    claim: null,
    startTime: '',
    endTime: null,
    notes: null,
    isCall: false,
};

export const initialState: CallState = {
    activeCallState: null,
    updates: null,
};

const { reducer, update } = createReducer('call/UPDATE', initialState);
export const callReducer = reducer;

const sendUpdatesToHub = debounce(
    (clientId: string, updates: UpdateCallRequest) => CallHub.updateCall(clientId, updates),
    200,
);

const getUpdateRequestFromCallState = (callState: OvationsApi.Types.CallState): UpdateCallRequest => {
    const getId = <T>(entity: { id: T } | null): T | undefined => {
        return entity ? entity.id : undefined;
    };
    const updateRequest: UpdateCallRequest = {
        id: callState.id,
        callTypeId: getId<number>(callState.callType),
        callDispositionId: getId<number>(callState.callDisposition),
        promotionId: getId<string>(callState.promotion),
        claimId: getId<string>(callState.claim),
        notes: callState.notes || '',
    };
    return updateRequest;
};

export const actions = {
    update,

    retrieve(): ThunkAction<Promise<OvationsApi.Types.CallState | undefined>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const activeCallState = await OvationsApi.Call.retrieve();
            if (activeCallState) {
                const updates = getUpdateRequestFromCallState(activeCallState);
                dispatch(update({ activeCallState, updates }));
            }
            return activeCallState;
        };
    },

    startOrRetrieveCall(
        clientId: string,
        programId: string,
        customerId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const response = await CallHub.beginCall(clientId, programId, customerId);
            if (!response.success) {
                dispatch(actions.retrieve());
                return;
            }
            const id = response.result;
            const updates = { id };
            const activeCallState: OvationsApi.Types.CallState = {
                ...emptyCallState,
                id,
                clientId,
                programId,
                customer: { ...emptyCallState.customer, id: customerId },
                startTime: new Date().toISOString(),
            };
            dispatch(update({ updates, activeCallState }));
        };
    },

    updateCall(clientId: string, updates: UpdateCallRequest): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            sendUpdatesToHub(clientId, updates);
            dispatch(update({ updates }));
        };
    },

    endCall(): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState) => {
            const { call } = getState();
            if (!call.activeCallState || !call.updates) {
                return;
            }
            dispatch(update({ activeCallState: null, updates: null }));
            await CallHub.endCall(call.activeCallState.clientId, call.updates);
        };
    },
};

export const selectors = {
    getUpdateRequestFromCallState,
};
