import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalNotificationState from 'redux-modules/definitions/PortalNotificationState';
import S from 'redux-modules/definitions/RootState';
import { EmailScheduleType } from 'core/enums/EmailNotificationScheduler';

export const initialState: PortalNotificationState = {
    map: {},
    tokensMap: {},
};

const { reducer, update } = createReducer('portalNotification/UPDATE', initialState);
export const portalNotificationReducer = reducer;

export const emptyPortalNotificationContent = {
    subject: '',
    emailContent: '',
    smsContent: '',
    isActive: true,
    oneTimeReminderDays: null,
    recurrenceFrequency: null,
    recurringStopAfterDays: null,
    emailScheduleType: EmailScheduleType.Never,
    stopAfterEnabled: false,
};

export const emptyPortalNotification = {
    programId: null,
    registration: emptyPortalNotificationContent,
    mailInRegistration: emptyPortalNotificationContent,
    claimSubmission: emptyPortalNotificationContent,
    passwordReset: emptyPortalNotificationContent,
    passwordChanged: emptyPortalNotificationContent,
};

export const emptyPortalNotificationTokens: OvationsApi.Types.PortalNotificationTokens = {
    passwordChanged: {},
    passwordReset: {},
    registration: {},
    mailInRegistration: {},
    claimSubmission: {},
    claimIncomplete: {},
    claimAdditionalIncomplete: {},
    claimDisqualification: {},
    referredIndividualConfirmation: {},
    claimFulfilled: {},
    customerUpdated: {},
    readyToRedeem: {},
};

export const actions = {
    fetch: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const portalNotification = await OvationsApi.PortalNotification.fetch(clientId, programId);
            if (!portalNotification) {
                return;
            }
            dispatch(actions.upsertPortalNotification(clientId, portalNotification));
        };
    },

    updatePortalNotification(
        clientId: string,
        portalNotification: OvationsApi.Types.PortalNotification,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.PortalNotification.update(clientId, portalNotification);
            dispatch(actions.upsertPortalNotification(clientId, portalNotification));
        };
    },

    upsertPortalNotification(
        clientId: string,
        portalNotification: OvationsApi.Types.PortalNotification,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.portalNotification.map, [portalNotification.programId]: portalNotification };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    fetchTokens: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const tokens = await OvationsApi.PortalNotification.fetchTokens(clientId, programId);
            if (!tokens) {
                return;
            }
            dispatch(actions.upsertPortalNotificationToken(clientId, programId, tokens));
        };
    },

    upsertPortalNotificationToken(
        clientId: string,
        programId: string,
        tokens: OvationsApi.Types.PortalNotificationTokens,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const tokensMap = { ...ctx.portalNotification.tokensMap, [programId]: tokens };
            return dispatch(clientContextManager.action(clientId, update({ tokensMap })));
        };
    },

    sendNotificaionPreview(
        clientId: string,
        portalPreview: OvationsApi.Types.PortalNotificationPreview,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async () => {
            await OvationsApi.PortalNotification.sendPreview(clientId, portalPreview);
        };
    },
};
