import { omit } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';

import Forbidden from 'components/Forbidden';
import S from 'redux-modules/definitions/RootState';
import { selectors } from 'redux-modules/root';

export interface PrivateRouteProps extends RouteProps {
    rootState: S;
    permissions?: string[];
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { rootState, permissions, ...routeProps } = props;
    if (!rootState.profile.me) {
        const { pathname = '', search = '', hash = '' } = routeProps.location || {};
        const redirect = `${pathname}${search}${hash}`;
        return <Redirect to={`/login?redirect=${encodeURIComponent(redirect)}`} />;
    }
    if (permissions && !selectors.userHasAccess(rootState, permissions)) {
        return <Route {...omit(routeProps, 'render')} component={Forbidden} />;
    }
    return <Route {...routeProps} />;
};

/* istanbul ignore next */
export default connect((rootState) => ({ rootState }))(PrivateRoute);
