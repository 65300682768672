/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { throttle } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Col,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';

import { IGNORE_ROW_CLICK, UncontrolledDataTable } from 'components/DataTable';
import MiniPaginate from 'components/MiniPaginate';
import elements from 'config/elements';
import { DATE_FORMAT } from 'config/global';
import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import ModalType from 'enums/ModalType';
import * as layout from 'redux-modules/layout';
import CreateButton from 'core/ovations-components/CreateButton';

interface UserSearchState {
    includeArchived: boolean;
    includeActive: boolean;
    page: number;
    perPage: number;
    searchTerm: string;
    total: number;
}

type UserDataTable = UncontrolledDataTable<OvationsApi.Types.PlatformUserProfile>;
const UserDataTable = UncontrolledDataTable as any as new () => UserDataTable;

export class UserSearchContainer extends React.Component<ContainerProps, UserSearchState> {
    dataTable: null | UserDataTable = null;

    respondToSearchInputChange = throttle(
        () => {
            if (this.dataTable) {
                this.dataTable.search(1);
            }
        },
        250,
        { leading: false },
    );

    constructor(props: ContainerProps) {
        super(props);

        this.state = {
            includeArchived: false,
            includeActive: true,
            page: 1,
            perPage: UncontrolledDataTable.defaultProps.perPage,
            searchTerm: '',
            total: 0,
        };
    }

    onArchiveStatusChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        // name will only ever be a property in state
        // tslint:disable-next-line no-any
        this.setState({ [e.currentTarget.name]: e.currentTarget.checked } as any, () => {
            if (this.dataTable) {
                this.dataTable.search(1);
            }
        });
    };

    onMiniPageClick = (page: number) => {
        if (this.dataTable) {
            this.dataTable.search(page);
        }
    };

    onNewUserClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.NewUser));
    };

    onSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const searchTerm = e.currentTarget.value;
        const shouldSearch = searchTerm.trim() !== this.state.searchTerm.trim();
        let respondToSearchInputChange;
        if (shouldSearch) {
            respondToSearchInputChange = this.respondToSearchInputChange;
        }
        this.setState({ searchTerm }, respondToSearchInputChange);
    };

    onRowClick = (row: OvationsApi.Types.PlatformUserProfile) => {
        this.props.dispatch(push(toPath(routes.USER_DETAIL, { userId: row.id })));
    };

    onSearch = async (page: number, perPage: number, sortKey?: string, sortDescending?: boolean) => {
        const { state } = this;
        const result = await OvationsApi.PlatformUserList.search({
            page,
            query: state.searchTerm,
            includeActive: state.includeActive || undefined,
            includeArchived: state.includeArchived || undefined,
            pageSize: perPage,
            sortIndex: sortKey,
            sortDescending,
        });

        this.setState({
            page,
            perPage,
            total: result.totalResults,
        });

        return { total: result.totalResults, rows: result.results };
    };

    renderUser() {
        return (
            <div className="wrap mt-4">
                <div className="d-flex justify-content-between align-content-center">
                    <div>
                        <h1 className="h3 m-0">O-vations Users</h1>
                    </div>
                    <div>
                        <CreateButton
                            clickHandler={this.onNewUserClick}
                            id={elements.usersPage.id.newUserTrigger}
                            name="New User"
                        />
                    </div>
                </div>
                <hr />
            </div>
        );
    }

    renderSearch() {
        const { state } = this;
        return (
            <div className="wrap mt-4">
                <Row>
                    <Col className="d-flex">
                        <div className="flex-grow">
                            <InputGroup>
                                <InputGroupText>
                                    <FAIcon icon={faSearch} />
                                </InputGroupText>
                                <Input
                                    id={elements.usersPage.id.searchBox}
                                    type="search"
                                    placeholder="Search Users"
                                    value={state.searchTerm}
                                    onChange={this.onSearchInputChange}
                                />
                            </InputGroup>
                        </div>
                        <div className="ms-3">
                            <UncontrolledDropdown className={elements.usersPage.class.filterByStatusDropdown}>
                                <DropdownToggle className="btn--soft" color="light" caret>
                                    Filter by Status
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div id={elements.usersPage.id.activeCheckbox} className="p-1 ps-3 pe-3">
                                        <FormGroup check>
                                            <Label check className="d-block">
                                                <Input
                                                    type="checkbox"
                                                    name="includeActive"
                                                    checked={state.includeActive}
                                                    onChange={this.onArchiveStatusChange}
                                                />{' '}
                                                Active
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <div id={elements.usersPage.id.archivedCheckbox} className="p-1 ps-3 pe-3">
                                        <FormGroup check>
                                            <Label check className="d-block">
                                                <Input
                                                    type="checkbox"
                                                    name="includeArchived"
                                                    checked={state.includeArchived}
                                                    onChange={this.onArchiveStatusChange}
                                                />{' '}
                                                Archived
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Col>
                    <Col className="text-end">
                        <MiniPaginate
                            id={elements.usersPage.id.tablePaginate}
                            page={state.page}
                            perPage={state.perPage}
                            total={state.total}
                            onArrowClick={this.onMiniPageClick}
                            noun="User"
                        />
                    </Col>
                </Row>
                <hr />
            </div>
        );
    }

    renderList() {
        return (
            <div className="wrap mt-4">
                <UserDataTable
                    id={elements.usersPage.id.dataTable}
                    ref={(dataTable) => {
                        this.dataTable = dataTable;
                    }}
                    columns={[
                        {
                            key: 'name',
                            header: 'User Name',
                            sortable: true,
                            cell: (row, key) => (
                                <td key={key}>
                                    {row.firstName} {row.lastName}
                                </td>
                            ),
                        },
                        { key: 'email', header: 'Email', sortable: true },
                        {
                            key: 'createDate',
                            header: 'Date Added',
                            cell: (row, key) => <td key={key}>{moment.utc(row.createDate).format(DATE_FORMAT)}</td>,
                            sortable: true,
                        },
                        {
                            key: 'isArchived',
                            header: '',
                            cell: (row, key) => (
                                <td key={key} className="p-0 align-middle">
                                    {row.isArchived && (
                                        <div className="btn btn-secondary btn-sm disabled">Archived</div>
                                    )}
                                </td>
                            ),
                        },
                        {
                            key: 'edit',
                            header: '',
                            cell: (row, key) => (
                                <td key={key}>
                                    <Link
                                        to={toPath(routes.USER_DETAIL, { userId: row.id })}
                                        className={IGNORE_ROW_CLICK}
                                    >
                                        <FAIcon icon={faPencilAlt} className="text-body" />
                                    </Link>
                                </td>
                            ),
                        },
                    ]}
                    search={this.onSearch}
                    onRowClick={this.onRowClick}
                    rowClassName={(row) => classNames({ 'text-secondary': row.isArchived })}
                />
            </div>
        );
    }

    render() {
        return (
            <>
                {this.renderUser()}
                {this.renderSearch()}
                {this.renderList()}
            </>
        );
    }
}

/* istanbul ignore next */
export default connect((state) => state)(UserSearchContainer);
