import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import CallTypeState from 'redux-modules/definitions/CallTypeState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { Action } from 'redux';

export const initialState: CallTypeState = {
    list: [],
};

const { reducer, update } = createReducer('callType/UPDATE', initialState);
export const callTypeReducer = reducer;

export const emptyCallType: OvationsApi.Types.CallType = {
    id: 0,
    programId: '',
    name: '',
    isActive: false,
    dispositionIds: [],
};

export const actions = {
    update,

    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.CallType.fetchAll(clientId, programId);
            if (list) {
                dispatch(clientContextManager.action(clientId, update({ list })));
            }
        };
    },

    createCallType(
        clientId: string,
        programId: string,
        callType: OvationsApi.Types.CallType,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newId = await OvationsApi.CallType.create(clientId, programId, callType);
            const newDisposition = { ...callType, id: newId };
            dispatch(actions.upsertCallType(clientId, newDisposition));
        };
    },

    updateCallType(
        clientId: string,
        programId: string,
        callType: OvationsApi.Types.CallType,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.CallType.update(clientId, programId, callType);
            dispatch(actions.upsertCallType(clientId, callType));
        };
    },

    updateSortedCallTypes(
        clientId: string,
        programId: string,
        callTypes: OvationsApi.Types.CallType[],
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            await OvationsApi.CallType.updateAll(clientId, programId, callTypes);
            if (callTypes) {
                const list = [...callTypes];
                dispatch(clientContextManager.action(clientId, update({ list })));
            }
        };
    },

    upsertCallType(clientId: string, callType: OvationsApi.Types.CallType): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const list = [...ctx.callType.list, callType];
            return dispatch(clientContextManager.action(clientId, update({ list })));
        };
    },
};

export const selectors = {
    getListForProgram: createSelector(
        (state: CallTypeState) => state.list,
        (list) =>
            createSelector(
                (programId: string) => programId,
                (programId) => list.filter((callType) => callType.programId === programId),
            ),
    ),
};
