import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { keyBy, values } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import BatchConfigurationState from 'redux-modules/definitions/BatchConfigurationState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import { Action } from 'redux';

export const emptyBatchConfiguration: OvationsApi.Types.BatchConfiguration = {
    id: 0,
    programId: '',
    name: '',
    batchType: OvationsApi.Enums.BatchType.SingleLoadUSA,
    jobType: OvationsApi.Enums.BatchJobType.OneTime,
    isArchived: false,
    startDate: null,
    isRequireCertifiedAddresses: false,
};

export const emptyRecurringBatchConfiguration: OvationsApi.Types.RecurringBatchConfiguration = {
    id: 0,
    programId: '',
    name: '',
    batchType: OvationsApi.Enums.BatchType.SingleLoadUSA,
    jobType: OvationsApi.Enums.BatchJobType.Recurring,
    isArchived: false,
    startDate: null,
    runInterval: 1,
    period: OvationsApi.Enums.IntervalPeriod.Week,
    daysOfWeek: [],
    monthlyDate: null,
    isRequireCertifiedAddresses: false,
};

export const initialState: BatchConfigurationState = {
    map: {},
    totalResults: 0,
};

const { reducer, update } = createReducer('batchConfiguration/UPDATE', initialState);
export const batchConfigurationReducer = reducer;

export const actions = {
    fetchAll(
        clientId: string,
        programId: string,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.BatchConfiguration> | undefined>,
        S,
        ExtraArg,
        Action
    > {
        return async (dispatch) => {
            const response = await OvationsApi.BatchConfiguration.fetchAll(clientId, programId, false);
            if (!response) {
                return;
            }
            await dispatch(actions.upsertBatchConfigurationList(clientId, response));
            return response;
        };
    },

    search(
        clientId: string,
        programId: string,
        batchConfigurationRequest: Partial<OvationsApi.Types.BatchConfigurationRequest>,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.BatchConfiguration> | undefined>,
        S,
        ExtraArg,
        Action
    > {
        return async (dispatch) => {
            const response = await OvationsApi.BatchConfiguration.search(
                clientId,
                programId,
                batchConfigurationRequest,
                false,
            );
            if (!response) {
                return;
            }
            await dispatch(actions.upsertBatchConfigurationList(clientId, response));
            return response;
        };
    },

    create(
        clientId: string,
        batchConfiguration: OvationsApi.Types.BatchConfiguration,
    ): ThunkAction<Promise<number | undefined>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const newId = await OvationsApi.BatchConfiguration.create(clientId, batchConfiguration);
            if (!newId) {
                return;
            }
            const newBatchConfiguration = { ...batchConfiguration, id: newId };
            const ctx = clientContextManager.getContext(getState(), clientId);
            const totalResults = ctx.batchConfiguration.totalResults + 1;
            dispatch(actions.upsertBatchConfiguration(clientId, newBatchConfiguration));
            dispatch(clientContextManager.action(clientId, update({ totalResults })));
            return newId;
        };
    },

    upsertBatchConfiguration(
        clientId: string,
        batchConfiguration: OvationsApi.Types.BatchConfiguration,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.batchConfiguration.map, [batchConfiguration.id]: batchConfiguration };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    upsertBatchConfigurationList(
        clientId: string,
        response: OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.BatchConfiguration>,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const map = keyBy(response.results, 'id');
            return dispatch(
                clientContextManager.action(clientId, update({ map, totalResults: response.totalResults })),
            );
        };
    },
};

const getList = createSelector([(state) => state.map], (map) => values(map));

export const selectors = {
    getList,
};
