import { AnyAction, Dispatch } from 'redux';

import createReducer from 'core/lib/createReducer';
import { removeFirst } from 'core/util/arrays';
import Notification from 'definitions/Notification';
import * as Theme from 'enums/Theme';
import NotificationState from 'redux-modules/definitions/NotificationState';
import S from 'redux-modules/definitions/RootState';

let internalCounter = 0;
const initialState: NotificationState = {
    items: [],
};

const { reducer, update } = createReducer<NotificationState>('notification/UPDATE', initialState);
export const notificationReducer = reducer;

export const actions = {
    remove: (id: number) => (dispatch: Dispatch<AnyAction>, getState: () => S) => {
        const { items } = getState().notification;
        return dispatch(update({ items: removeFirst(items, (n) => n.id === id) }));
    },

    add: (settings: Partial<Notification>) => (dispatch: Dispatch<AnyAction>, getState: () => S) => {
        const { items } = getState().notification;
        const newNotification = {
            ...settings,
            id: internalCounter++,
            type: settings.type || Theme.Color.Primary,
        };
        if (newNotification.duration) {
            setTimeout(() => dispatch(actions.remove(newNotification.id)), newNotification.duration);
        }
        return dispatch(update({ items: [...items, newNotification] }));
    },
};
