import { keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import { DEFAULT_LOCALE } from 'core/l10n/locales';
import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import CustomerSearchRequest from 'core/ovations-api/definitions/CustomerSearchRequest';
import { AlternateProfileType } from 'core/ovations-api/enums';
import CustomerState from 'redux-modules/definitions/CustomerState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';

export const emptyCertifiableAddress: OvationsApi.Types.CertifiableAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    status: OvationsApi.Enums.AddressCertificationStatus.NonCertified,
};

export const emptyCustomer: OvationsApi.Types.Customer = {
    id: '',
    email: '',
    firstName: '',
    optInToFutureOffers: false,
    lastName: '',
    phoneNumber: null,
    preferredEmail: '',
    preferredPhoneNumber: null,
    address: emptyCertifiableAddress,
    preferredContactMethod: OvationsApi.Enums.NotificationChannelType.None,
    preferredLanguage: DEFAULT_LOCALE,
    isActive: true,
    isLegacyUser: false,
    hasSavedSsn: false,
    legalName: '',
    alternateProfiles: [],
    customFields: {},
};

export const initialState: CustomerState = {
    addressValidationQueue: [],
    map: {},
    totalResults: 0,
    promotionLimitLedger: {},
};

const { reducer, update } = createReducer('customer/UPDATE', initialState);
export const customerReducer = reducer;

export const actions = {
    update,

    fetchSingle(
        clientId: string,
        programId: string,
        customerId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const customer = await OvationsApi.Customer.fetchSingle(clientId, programId, customerId);
            return dispatch(actions.upsertCustomer(clientId, customer));
        };
    },

    updateValidationQueue(
        clientId: string,
        addressValidationQueue: OvationsApi.Types.Customer[],
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            if (ctx.customer.addressValidationQueue === addressValidationQueue) {
                return;
            }
            dispatch(clientContextManager.action(clientId, update({ addressValidationQueue })));
        };
    },

    createCustomer(
        clientId: string,
        programId: string,
        customer: OvationsApi.Types.Customer,
        fromMailIn: boolean | undefined,
    ): ThunkAction<Promise<OvationsApi.Types.CustomerActionResult>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const result = await OvationsApi.Customer.create(clientId, programId, customer, fromMailIn);
            if (result.success) {
                const { id } = result.customer;
                dispatch(actions.upsertCustomer(clientId, { ...customer, id }));
            }
            return result;
        };
    },

    updateCustomer(
        clientId: string,
        programId: string,
        customer: OvationsApi.Types.Customer,
        fromMailIn: boolean | undefined,
    ): ThunkAction<Promise<OvationsApi.Types.CustomerActionResult>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const result = await OvationsApi.Customer.update(clientId, programId, customer, fromMailIn);
            if (result.success) {
                dispatch(actions.upsertCustomer(clientId, customer));
            }
            return result;
        };
    },

    upsertCustomer(clientId: string, customer: OvationsApi.Types.Customer): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.customer.map, [customer.id]: customer };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    fetchCustomerLimitLedger(
        clientId: string,
        programId: string,
        customerId: string,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const limitLedger = await OvationsApi.Customer.fetchCustomerPromotionLimitLedger(
                clientId,
                programId,
                customerId,
            );
            const ctx = clientContextManager.getContext(getState(), clientId);
            const promotionLimitLedger = { ...ctx.customer.promotionLimitLedger, [customerId]: limitLedger };
            return dispatch(clientContextManager.action(clientId, update({ promotionLimitLedger })));
        };
    },

    search(
        clientId: string,
        programId: string,
        request: Partial<CustomerSearchRequest>,
    ): ThunkAction<Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.Customer>>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const customers = await OvationsApi.Customer.searchAll(clientId, programId, request);
            const map = keyBy(customers.results, 'id');
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: customers.totalResults })));
            return customers;
        };
    },

    saveCustomerSSN(
        clientId: string,
        programId: string,
        ssnRequest: OvationsApi.Types.CustomerSSNRequest,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async () => {
            const result = await OvationsApi.Customer.saveCustomerSSN(clientId, programId, ssnRequest);
            return result;
        };
    },

    saveNote(
        clientId: string,
        programId: string,
        customerId: string,
        note: string,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async () => {
            const result = await OvationsApi.Note.create(clientId, programId, customerId, note);
            return result;
        };
    },
};

const getList = createSelector([(state: CustomerState) => state.map], (map: CustomerState['map']) => values(map));

export const getTaxAddress = (customer: OvationsApi.Types.Customer): OvationsApi.Types.AlternateProfile | undefined => {
    const { alternateProfiles } = customer;
    return alternateProfiles && alternateProfiles.find((ap) => ap.alternateProfileType === AlternateProfileType.Tax);
};

export const replaceTaxAddressWithAddress = (
    alternateProfiles: OvationsApi.Types.AlternateProfile[] = [],
    replacementAddress: OvationsApi.Types.CertifiableAddress,
): OvationsApi.Types.AlternateProfile[] => {
    const taxAddress = alternateProfiles.find((altProfile) => {
        return altProfile.alternateProfileType === OvationsApi.Enums.AlternateProfileType.Tax;
    });

    if (!taxAddress) {
        return alternateProfiles;
    }

    const altProfilesWOTaxes = alternateProfiles.filter((altProfile) => {
        return altProfile.alternateProfileType !== OvationsApi.Enums.AlternateProfileType.Tax;
    });

    const updatedTaxProfile: OvationsApi.Types.AlternateProfile = {
        ...replacementAddress,
        alternateProfileType: OvationsApi.Enums.AlternateProfileType.Tax,
    };

    return [...altProfilesWOTaxes, updatedTaxProfile];
};

export const isTaxAddressPrimaryAddress = (
    profile: OvationsApi.Types.Customer | OvationsApi.Types.RegistrationRequest | null,
) => {
    if (profile == null) {
        return false;
    }

    const taxAddress = getTaxAddress(profile);
    const primaryAddress = profile.address;

    if (taxAddress === undefined) {
        return false;
    }

    const evalArray = [
        taxAddress.address1 === primaryAddress.address1,
        taxAddress.address2 === primaryAddress.address2,
        taxAddress.city === primaryAddress.city,
        taxAddress.state === primaryAddress.state,
        taxAddress.zip === primaryAddress.zip,
    ];

    if (evalArray.indexOf(false) !== -1) {
        return false;
    }

    return true;
};

export const selectors = {
    getList,
};
