import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ProfileState from 'redux-modules/definitions/ProfileState';
import S from 'redux-modules/definitions/RootState';

type PlatformUserProfile = OvationsApi.Types.PlatformUserProfile;

const initialState: ProfileState = {
    map: {},
    me: '',
};

export const emptyProfile: PlatformUserProfile = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    isArchived: true,
    createDate: '',
    platformPermissions: [],
    clientRoles: [],
};

const { reducer, update } = createReducer<ProfileState>('profile/UPDATE', initialState);
export const profileReducer = reducer;

export const actions = {
    fetchProfile: (id: string): ThunkAction<Promise<PlatformUserProfile | undefined>, S, void, Action> => {
        return async (dispatch) => {
            const userProfile = await OvationsApi.PlatformUserProfile.fetch(id);
            if (!userProfile) {
                return;
            }
            dispatch(actions.upsertProfile(userProfile));
            return userProfile;
        };
    },

    fetchMe: (): ThunkAction<Promise<PlatformUserProfile>, S, void, Action> => {
        return async (dispatch, getState) => {
            const userProfile = await OvationsApi.PlatformUserProfile.fetchMe();
            dispatch(
                update({
                    me: userProfile.id,
                    map: { ...getState().profile.map, [userProfile.id]: userProfile },
                }),
            );
            return userProfile;
        };
    },

    saveUpdates: (profile: PlatformUserProfile): ThunkAction<void, S, void, Action> => {
        return async (dispatch) => {
            await OvationsApi.PlatformUserProfile.update(profile);
            dispatch(actions.upsertProfile(profile));
        };
    },

    upsertProfile: (profile: PlatformUserProfile): ThunkAction<void, S, void, Action> => {
        return (dispatch, getState) => {
            const map = { ...getState().profile.map, [profile.id]: profile };
            dispatch(update({ map }));
        };
    },

    updateStatus: (id: string, isArchived: boolean): ThunkAction<void, S, void, Action> => {
        return async (dispatch, getState) => {
            await OvationsApi.PlatformUserProfile.updateStatus(id, isArchived);
            const userProfile = getState().profile.map[id];
            const updatedProfile = { ...userProfile, isArchived };
            dispatch(actions.upsertProfile(updatedProfile));
        };
    },
};

export const selectors = {
    getMe: (state: ProfileState): PlatformUserProfile => state.map[state.me] || emptyProfile,
};
