/* eslint-disable @typescript-eslint/no-use-before-define */
import { filter, includes, isNil, keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import {
    Promotion,
    PromotionQuestion,
    PromotionSummary,
    UserDefinedRewardCalculation,
} from 'core/ovations-api/definitions';
import {
    HouseholdLimitTypeValue,
    ProfileFieldType,
    PromotionType,
    QuestionType,
    RewardCalculationType,
    RewardLimitCalculationType,
    RewardPackageType,
} from 'core/ovations-api/enums';
import { replaceIndex } from 'core/util/arrays';
import { Lens, makeLens } from 'core/util/lens';
import RewardCalculationMap from 'definitions/RewardCalculationMap';
import RewardLimitCalculationMap from 'definitions/RewardLimitCalculationMap';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PromotionState from 'redux-modules/definitions/PromotionState';
import S from 'redux-modules/definitions/RootState';
import { emptyQuestion } from 'redux-modules/question';

export const initialState: PromotionState = {
    map: {},
    summaries: [],
    totalResults: 0,
    profileFieldsMap: {},
};

export const emptyPromotionSummary: PromotionSummary = {
    id: '',
    programId: '',
    state: OvationsApi.Enums.PromotionState.Pending,
    name: '',
    questions: [],
    rewardPackageTypes: [],
    rewardPrefundEnabled: false,
    promotionType: OvationsApi.Enums.PromotionType.ClaimSubmission,
    customerFacingName: '',
};

export const emptyPromotionQuestion: PromotionQuestion = {
    dateAdded: '',
    id: '',
    hideOnPortal: false,
    promotionId: '',
    readOnlyOnPortal: false,
    requiredOnPortal: false,
    readOnlyOnCallCenter: false,
    requiredOnCallCenter: false,
    question: emptyQuestion,
    type: QuestionType.None,
    validateOnCallCenter: false,
    needsReview: true,
    checkForDuplicate: false,
    isForPreFund: false,
    displayOnPortalHistory: false,
    promotionQuestionId: 0,
};

export const emptyQuantity: OvationsApi.Types.QuantityRewardLimitCalculation = {
    type: RewardLimitCalculationType.Quantity,
    profileFieldType: ProfileFieldType.Custom,
    promotionQuestionId: '',
    itemName: '',
    profileFieldId: null,
    quantityLimits: {},
};

export const emptyDollarAmount: OvationsApi.Types.DollarAmountRewardLimitCalculation = {
    type: RewardLimitCalculationType.DollarAmount,
    profileFieldType: ProfileFieldType.Custom,
    profileFieldId: null,
    dollarLimits: {},
};

export const emptyRewardLimitCalculations: RewardLimitCalculationMap = {
    [RewardLimitCalculationType.Quantity]: {
        type: RewardLimitCalculationType.Quantity,
        profileFieldType: ProfileFieldType.Custom,
        promotionQuestionId: '',
        itemName: '',
        profileFieldId: null,
        quantityLimits: {},
    },
    [RewardLimitCalculationType.NoLimits]: {
        type: RewardLimitCalculationType.NoLimits,
        quantityLimits: {},
    },
    [RewardLimitCalculationType.DollarAmount]: {
        type: RewardLimitCalculationType.DollarAmount,
        profileFieldType: ProfileFieldType.Custom,
        profileFieldId: null,
        dollarLimits: {},
    },
    [RewardLimitCalculationType.Both]: {
        type: RewardLimitCalculationType.Both,
        promotionQuestionId: '',
        itemName: '',
        profileFieldType: ProfileFieldType.Custom,
        profileFieldId: null,
        quantityLimits: {},
        dollarLimits: {},
    },
    [RewardLimitCalculationType.PromotionGroup]: {
        type: RewardLimitCalculationType.PromotionGroup,
    },
};

export const limitLens: { [K in keyof RewardLimitCalculationMap]: Lens<RewardLimitCalculationMap[K]> } = {
    [RewardLimitCalculationType.Quantity]: makeLens<OvationsApi.Types.QuantityRewardLimitCalculation>(),
    [RewardLimitCalculationType.DollarAmount]: makeLens<OvationsApi.Types.DollarAmountRewardLimitCalculation>(),
    [RewardLimitCalculationType.NoLimits]: makeLens<OvationsApi.Types.NoLimitsRewardLimitCalculation>(),
    [RewardLimitCalculationType.Both]: makeLens<OvationsApi.Types.BothRewardLimitCalculation>(),
    [RewardLimitCalculationType.PromotionGroup]: makeLens<OvationsApi.Types.PromotionGroupRewardLimitCalculation>(),
};

const emptyRewardCalculationBase: OvationsApi.Types.RewardCalculationBase = {
    id: 0,
    type: RewardCalculationType.Static,
    label: null,
    alternatePayeeRewardPackageId: null,
    alternatePayeeRewardPackageType: null,
    rewardPackageName: '',
    rewardPackages: [],
    sortOrder: 0,
};

export const emptyChoice: OvationsApi.Types.StaticRewardCalculation = {
    ...emptyRewardCalculationBase,
    type: RewardCalculationType.Static,
    amount: null,
    sortOrder: 0,
};

export const emptyRewardCalculations: RewardCalculationMap = {
    [RewardCalculationType.Static]: {
        ...emptyRewardCalculationBase,
        type: RewardCalculationType.Static,
        amount: null,
    },
    [RewardCalculationType.StaticPoints]: {
        ...emptyRewardCalculationBase,
        type: RewardCalculationType.StaticPoints,
        points: null,
    },
    [RewardCalculationType.Variable]: {
        ...emptyRewardCalculationBase,
        type: RewardCalculationType.Variable,
        dropdownQuestion: null,
        values: {},
    },
    [RewardCalculationType.UserDefined]: {
        ...emptyRewardCalculationBase,
        type: RewardCalculationType.UserDefined,
        numberQuestion: null,
    },
    [RewardCalculationType.Choice]: {
        ...emptyRewardCalculationBase,
        type: RewardCalculationType.Choice,
        choices: [
            {
                ...emptyChoice,
                sortOrder: 0,
            },
            {
                ...emptyChoice,
                sortOrder: 1,
            },
        ],
    },
    [RewardCalculationType.Percentage]: {
        ...emptyRewardCalculationBase,
        type: RewardCalculationType.Percentage,
        promotionQuestionRewardCalculations: [],
        percentage: 0,
    },
};

export const lens: { [K in keyof RewardCalculationMap]: Lens<RewardCalculationMap[K]> } = {
    [RewardCalculationType.Choice]: makeLens<OvationsApi.Types.ChoiceRewardCalculation>(),
    [RewardCalculationType.Static]: makeLens<OvationsApi.Types.StaticRewardCalculation>(),
    [RewardCalculationType.StaticPoints]: makeLens<OvationsApi.Types.StaticPointsRewardCalculation>(),
    [RewardCalculationType.UserDefined]: makeLens<UserDefinedRewardCalculation>(),
    [RewardCalculationType.Variable]: makeLens<OvationsApi.Types.VariableRewardCalculation>(),
    [RewardCalculationType.Percentage]: makeLens<OvationsApi.Types.PercentageRewardCalculation>(),
};

export const emptyPromotion: Promotion = {
    checkDuplicatesAcrossProgram: false,
    id: '',
    number: 0,
    programId: '',
    name: '',
    purchaseStart: null,
    purchaseStartGracePeriod: '',
    purchaseEnd: null,
    purchaseEndGracePeriod: '',
    websiteSubmissionStart: null,
    websiteSubmissionEnd: null,
    mailSubmissionStart: null,
    mailSubmissionEnd: null,
    missingLanguages: [],
    promotionClose: null,
    ownerName: '',
    contactNumber: '',
    contactName: '',
    contactEmail: '',
    estimatedRedemptions: null,
    poBox: '',
    poNumber: '',
    suiteNumber: '',
    callCenterTollFreeNumber: '',
    questions: [],
    requiresPrefunding: false,
    rewardCalculations: [emptyRewardCalculations.Static],
    promotionLimit: null,
    state: OvationsApi.Enums.PromotionState.Draft,
    termsAndConditions: null,
    termsAndConditionsHeading: null,
    eSignAgreementUrl: null,
    rewardPrefundEnabled: false,
    hasAlternatePayee: false,
    eligibilityGroups: [],
    promotionGroup: undefined,
    promotionType: OvationsApi.Enums.PromotionType.ClaimSubmission,
    claimRedemptionExpiration: null,
    overrideManualValidation: false,
    clientOfferId: '',
    referralConfirmation: false,
    householdLimit: null,
    householdLimitType: OvationsApi.Enums.HouseholdLimitTypeValue.None,
    timeLimit: null,
    householdLimitResetType: null,
    hasHouseholdLimitResetType: false,
    customerFacingName: '',
    bulkImportEnabled: false,
    createDate: '',
};

const { reducer, update } = createReducer<PromotionState>('promotion/UPDATE', initialState);
export const promotionReducer = reducer;

export const actions = {
    fetchAll(
        clientId: string,
        programId: string,
        promotionRequest: Partial<OvationsApi.Types.PromotionRequest>,
    ): ThunkAction<Promise<OvationsApi.Types.PromotionResult>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const response = await OvationsApi.Promotion.fetchAll(clientId, programId, promotionRequest);
            let updatedResponse = {};
            if (response.results) {
                updatedResponse = response.results.map((obj) => ({
                    ...obj,
                    householdLimitType:
                        obj.householdLimit !== null ? HouseholdLimitTypeValue.Rewards : HouseholdLimitTypeValue.None,
                }));
            }
            const map = keyBy(updatedResponse, 'id');
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: response.totalResults })));
            return response;
        };
    },

    updatePromotionsList(clientId: string, promotions: Promotion[]): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            dispatch(clientContextManager.action(clientId, update({ map: keyBy(promotions, 'id') })));
        };
    },

    fetchSummaries(
        clientId: string,
        programId: string,
        promotionStates?: OvationsApi.Enums.PromotionState[],
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const summaries = await OvationsApi.Promotion.fetchSummaries(clientId, programId, promotionStates);
            dispatch(clientContextManager.action(clientId, update({ summaries })));
        };
    },

    fetchProfileFields(clientId: string, programId: string): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch) => {
            const profileFields = await OvationsApi.Promotion.fetchProfileFields(clientId, programId);
            dispatch(actions.upsertProfileFields(clientId, programId, profileFields));
        };
    },

    updateEligibilityGroup(
        clientId: string,
        programId: string,
        promotionId: string,
        eligibilityGroup: OvationsApi.Types.EligibilityGroup,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return async (dispatch) => {
            const updatedEligibilityGroup = await OvationsApi.Promotion.updateEligibilityGroup(
                clientId,
                programId,
                promotionId,
                eligibilityGroup,
            );
            dispatch(actions.upsertEligibilityGroup(clientId, promotionId, updatedEligibilityGroup));
        };
    },

    upsertEligibilityGroup(
        clientId: string,
        promotionId: string,
        eligibilityGroup: OvationsApi.Types.EligibilityGroup,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const promotion = ctx.promotion.map[promotionId];
            const index = promotion.eligibilityGroups.findIndex((eg) => eg.id === eligibilityGroup.id);
            const updatedPromotion = {
                ...promotion,
                eligibilityGroups: replaceIndex(promotion.eligibilityGroups, index, () => eligibilityGroup),
            };
            dispatch(actions.upsertPromotion(clientId, updatedPromotion));
        };
    },

    fetchSingle(
        clientId: string,
        programId: string,
        promotionId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const promotion = await OvationsApi.Promotion.fetch(clientId, programId, promotionId);
            if (!promotion) {
                return;
            }
            promotion.householdLimitType =
                promotion.householdLimit !== null ? HouseholdLimitTypeValue.Rewards : HouseholdLimitTypeValue.None;
            dispatch(actions.upsertPromotion(clientId, promotion));
        };
    },

    createNewPromotion(clientId: string, promotion: Promotion): ThunkAction<Promise<string>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const newId = await OvationsApi.Promotion.create(clientId, promotion);
            const newPromotion = { ...promotion, id: newId } as Promotion;
            const ctx = clientContextManager.getContext(getState(), clientId);
            const totalResults = ctx.promotion.totalResults + 1;
            dispatch(actions.upsertPromotion(clientId, newPromotion));
            dispatch(clientContextManager.action(clientId, update({ totalResults })));
            return newId;
        };
    },

    updatePromotion(clientId: string, promotion: Promotion): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Promotion.update(clientId, promotion);
            dispatch(actions.upsertPromotion(clientId, promotion));
        };
    },

    upsertPromotion(clientId: string, promotion: Promotion): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.promotion.map, [promotion.id]: promotion };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    upsertProfileFields(
        clientId: string,
        programId: string,
        profileFields: OvationsApi.Types.ProfileField[],
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const profileFieldsMap = { ...ctx.promotion.profileFieldsMap, [programId]: profileFields };
            dispatch(clientContextManager.action(clientId, update({ profileFieldsMap })));
        };
    },
};

const getList = createSelector([(state: PromotionState) => state.map], (map) => values(map));

const isStaticRewardReadyForPending = (reward: OvationsApi.Types.StaticRewardCalculation) => {
    return !isNil(reward.amount) && reward.amount > 0 && !isNil(reward.rewardPackages);
};

const isVariableRewardReadyForPending = (reward: OvationsApi.Types.VariableRewardCalculation) => {
    if (!reward.dropdownQuestion || !reward.rewardPackages || !reward.values) {
        return false;
    }
    const areOptionsValid = reward.dropdownQuestion.options.some((option) => {
        return !isNil(reward.values[option]) && reward.values[option] > 0;
    });

    return areOptionsValid;
};

const isUserDefinedRewardReadyForPending = (reward: OvationsApi.Types.UserDefinedRewardCalculation) => {
    return !isNil(reward.rewardPackages) && !isNil(reward.numberQuestion);
};

const isChoiceRewardReadyForPending = (reward: OvationsApi.Types.ChoiceRewardCalculation): boolean => {
    if (reward.choices.length < 2) {
        return false;
    }
    if (reward.choices.every((choice) => choice.label)) {
        return isRewardReadyForPending(reward.choices);
    }
    return false;
};

const isPercentageRewardReadyForPending = (reward: OvationsApi.Types.PercentageRewardCalculation): boolean => {
    return !!reward.promotionQuestionRewardCalculations.length && !isNil(reward.rewardPackages);
};

const areQuestionsReadyForPending = createSelector(
    (questions: OvationsApi.Types.PromotionQuestion[]) => questions,
    (questions) => {
        if (!questions.length) {
            return false;
        }
        const hasQuestionThatNeedsReview = questions.some((question) => question.needsReview);
        return !hasQuestionThatNeedsReview;
    },
);

const isRewardReadyForPending = (rewardCalculations: OvationsApi.Types.RewardCalculation[] | null) => {
    if (!rewardCalculations) {
        return false;
    }
    let isReady = true;
    for (let index = 0; index < rewardCalculations.length && isReady; index++) {
        const rewardCalc = rewardCalculations[index];
        switch (rewardCalculations[index].type) {
            case OvationsApi.Enums.RewardCalculationType.Static: {
                isReady = isStaticRewardReadyForPending(rewardCalc as OvationsApi.Types.StaticRewardCalculation);
                break;
            }
            case OvationsApi.Enums.RewardCalculationType.Variable: {
                isReady = isVariableRewardReadyForPending(rewardCalc as OvationsApi.Types.VariableRewardCalculation);
                break;
            }
            case OvationsApi.Enums.RewardCalculationType.UserDefined: {
                isReady = isUserDefinedRewardReadyForPending(
                    rewardCalc as OvationsApi.Types.UserDefinedRewardCalculation,
                );
                break;
            }
            case OvationsApi.Enums.RewardCalculationType.Choice: {
                isReady = isChoiceRewardReadyForPending(rewardCalc as OvationsApi.Types.ChoiceRewardCalculation);
                break;
            }
            case OvationsApi.Enums.RewardCalculationType.Percentage: {
                isReady = isPercentageRewardReadyForPending(
                    rewardCalc as OvationsApi.Types.PercentageRewardCalculation,
                );
                break;
            }
            default:
                isReady = false;
                break;
        }
    }
    return isReady;
};

const isVirtualReward = (reward: OvationsApi.Types.RewardCalculation | null): boolean => {
    if (!reward) {
        return false;
    }

    if (reward.type === OvationsApi.Enums.RewardCalculationType.Choice) {
        return reward.choices.some(isVirtualReward);
    }
    return reward.rewardPackages.some((x) => x.rewardPackageType === RewardPackageType.VirtualUSA);
};

const promotionQuantityLimitIsEmpty = (promotion: OvationsApi.Types.Promotion): boolean => {
    if (promotion.promotionLimit !== null) {
        if (
            promotion.promotionLimit.type === RewardLimitCalculationType.Quantity ||
            promotion.promotionLimit.type === RewardLimitCalculationType.Both
        ) {
            if (Object.keys(promotion.promotionLimit.quantityLimits).length === 0) {
                return true;
            }
        }
    }
    return false;
};

const promotionDollarLimitIsEmpty = (promotion: OvationsApi.Types.Promotion): boolean => {
    if (promotion.promotionLimit !== null) {
        if (
            promotion.promotionLimit.type === RewardLimitCalculationType.DollarAmount ||
            promotion.promotionLimit.type === RewardLimitCalculationType.Both
        ) {
            if (Object.keys(promotion.promotionLimit.dollarLimits).length === 0) {
                return true;
            }
        }
    }
    return false;
};

const promotionLimitIsSetProperly = (promotion: OvationsApi.Types.Promotion) => {
    if (!promotion) {
        return true;
    }

    if (promotionQuantityLimitIsEmpty(promotion) === true) {
        return false;
    }

    if (promotionDollarLimitIsEmpty(promotion) === true) {
        return false;
    }

    return true;
};

const isRewardVirtualAndEsignConfigured = (promotion: OvationsApi.Types.Promotion) => {
    if (!promotion) {
        return false;
    }
    if (!promotion.rewardCalculations) {
        return false;
    }

    const { rewardCalculations } = promotion;
    let isAnyVirtual = false;
    isAnyVirtual =
        rewardCalculations.length > 0
            ? rewardCalculations.some((rewardCalculation) => isVirtualReward(rewardCalculation))
            : false;

    if (isAnyVirtual) {
        return isEsignDocumentConfigured(promotion);
    }
    return true;
};

const isEsignDocumentConfigured = (promotion: OvationsApi.Types.Promotion) => {
    return !isNil(promotion.eSignAgreementUrl);
};

const isRewardReadyForPendingMemoized = createSelector(
    (rewards: OvationsApi.Types.RewardCalculation[] | null) => rewards,
    isRewardReadyForPending,
);

const isAlternateRecepientOptionSet = (promotion: OvationsApi.Types.Promotion): boolean => {
    const { rewardCalculations } = promotion;
    if (promotion.hasAlternatePayee) {
        rewardCalculations?.forEach((rewardCalc) => {
            return (
                !isNil(rewardCalc) &&
                rewardCalc.type !== OvationsApi.Enums.RewardCalculationType.Choice &&
                !isNil(rewardCalc.alternatePayeeRewardPackageId)
            );
        });
    }
    return true;
};

export const selectors = {
    getList,

    getListForProgram: (state: PromotionState, programId: string) => {
        const promotions = selectors.getList(state);
        return promotions.filter((promotion) => promotion.programId === programId);
    },

    isReadyForPending: (promotion: OvationsApi.Types.Promotion): boolean => {
        switch (promotion.promotionType) {
            case PromotionType.ClaimSubmission:
                return selectors.isReadyForPendingClaim(promotion);
            case PromotionType.Redemption: // TEMPORARY, WILL NEED SELECTOR CREATED IN FUTURE
                return !!(promotion.name && promotion.name.length > 0);
            default:
                return false;
        }
    },

    getSummariesForProgram: createSelector(
        (state: PromotionState) => state.summaries,
        (summaries) =>
            createSelector(
                (programId: string) => programId,
                (programId) => filter(summaries, { programId }),
            ),
    ),

    canEditInfo: createSelector(
        (promotion: OvationsApi.Types.Promotion) => promotion,
        (promotion) => {
            const readOnlyStates = [OvationsApi.Enums.PromotionState.Pending, OvationsApi.Enums.PromotionState.Closed];

            return !includes(readOnlyStates, promotion.state);
        },
    ),

    isReadyForPendingClaim: createSelector(
        (promotion: OvationsApi.Types.Promotion) => promotion,
        (promotion) => {
            const requiredKeys: Array<keyof OvationsApi.Types.Promotion> = [
                'mailSubmissionStart',
                'mailSubmissionEnd',
                'promotionClose',
            ];
            const isMissingRequiredField = requiredKeys.some((key) => isNil(promotion[key]));
            if (isMissingRequiredField) {
                return false;
            }

            const isMissingRewardPackages = !promotion.rewardCalculations?.some((x) => x.rewardPackages.length > 0);

            return (
                !isMissingRewardPackages &&
                !isMissingRequiredField &&
                areQuestionsReadyForPending(promotion.questions) &&
                isRewardReadyForPendingMemoized(promotion.rewardCalculations) &&
                isRewardVirtualAndEsignConfigured(promotion) &&
                promotionLimitIsSetProperly(promotion) &&
                isAlternateRecepientOptionSet(promotion)
            );
        },
    ),

    getPromotionDisplayName: createSelector(
        (promotion: Partial<OvationsApi.Types.PromotionSummary | OvationsApi.Types.Promotion>) => promotion,
        (promotion) => {
            return promotion.customerFacingName ? promotion.customerFacingName : promotion.name;
        },
    ),

    isReloadableConfigureOnPromotion: createSelector(
        (summaries: PromotionSummary[]) => summaries,
        (summaries) => {
            const reloadablesRewards = [
                RewardPackageType.ReloadableUSA,
                RewardPackageType.ReloadableCAN,
                RewardPackageType.ReloadableCANVisa,
                RewardPackageType.ReloadableUSAGalileo,
            ];
            const promoWithReloadableReward = summaries.find((summary) =>
                reloadablesRewards.some((r) => summary.rewardPackageTypes.includes(r)),
            );
            if (promoWithReloadableReward) {
                return true;
            }
            return false;
        },
    ),
};
