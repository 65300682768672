import * as OvationsApi from 'core/ovations-api';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalDashboardState from 'redux-modules/definitions/PortalDashboardState';
import S from 'redux-modules/definitions/RootState';
import { getPortalCacheKey } from 'redux-modules/portal';

export const initialState: PortalDashboardState = {
    map: {},
};

export const emptyPortalDashboard: OvationsApi.Types.PortalDashboard = {
    programId: '',
    imageRotatorTitle: '',
    imageRotatorImages: [],
    enablePointTracker: false,
    pointTrackerTitle: null,
    pointTrackerGoal: null,
    allowedPointTrackerRoles: [],
    enableYearOverYearTracker: false,
    yearOverYearTrackerTitle: null,
    yearOverYearTrackerGoal: null,
    yearOverYearTrackerUnits: null,
    allowedYearOverYearTrackerRoles: [],
    trackerBackgroundFileLocation: null,
    trackerBackgroundUrl: null,
    trackerIcon: null,
    useEnglishImageRotatorImages: true,
};

export const emptyImageRotatorImage: OvationsApi.Types.ImageRotatorImage = {
    linkUrl: '',
    id: 0,
    imageLocation: '',
    altText: '',
    imageUrl: '',
};

const { reducer, update } = createReducer('portalDashboard/UPDATE', initialState);
export const portalDashboardReducer = reducer;

export const actions = {
    fetch: (clientId: string, programId: string, culture?: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const portalDashboard = await OvationsApi.Portal.fetchDashboard(clientId, programId, culture);
            if (!portalDashboard) {
                return;
            }
            dispatch(actions.upsert(clientId, portalDashboard, culture));
        };
    },

    update(
        clientId: string,
        portalDashboard: OvationsApi.Types.PortalDashboard,
        culture?: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Portal.updateDashboard(clientId, portalDashboard, culture);
            dispatch(actions.upsert(clientId, portalDashboard, culture));
        };
    },

    upsert(
        clientId: string,
        portalDashboard: OvationsApi.Types.PortalDashboard,
        culture?: string,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const cacheKey = getPortalCacheKey(portalDashboard.programId, culture);
            const map = { ...ctx.portalDashboard.map, [cacheKey]: portalDashboard };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
