import { keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import DataSetState from 'redux-modules/definitions/DataSetState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';

type DataSet = OvationsApi.Types.DataSet;
type CreateDataSetRequest = OvationsApi.Types.CreateDataSetRequest;
type EditDataSetRequest = OvationsApi.Types.EditDataSetRequest;

export const emptyDataSet: DataSet = {
    id: '',
    name: '',
    properties: {},
    programId: '',
    uniquePropertyId: 0,
    uploadDate: '',
    uploadedBy: '',
    fileName: '',
    url: null,
    version: 1,
};

export const emptyCreateRequest: CreateDataSetRequest = {
    name: '',
    programId: '',
    uniqueColumnName: '',
};

export const emptyEditRequest: EditDataSetRequest = {
    id: '',
    name: '',
    programId: '',
    uniqueColumnName: '',
    fileName: '',
    url: null,
};

export const initialState: DataSetState = {
    map: {},
};

const { reducer, update } = createReducer('dataSet/UPDATE', initialState);
export const dataSetReducer = reducer;

export const actions = {
    update,

    fetchAll(clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.DataSet.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const programDataSets = keyBy(list, 'id');
            const map = { ...ctx.dataSet.map, ...programDataSets };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    upsertDataSet(clientId: string, dataSet: DataSet): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.dataSet.map, [dataSet.id]: dataSet };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector([(state: DataSetState) => state.map], (map: DataSetState['map']) => values(map));

export const selectors = {
    getList,

    getListForProgram: (state: DataSetState, programId: string) => {
        const dataSets = selectors.getList(state);
        return dataSets.filter((dataSet) => dataSet.programId === programId);
    },
};
