import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalPointsCatalogState from 'redux-modules/definitions/PortalPointsCatalogState';
import S from 'redux-modules/definitions/RootState';

export const initialState: PortalPointsCatalogState = {
    map: {},
};

const { reducer, update } = createReducer('portalPointsCatalogContact/UPDATE', initialState);
export const portalPointsCatalogReducer = reducer;

export const emptyPointsCatalog = {
    portalId: null,
    sharedSecret: null,
    programKey: null,
    url: null,
    enabled: false,
    id: null,
} as OvationsApi.Types.PortalPointsCatalog;

export const actions = {
    fetch: (clientId: string, programId: string, portalId: number): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const portalPointsCatalog = await OvationsApi.Portal.fetchPointsCatalog(clientId, programId, portalId);
            if (!portalPointsCatalog || (portalPointsCatalog && !portalPointsCatalog.id)) {
                return;
            }
            dispatch(actions.upsert(clientId, programId, portalPointsCatalog));
        };
    },

    update(
        clientId: string,
        programId: string,
        portalPointsCatalog: OvationsApi.Types.PortalPointsCatalog,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            if (portalPointsCatalog.id) {
                await OvationsApi.Portal.updatePointsCatalog(clientId, programId, portalPointsCatalog);
            } else {
                // eslint-disable-next-line no-param-reassign
                portalPointsCatalog = await OvationsApi.Portal.createPointsCatalog(
                    clientId,
                    programId,
                    portalPointsCatalog,
                );
            }
            dispatch(actions.upsert(clientId, programId, portalPointsCatalog));
        };
    },

    upsert(
        clientId: string,
        programId: string,
        portalPointsCatalog: OvationsApi.Types.PortalPointsCatalog,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.portalPointsCatalog.map, [programId]: portalPointsCatalog };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
