import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import PortalContactState from 'redux-modules/definitions/PortalContactState';
import S from 'redux-modules/definitions/RootState';
import { getPortalCacheKey } from 'redux-modules/portal';

export const initialState: PortalContactState = {
    map: {},
};

export const emptyPortalContact: OvationsApi.Types.PortalContact = {
    programId: '',
    receivingEmailAddress: '',
    contactNumber: '',
    hoursOfOperation: '',
    useEnglishContactInfo: true,
    questionCategories: [],
};

const { reducer, update } = createReducer('portalContact/UPDATE', initialState);
export const portalContactReducer = reducer;

export const actions = {
    fetch: (clientId: string, programId: string, culture?: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const portalContact = await OvationsApi.Portal.fetchContact(clientId, programId, culture);
            if (!portalContact) {
                return;
            }
            dispatch(actions.upsert(clientId, portalContact, culture));
        };
    },

    update(
        clientId: string,
        portalContact: OvationsApi.Types.PortalContact,
        culture?: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch) => {
            await OvationsApi.Portal.updateContact(clientId, portalContact, culture);
            dispatch(actions.upsert(clientId, portalContact, culture));
        };
    },

    upsert(
        clientId: string,
        portalContact: OvationsApi.Types.PortalContact,
        culture?: string,
    ): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const cacheKey = getPortalCacheKey(portalContact.programId, culture);
            const map = { ...ctx.portalContact.map, [cacheKey]: portalContact };
            return dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};
