import { filter, keyBy, omitBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import ReasonState from 'redux-modules/definitions/ReasonState';
import S from 'redux-modules/definitions/RootState';
import { createSelector } from 'reselect';

import Reason from 'core/ovations-api/definitions/Reason';
import ClientContext from 'redux-modules/definitions/ClientContext';

export const initialState: ReasonState = {
    map: {},
};

const { reducer, update } = createReducer<ReasonState>('reason/UPDATE', initialState);
export const reasonReducer = reducer;

const duplicateRemover = (ctx: ClientContext, programId: string, reasons: Reason[]) => {
    const mapCleaned = omitBy(ctx.reason.map, { programId }) as typeof ctx.reason.map;
    const map = { ...mapCleaned, ...keyBy(reasons, 'id') };
    return map;
};

export const actions = {
    fetchAll: (clientId: string, programId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch, getState, { clientContextManager }) => {
            const reasons = await OvationsApi.Reason.fetchAll(clientId, programId);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = duplicateRemover(ctx, programId, reasons);
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },

    updateAll: (
        clientId: string,
        programId: string,
        reasons: OvationsApi.Types.Reason[],
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch, getState, { clientContextManager }) => {
            const updatedReasons = await OvationsApi.Reason.updateAll(clientId, programId, reasons);
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = duplicateRemover(ctx, programId, updatedReasons);
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector(
    (state: ReasonState) => state.map,
    (map) => values(map),
);

export const selectors = {
    getList,

    getListForProgram: createSelector(getList, (list) =>
        createSelector(
            (programId: string) => programId,
            (programId) => filter(list, { programId }),
        ),
    ),
};
