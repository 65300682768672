import React from 'react';

import Spinner from 'components/Spinner';
import { generalFailure, insufficientPermissions } from 'config/messages';
import configureWithData, { LoaderConfig as LoaderConfigWithData } from 'core/lib/configureWithData';

// eslint-disable-next-line @typescript-eslint/ban-types
export type LoaderConfig<T = {}> = LoaderConfigWithData<T>;

const withData = configureWithData({
    fallback: () => <Spinner />,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: (e: any, showError) => {
        let error = generalFailure();
        if (e.response && e.response.status === 403) {
            error = insufficientPermissions();
        }
        showError(error);
    },
    renderError: (error) => <div className="p-5">{error}</div>,
});

export default withData;
