import { Dictionary, keyBy } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import RoleState from 'redux-modules/definitions/RoleState';
import S from 'redux-modules/definitions/RootState';

type PlatformRole = OvationsApi.Types.PlatformRole;

export const emptyRole: PlatformRole = {
    id: '',
    name: '',
    rolePermissions: [],
};

const initialState: RoleState = {
    map: {},
};

const { reducer, update } = createReducer<RoleState>('UserRole/UPDATE', initialState);
export const roleReducer = reducer;

export const actions = {
    fetchAll: (): ThunkAction<Promise<Dictionary<PlatformRole>>, S, void, Action> => {
        return async (dispatch) => {
            const roles = await OvationsApi.PlatformRole.fetchAll();
            const map = keyBy(roles, 'id');
            dispatch(update({ map }));
            return map;
        };
    },

    fetchRoleDetail: (id: string): ThunkAction<Promise<PlatformRole | undefined>, S, void, Action> => {
        return async (dispatch) => {
            const userRoleDetail = await OvationsApi.PlatformRole.fetch(id);
            if (!userRoleDetail) {
                return;
            }
            dispatch(actions.upsertRoleDetail(userRoleDetail));
            return userRoleDetail;
        };
    },

    createNewRole: (userRoleDetail: PlatformRole): ThunkAction<Promise<PlatformRole>, S, void, Action> => {
        return async (dispatch) => {
            const createdRole = await OvationsApi.PlatformRole.create(userRoleDetail);
            dispatch(actions.upsertRoleDetail(createdRole));
            return createdRole;
        };
    },

    updateRole: (userRoleDetail: PlatformRole): ThunkAction<void, S, void, Action> => {
        return async (dispatch) => {
            await OvationsApi.PlatformRole.update(userRoleDetail);
            dispatch(actions.upsertRoleDetail(userRoleDetail));
        };
    },

    upsertRoleDetail: (role: PlatformRole): ThunkAction<void, S, void, Action> => {
        return (dispatch, getState) => {
            const map = { ...getState().role.map, [role.id]: role };
            dispatch(update({ map }));
        };
    },
};
