/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/static-property-placement */
import { faDownload, faMinus, faPlus, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import { clamp, findIndex } from 'lodash';
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import ClientPdfViewer from 'components/client/ClientPdfViewer';
import ImageViewer from 'components/ImageViewer';
import MiniPaginate from 'components/MiniPaginate';
import elements from 'config/elements';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { ClientLevel } from 'enums/Permission';
import Authorize from 'lib/Authorize';

interface RequiredProps {
    clientId: string;
    questions: OvationsApi.Types.PromotionFileUploadQuestion[];
    answer?: OvationsApi.Types.PromotionFileUploadQuestionAnswer;
    selectedFileQuestionId: string;
    onSelectedQuestionChange: (promotionQuestionId: string) => void;
}

interface OptionalProps {
    maxScale: number;
    minScale: number;
}

type ClaimDocumentViewerProps = RequiredProps & OptionalProps;

interface ClaimDocumentViewerState {
    rotationDegrees: number;
    scale: number;
}

interface ViewerStrategy {
    fileNamePattern: RegExp;
    fileTypePattern: RegExp;
    render: (instance: ClaimDocumentViewer) => JSX.Element;
    zoomEnabled: boolean;
}

const viewerStategies: ViewerStrategy[] = [
    {
        fileNamePattern: /\.(jpe?g|png)$/i,
        fileTypePattern: /^image\//i,
        zoomEnabled: true,
        render: (instance) => {
            const { answer } = instance.props;
            return (
                <ImageViewer
                    imageSrc={answer!.fileUrl || answer!.fileLocation || ''}
                    scale={instance.state.scale}
                    rotationDegrees={instance.state.rotationDegrees}
                />
            );
        },
    },
    {
        fileNamePattern: /\.pdf$/i,
        fileTypePattern: /^application\/pdf$/i,
        zoomEnabled: false,
        render: (instance) => {
            return (
                <ClientPdfViewer
                    clientId={instance.props.clientId}
                    file={instance.props.answer!.file}
                    fileLocation={instance.props.answer!.fileLocation}
                    rotationDegrees={instance.state.rotationDegrees}
                />
            );
        },
    },
];

const SCALE_INCREMENT = 2;
const ROTATION_INCREMENT = 90;

class ClaimDocumentViewer extends React.Component<RequiredProps, ClaimDocumentViewerState> {
    static defaultProps: OptionalProps = {
        maxScale: SCALE_INCREMENT ** 2 /* number of additional zoom levels */,
        minScale: 1,
    };

    constructor(props: ClaimDocumentViewerProps) {
        super(props);
        this.state = {
            rotationDegrees: 0,
            scale: 1,
        };
    }

    componentDidUpdate(prevProps: ClaimDocumentViewerProps) {
        if (this.props.selectedFileQuestionId !== prevProps.selectedFileQuestionId) {
            this.setState({ scale: 1, rotationDegrees: 0 });
        }
    }

    onPageChange = (page: number) => {
        const nextIndex = page - 1;
        const nextQuestion = this.props.questions[nextIndex];
        this.props.onSelectedQuestionChange(nextQuestion.id as string);
    };

    onZoomIn = () => this.setScale(this.state.scale * SCALE_INCREMENT);

    onZoomOut = () => this.setScale((this.state.scale * 1) / SCALE_INCREMENT);

    onRotateLeft = () => this.setRotation(this.state.rotationDegrees - ROTATION_INCREMENT);

    onRotateRight = () => this.setRotation(this.state.rotationDegrees + ROTATION_INCREMENT);

    setScale(scale: number) {
        const props = { ...ClaimDocumentViewer.defaultProps, ...this.props };
        this.setState({ scale: clamp(scale, props.minScale, props.maxScale) });
    }

    setRotation(rotationDegrees: number) {
        this.setState({ rotationDegrees: (rotationDegrees + 360) % 360 });
    }

    onDownloadClick = () => {
        if (!this.props.answer) {
            return;
        }
        window.open(`${this.props.answer.fileUrl}&forceDownload=true`, '_blank');
    };

    isViableStrategy(strategy: ViewerStrategy) {
        if (!this.props.answer) {
            return false;
        }
        const { file, fileLocation } = this.props.answer;
        if (file) {
            return strategy.fileTypePattern.test(file.type);
        }
        if (!fileLocation) {
            return false;
        }
        return strategy.fileNamePattern.test(fileLocation);
    }

    renderControls(strategy?: ViewerStrategy) {
        const props = { ...ClaimDocumentViewer.defaultProps, ...this.props };
        const canPreview = strategy != null;

        return (
            <ButtonGroup size="sm">
                <Authorize permissions={[ClientLevel.ProcessMailInClaims]}>
                    {props.answer && props.answer.type === OvationsApi.Enums.QuestionType.FileUpload && (
                        <Button
                            outline
                            type="button"
                            className="btn-outline btn-outline-secondary"
                            id={elements.claimDetail.id.documentDownload}
                            onClick={this.onDownloadClick}
                            disabled={!props.answer.fileUrl}
                            title="Download File"
                        >
                            <FAIcon icon={faDownload} />
                        </Button>
                    )}
                </Authorize>
                <Button
                    outline
                    type="button"
                    className="btn-outline btn-outline-secondary"
                    id={elements.claimDetail.id.documentRotateLeft}
                    onClick={this.onRotateLeft}
                    disabled={!canPreview}
                >
                    <FAIcon icon={faUndo} />
                </Button>
                <Button
                    outline
                    type="button"
                    className="btn-outline btn-outline-secondary"
                    id={elements.claimDetail.id.documentRotateRight}
                    onClick={this.onRotateRight}
                    disabled={!canPreview}
                >
                    <FAIcon icon={faRedo} />
                </Button>
                {strategy && strategy.zoomEnabled && (
                    <>
                        <Button
                            outline
                            type="button"
                            className="btn-outline btn-outline-secondary"
                            id={elements.claimDetail.id.documentZoomIn}
                            onClick={this.onZoomIn}
                            disabled={!canPreview || this.state.scale === props.maxScale}
                        >
                            <FAIcon icon={faPlus} />
                        </Button>
                        <Button
                            type="button"
                            outline
                            className="btn-outline btn-outline-secondary"
                            id={elements.claimDetail.id.documentZoomOut}
                            onClick={this.onZoomOut}
                            disabled={!canPreview || this.state.scale === props.minScale}
                        >
                            <FAIcon icon={faMinus} />
                        </Button>
                    </>
                )}
            </ButtonGroup>
        );
    }

    renderDocument(strategy?: ViewerStrategy) {
        const { answer } = this.props;
        if (!answer || (!answer.fileLocation && !answer.file)) {
            return 'This question has no file uploaded.';
        }
        if (!strategy) {
            return 'This file cannot be previewed.';
        }
        return strategy.render(this);
    }

    render() {
        const { props } = this;
        const activeIndex = findIndex(props.questions, { id: props.selectedFileQuestionId });
        const promotionQuestion = props.questions[activeIndex];
        if (!promotionQuestion) {
            return null;
        }
        const viewerStrategy = viewerStategies.find((strategy) => this.isViableStrategy(strategy));

        return (
            <div>
                <div className="d-flex align-items-baseline justify-content-between">
                    <div>
                        <h3 className="h5 m-0">Customer Files</h3>
                    </div>
                    <div>
                        <MiniPaginate
                            id={elements.claimDetail.id.documentPager}
                            page={activeIndex + 1}
                            perPage={1}
                            total={props.questions.length}
                            onArrowClick={this.onPageChange}
                            noun="File"
                            size="sm"
                        />
                    </div>
                </div>
                <div className="d-flex align-items-baseline justify-content-between py-2">
                    {promotionQuestion.question.title}
                    {this.renderControls(viewerStrategy)}
                </div>
                <div id={elements.claimDetail.id.document}>{this.renderDocument(viewerStrategy)}</div>
            </div>
        );
    }
}

export default ClaimDocumentViewer;
