import PortalContentCodeLocation from 'definitions/PortalContentCodeLocation';
import PortalContentCodes from 'enums/PortalContentCodes';

export const portalContentCodeLocations: { [contentCode: string]: PortalContentCodeLocation } = {
    [PortalContentCodes.EmailDomain]: {
        pageName: 'Channels',
        fieldName: 'Mailgun Email Domain',
    },
    [PortalContentCodes.FromEmail]: {
        pageName: 'Channels',
        fieldName: '"From" Email Address',
    },
    [PortalContentCodes.ReplyToEmail]: {
        pageName: 'Channels',
        fieldName: '"Reply To" Email Address',
    },
    [PortalContentCodes.NavbarColor]: {
        pageName: 'Header',
        fieldName: 'Nav Bar Color',
    },
    [PortalContentCodes.NavbarLinkTextColor]: {
        pageName: 'Header',
        fieldName: 'Nav Link Text Color',
    },
    [PortalContentCodes.NavbarLinkHoverTextColor]: {
        pageName: 'Header',
        fieldName: 'Nav Link Hover Text Color',
    },
    [PortalContentCodes.NavbarLinkHoverBackgroundColor]: {
        pageName: 'Header',
        fieldName: 'Nav Link Hover Background Color',
    },
    [PortalContentCodes.TagManagerId]: {
        pageName: 'URL & Analytics',
        fieldName: 'Tag Manager ID',
    },
    [PortalContentCodes.ContactReceivingEmail]: {
        pageName: 'Contact Us',
        fieldName: 'Receiving Email Address',
    },
    [PortalContentCodes.ContactPhoneNumber]: {
        pageName: 'Contact Us',
        fieldName: 'Contact Number',
    },
    [PortalContentCodes.ContactHours]: {
        pageName: 'Contact Us',
        fieldName: 'Hours of Operation',
    },
    [PortalContentCodes.RegistrationSubject]: {
        pageName: 'Registration Success',
        fieldName: 'Registration Successful Email: Subject',
    },
    [PortalContentCodes.RegistrationEmailContent]: {
        pageName: 'Registration Success',
        fieldName: 'Registration Successful Email: Body',
    },
    [PortalContentCodes.RegistrationSmsContent]: {
        pageName: 'Registration Success',
        fieldName: 'Registration Successful SMS: Body',
    },
    [PortalContentCodes.PasswordResetSubject]: {
        pageName: 'Password Updates',
        fieldName: 'Password Reset Email: Subject',
    },
    [PortalContentCodes.PasswordResetEmailContent]: {
        pageName: 'Password Updates',
        fieldName: 'Password Reset Email: Body',
    },
    [PortalContentCodes.PasswordResetSmsContent]: {
        pageName: 'Password Updates',
        fieldName: 'Password Reset SMS: Body',
    },
    [PortalContentCodes.PasswordChangedSubject]: {
        pageName: 'Password Updates',
        fieldName: 'Password Changed Email: Subject',
    },
    [PortalContentCodes.PasswordChangedEmailContent]: {
        pageName: 'Password Updates',
        fieldName: 'Password Changed Email: Body',
    },
    [PortalContentCodes.PasswordChangedSmsContent]: {
        pageName: 'Password Updates',
        fieldName: 'Password Changed SMS: Body',
    },
    [PortalContentCodes.FrequentlyAskedQuestions]: {
        pageName: 'FAQs',
        fieldName: 'Frequently Asked Questions',
    },
};
