import { Dictionary, keyBy, values } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { FileImport } from 'core/ovations-api/definitions';
import FileImportState from 'redux-modules/definitions/FileImportState';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import S from 'redux-modules/definitions/RootState';
import FileImportType from 'core/ovations-api/enums/FileImportType';

export const emptyClaimImport: OvationsApi.Types.FileImport = {
    id: '',
    programId: '',
    name: '',
    fileLocation: '',
    uploadDate: '',
    uploadedBy: '',
    url: '',
    successful: 0,
    failed: 0,
    detailUrl: '',
};

export const initialState: FileImportState = {
    map: {},
    totalResults: 0,
};

const { reducer, update } = createReducer<FileImportState>('fileImport/UPDATE', initialState);
export const fileImportReducer = reducer;

export const actions = {
    fetchAll(
        clientId: string,
        programId: string,
        fileImportType: FileImportType,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.FileImport.fetchAll(clientId, programId, fileImportType);
            const map = keyBy(list.items, 'id') as Dictionary<FileImport>;
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: list.totalRecords })));
        };
    },

    search(
        clientId: string,
        programId: string,
        fileImportType: FileImportType,
        request: Partial<OvationsApi.Types.FileImportSearchRequest>,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsApi.Types.FileImport>>,
        S,
        ExtraArg,
        Action
    > {
        return async (dispatch, getState, { clientContextManager }) => {
            const list = await OvationsApi.FileImport.search(clientId, programId, fileImportType, request);
            const map = keyBy(list.results, 'id') as Dictionary<FileImport>;
            dispatch(clientContextManager.action(clientId, update({ map, totalResults: list.totalResults })));
            return list;
        };
    },

    createFileImport(
        clientId: string,
        fileToSave: FileImport,
        fileImportType: FileImportType,
    ): ThunkAction<Promise<FileImport>, S, ExtraArg, Action> {
        return async (dispatch) => {
            const newFile = await OvationsApi.FileImport.create(clientId, fileToSave, fileImportType);
            newFile.file = fileToSave.file;
            dispatch(actions.upsertDocument(clientId, newFile));
            return newFile;
        };
    },

    upsertDocument(clientId: string, fileToSave: FileImport): ThunkAction<void, S, ExtraArg, Action> {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.fileImport.map, [fileToSave.id]: fileToSave };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

const getList = createSelector([(state: FileImportState) => state.map], (map) => values(map));

export const selectors = {
    getList,

    getListForProgram: (state: FileImportState, programId: string) => {
        const claimImportFiles = selectors.getList(state);
        return claimImportFiles.filter((file) => file.programId === programId);
    },
};
