import { push, replace } from 'connected-react-router';
import { throttle } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import CustomerDataTable from 'components/customer/CustomerDataTable';
import * as messages from 'config/messages';
import { ContainerProps } from 'containers/Container';
import * as OvationsApi from 'core/ovations-api';
import { Color } from 'enums/Theme';
import * as customer from 'redux-modules/customer';
import * as notification from 'redux-modules/notification';
import { clientContextManager, selectors } from 'redux-modules/root';

export type CustomerListContainerProps = ContainerProps<{ clientId: string; programId: string }>;

export interface CustomerListContainerState {
    isSearching: boolean;
    searchRequest: OvationsApi.Types.CustomerSearchRequest;
    // ^ Keeps inputs up-to-date while throttled setRequest is queued
}

export class CustomerListContainer extends React.Component<CustomerListContainerProps, CustomerListContainerState> {
    respondToSearchInputChange = throttle(this.setRequest, 250, { leading: false });

    constructor(props: CustomerListContainerProps) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            isSearching: false,
            searchRequest: OvationsApi.Customer.deserializeRequest(this.props.location.search),
        };
    }

    componentDidMount() {
        this.search();
    }

    componentDidUpdate(prevProps: CustomerListContainerProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.search();
        }
    }

    setRequest(request: Partial<OvationsApi.Types.CustomerSearchRequest>) {
        this.props.dispatch(replace(`?${OvationsApi.Customer.serializeRequest(request)}`));
    }

    resetSearchHeader = () => {
        const { location } = this.props;
        location.search = '';
        const resetSearchRequest = OvationsApi.Customer.deserializeRequest(location.search);
        this.respondToSearchInputChange(resetSearchRequest);

        this.search();
    };

    onRowClick = (row: OvationsApi.Types.Customer) => {
        const path = selectors.getCustomerDetailPath(this.props, row.id);
        if (path) {
            this.props.dispatch(push(path));
        }
    };

    onPageChange = (page: number) => {
        const request = OvationsApi.Customer.deserializeRequest(this.props.location.search);
        this.setRequest({ ...request, page });
    };

    onSearch = (key: string, value: string) => {
        const searchRequest = { ...this.state.searchRequest, [key]: value, page: 1 };
        this.setState({ searchRequest });
        this.respondToSearchInputChange(searchRequest);
    };

    async search(props: CustomerListContainerProps = this.props) {
        const { clientId, programId } = props.match.params;
        const searchRequest = OvationsApi.Customer.deserializeRequest(props.location.search);
        this.setState({ isSearching: true, searchRequest });
        try {
            await props.dispatch(customer.actions.search(clientId, programId, searchRequest));
        } catch (e) {
            props.dispatch(
                notification.actions.add({
                    type: Color.Danger,
                    body: messages.generalFailure(),
                    duration: 3 * 1000,
                }),
            );
        }
        this.setState({ isSearching: false });
    }

    render() {
        const { clientId } = this.props.match.params;
        const ctx = clientContextManager.getContext(this.props, clientId);
        const customerList = customer.selectors.getList(ctx.customer);
        return (
            <div className="wrap mt-4">
                <div className="d-flex pb-3">
                    <h1 className="h3 m-0">Customers</h1>
                </div>
                <CustomerDataTable
                    customerList={customerList}
                    isSearching={this.state.isSearching}
                    totalResults={ctx.customer.totalResults}
                    onPageChange={this.onPageChange}
                    onRowClick={this.onRowClick}
                    onSearch={this.onSearch}
                    searchRequest={this.state.searchRequest}
                    onReset={this.resetSearchHeader}
                />
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(CustomerListContainer);
