import { keyBy, values } from 'lodash';
import { Action, AnyAction, Dispatch } from 'redux';

import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { Client } from 'core/ovations-api/definitions';
import ClientState from 'redux-modules/definitions/ClientState';
import S from 'redux-modules/definitions/RootState';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

const initialState: ClientState = {
    map: {},
    fileTypes: {},
};

export const emptyClient: Client = {
    id: '',
    number: 0,
    name: '',
    logoUrl: '',
    logo: null,
    cardEngineClientId: null,
};

const { reducer, update } = createReducer<ClientState>('client/UPDATE', initialState);
export const clientReducer = reducer;

export const actions = {
    fetchAll: () => async (dispatch: Dispatch<AnyAction>) => {
        const list = await OvationsApi.Client.fetchAll();
        const map = keyBy(list, 'id');
        dispatch(update({ map }));
    },

    fetch: (id: string): ThunkAction<Promise<Client | undefined>, S, void, Action> => {
        return async (dispatch) => {
            const client = await OvationsApi.Client.fetch(id);
            if (!client) {
                return;
            }
            dispatch(actions.upsertClientDetail(client));
            return client;
        };
    },

    fetchFileTypes: (id: string): ThunkAction<Promise<void>, S, void, Action> => {
        return async (dispatch) => {
            const fileTypes = await OvationsApi.FileType.fetchAll(id);
            if (!fileTypes) {
                return;
            }
            dispatch(actions.upsertFileTypes(id, fileTypes));
        };
    },

    update: (client: Client): ThunkAction<void, S, void, Action> => {
        return async (dispatch) => {
            await OvationsApi.Client.update(client);
            dispatch(actions.upsertClientDetail(client));
        };
    },

    upsertClientDetail: (client: Client): ThunkAction<void, S, void, Action> => {
        return (dispatch, getState) => {
            const map = { ...getState().client.map, [client.id]: client };
            dispatch(update({ map }));
        };
    },

    upsertFileTypes: (clientId: string, fileTypeStrings: string[]): ThunkAction<void, S, void, Action> => {
        return (dispatch, getState) => {
            const fileTypes = { ...getState().client.fileTypes, [clientId]: fileTypeStrings };
            dispatch(update({ fileTypes }));
        };
    },
};

export const selectors = {
    getList: createSelector(
        (state: ClientState) => state.map,
        (map: ClientState['map']) => values(map),
    ),
};
