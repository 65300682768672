import { faArrowRight, faCog } from '@fortawesome/free-solid-svg-icons';
import { includes } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import elements from 'config/elements';
import * as routes from 'config/routes';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { SystemLevel } from 'enums/Permission';

const { globalHeader } = elements;

export interface GlobalSettingsProps {
    user: OvationsApi.Types.PlatformUserProfile;
    onNewClientClick?: () => void;
    onNewUserClick?: () => void;
}

const GlobalSettingsDropdown: React.SFC<GlobalSettingsProps> = (props) => {
    const { platformPermissions } = props.user;
    const menuTopSection = [];
    const menuBottomSection = [];
    if (includes(platformPermissions, SystemLevel.ManageUsers)) {
        menuTopSection.push(
            <DropdownItem key="o-vations-users" tag={Link} {...{ to: routes.USERS }}>
                O-vations Users
            </DropdownItem>,
        );
        menuBottomSection.push(
            <DropdownItem key="new-user" onClick={props.onNewUserClick} className="new-user-trigger">
                <div
                    id={globalHeader.class.newUserTrigger}
                    className="d-flex justify-content-between align-items-center"
                >
                    New User
                    <FAIcon icon={faArrowRight} />
                </div>
            </DropdownItem>,
        );
    }
    if (includes(platformPermissions, SystemLevel.ManageClients)) {
        menuBottomSection.push(
            <DropdownItem
                key="new-client"
                onClick={props.onNewClientClick}
                className={globalHeader.class.newClientTrigger}
            >
                <div className="d-flex justify-content-between align-items-center">
                    New Client
                    <FAIcon icon={faArrowRight} />
                </div>
            </DropdownItem>,
        );
    }
    if (includes(platformPermissions, SystemLevel.ConfigureRoles)) {
        menuTopSection.push(
            <DropdownItem key="manage-roles" tag={Link} {...{ to: routes.ROLES }}>
                Manage O-vations Roles
            </DropdownItem>,
        );
        menuBottomSection.push(
            <DropdownItem key="new-role" tag={Link} {...{ to: routes.ROLE_CREATE }}>
                <div className="d-flex justify-content-between align-items-center">
                    New Role
                    <FAIcon icon={faArrowRight} />
                </div>
            </DropdownItem>,
        );
    }
    if (!menuTopSection.length && !menuBottomSection.length) {
        return null;
    }
    return (
        <UncontrolledDropdown className={globalHeader.class.globalSettingsDropdown}>
            <DropdownToggle aria-label="settings" caret color="x-light">
                <FAIcon icon={faCog} size="lg" />
            </DropdownToggle>
            <DropdownMenu end>
                {menuTopSection}
                {menuTopSection.length > 0 && menuBottomSection.length > 0 && <DropdownItem divider />}
                {menuBottomSection}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default GlobalSettingsDropdown;
