import { Dictionary, groupBy, map } from 'lodash';
import * as qs from 'query-string';
import React from 'react';

import elements from 'config/elements';
import { ContainerProps } from 'containers/Container';
import { connect } from 'react-redux';

import { portalContentCodeLocations } from 'config/portalContentCodeLocations';
import PortalContentCodeLocation from 'definitions/PortalContentCodeLocation';
import PortalContentCodes from 'enums/PortalContentCodes';

export type PortalMissingFieldsContainerProps = ContainerProps;

export class PortalMissingFieldsContainer extends React.Component<PortalMissingFieldsContainerProps> {
    getStructuredLocations() {
        const missingFields = qs.parse(this.props.location.search, { arrayFormat: 'bracket' });
        const errorCodes: PortalContentCodes[] = missingFields.data as PortalContentCodes[];
        const locations: PortalContentCodeLocation[] = errorCodes.map(
            (errorCode) => portalContentCodeLocations[errorCode],
        );
        const structuredLocations: Dictionary<PortalContentCodeLocation[]> = groupBy(locations, 'pageName');
        return structuredLocations;
    }

    renderStructuredLocationMarkup() {
        const structuredLocations = this.getStructuredLocations();
        const structuredLocationMarkup = map(structuredLocations, (fields, index) => {
            const fieldsArray = fields.map((field) => {
                return field.fieldName;
            });
            const formattedFields = fieldsArray.join(', ');
            return (
                <div key={index} className={`mb-2 ${elements.portalConfig.class.missingFieldsItem}`}>
                    <span className="d-block">Page Name: {index}</span>
                    <span className="d-block">Field(s): {formattedFields}</span>
                </div>
            );
        });
        return structuredLocationMarkup;
    }

    render() {
        return (
            <div className="container">
                <h1>Missing portal fields:</h1>
                <span className="h5 d-block mb-2">Complete the following required fields to enable the portal.</span>
                {this.renderStructuredLocationMarkup()}
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(PortalMissingFieldsContainer);
