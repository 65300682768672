import { faChevronLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { last, values } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { matchPath, Route, Switch } from 'react-router';
import { Link, Prompt } from 'react-router-dom';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
} from 'reactstrap';
import { createSelector } from 'reselect';

import BreadcrumbTrail, { BreadcrumbTrailItem } from 'components/BreadcrumbTrail';
import UserClientRoles from 'components/user/UserClientRoles';
import UserPlatformPermissions from 'components/user/UserPlatformPermissions';
import elements from 'config/elements';
import * as messages from 'config/messages';
import * as routes from 'config/routes';
import { ContainerProps } from 'containers/Container';
import toPath from 'core/lib/toPath';
import { Comparison } from 'core/definitions/HasChangesSelector';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import { hasSameValues } from 'core/util/arrays';
import Tab from 'definitions/Tab';
import { Color } from 'enums/Theme';
import withData, { LoaderConfig } from 'lib/withData';
import * as client from 'redux-modules/client';
import * as notification from 'redux-modules/notification';
import * as permission from 'redux-modules/permission';
import * as profile from 'redux-modules/profile';

export interface UserDetailProps extends ContainerProps<{ userId: string }> {
    breadcrumbs: BreadcrumbTrailItem[];
}

interface UserDetailState {
    isConfirmingArchive: boolean;
    isLoading: boolean;
    isSavingChanges: boolean;
    isSavingUserStatus: boolean;
    profile: OvationsApi.Types.PlatformUserProfile;
}

const hasChanges = createSelector(
    (state: Comparison<OvationsApi.Types.PlatformUserProfile>) => state.initial,
    (state: Comparison<OvationsApi.Types.PlatformUserProfile>) => state.current,
    (initial, current) => {
        if (!initial || !current) {
            return initial !== current;
        }
        if (!hasSameValues(initial.platformPermissions, current.platformPermissions)) {
            return true;
        }
        if (!hasSameValues(initial.clientRoles, current.clientRoles)) {
            return true;
        }
        return false;
    },
);

export class UserDetailContainer extends React.Component<UserDetailProps, UserDetailState> {
    tabs: Tab[] = [
        { id: 'platform-permissions', label: 'Platform Level Permissions', path: routes.USER_DETAIL },
        { id: 'client-roles', label: 'Client Roles', path: routes.USER_ROLES },
        { id: 'history', label: 'History', path: routes.USER_HISTORY },
    ];

    constructor(props: UserDetailProps) {
        super(props);

        const { userId } = props.match.params;

        this.state = {
            isConfirmingArchive: false,
            isLoading: false,
            isSavingChanges: false,
            isSavingUserStatus: false,
            profile: props.profile.map[userId] || profile.emptyProfile,
        };
    }

    componentDidUpdate(prevProps: UserDetailProps) {
        const nextProfile = this.props.profile.map[this.props.match.params.userId];
        const profileHasChanged = nextProfile !== prevProps.profile.map[prevProps.match.params.userId];
        if (profileHasChanged) {
            this.setState({ profile: nextProfile });
        }
    }

    onConfirmArchive: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.setIsArchived(true);
    };

    onPlatformPermissionsChange = (platformPermissions: string[]) => {
        this.setState({ profile: { ...this.state.profile, platformPermissions } });
    };

    onRolesChange = (clientRoles: OvationsApi.Types.ClientRole[]) => {
        this.setState({ profile: { ...this.state.profile, clientRoles } });
    };

    onSaveClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.saveChanges();
    };

    onUserStatusClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        const isArchived = e.currentTarget.hasAttribute('data-is-archived');
        if (isArchived) {
            this.toggleArchiveConfirmation();
            return;
        }
        this.setIsArchived(false);
    };

    async setIsArchived(isArchived: boolean) {
        this.setState({
            isSavingUserStatus: true,
            isConfirmingArchive: false,
            profile: { ...this.state.profile, isArchived },
        });
        let type;
        let body;
        try {
            await this.props.dispatch(profile.actions.updateStatus(this.state.profile.id, isArchived));
            type = Color.Success;
            body = messages.archiveSuccess(isArchived);
        } catch (e) {
            type = Color.Danger;
            body = messages.archiveFailure(isArchived);
        }
        this.props.dispatch(notification.actions.add({ type, body, duration: 4000 }));
        this.setState({ isSavingUserStatus: false });
    }

    toggleArchiveConfirmation = () => {
        this.setState({ isConfirmingArchive: !this.state.isConfirmingArchive });
    };

    async saveChanges() {
        this.setState({ isSavingChanges: true });
        let type;
        let body;
        try {
            await this.props.dispatch(profile.actions.saveUpdates(this.state.profile));
            type = Color.Success;
            body = messages.profileUpdateSuccess();
        } catch (e) {
            type = Color.Danger;
            body = messages.profileUpdateFailure();
        }
        this.props.dispatch(notification.actions.add({ type, body, duration: 3000 }));
        this.setState({ isSavingChanges: false });
    }

    renderActionBar() {
        const lastBreadcrumb = last(this.props.breadcrumbs);
        const { state, props } = this;
        const initial = props.profile.map[props.match.params.userId];
        const current = state.profile;
        return (
            <div className="wrap">
                {lastBreadcrumb && lastBreadcrumb.url && (
                    <Link
                        to={lastBreadcrumb.url}
                        className="me-3 btn btn-outline-primary"
                        id={elements.userSettingsPage.id.backButton}
                    >
                        <FAIcon icon={faChevronLeft} /> Back
                    </Link>
                )}
                {state.profile.isArchived ? (
                    <span>Only active users can be edited.</span>
                ) : (
                    <Button
                        key="action-bar__primary"
                        color="primary"
                        className="me-3"
                        disabled={state.isSavingChanges || !hasChanges({ initial, current })}
                        onClick={this.onSaveClick}
                    >
                        {state.isSavingChanges ? (
                            <span>
                                Saving... <FAIcon icon={faCircleNotch} className="spin" />
                            </span>
                        ) : (
                            'Save Changes'
                        )}
                    </Button>
                )}
            </div>
        );
    }

    render() {
        const { state } = this;
        if (state.isLoading) {
            return (
                <div className="pt-5 text-center">
                    <FAIcon icon={faCircleNotch} size="2x" className="text-secondary spin" />
                </div>
            );
        }
        const fullName = `${state.profile.firstName} ${state.profile.lastName}`;
        const baseUrl = this.props.match.url;
        const breadcrumbs = [...this.props.breadcrumbs, { label: fullName, url: baseUrl }];
        const initial = this.props.profile.map[this.props.match.params.userId];
        const current = state.profile;
        return (
            <div>
                <Prompt
                    when={hasChanges({ initial, current })}
                    message={(location) => {
                        if (location.pathname.startsWith(baseUrl)) {
                            return true;
                        }
                        return messages.unsavedChanges();
                    }}
                />
                <Modal className={elements.archiveUserModal.class.modal} isOpen={this.state.isConfirmingArchive}>
                    <ModalHeader toggle={this.toggleArchiveConfirmation}>Confirm archive</ModalHeader>
                    <ModalBody>
                        Are you sure you want to archive this user? The user will no longer be able to access O-vations.
                        Their roles and permissions will be frozen.
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            id={elements.archiveUserModal.id.cancelArchiveTrigger}
                            color="secondary"
                            outline
                            onClick={this.toggleArchiveConfirmation}
                        >
                            Cancel
                        </Button>
                        <Button
                            id={elements.archiveUserModal.id.archiveTrigger}
                            color="danger"
                            outline
                            onClick={this.onConfirmArchive}
                        >
                            Yes, Archive
                        </Button>
                    </ModalFooter>
                </Modal>
                <BreadcrumbTrail breadcrumbs={breadcrumbs} />
                <div className="wrap d-flex justify-content-between align-items-center">
                    <div>
                        <h1 id={elements.userSettingsPage.id.userName} className="h3 m-0 d-inline-block">
                            {state.profile.firstName} {state.profile.lastName}
                        </h1>
                        <span id={elements.userSettingsPage.id.userEmail} className="ps-2 text-secondary">
                            {state.profile.email}
                        </span>
                    </div>
                    <div>
                        <div>
                            {current !== undefined && (
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        className={`${elements.userSettingsPage.class.userStatusDropdown} btn--soft`}
                                        caret
                                        color="light"
                                        disabled={this.state.isSavingUserStatus}
                                    >
                                        {current.isArchived ? ' Archived' : ' Active'}
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <DropdownItem
                                            className={elements.userSettingsPage.class.userStatusToggle}
                                            {...(!current.isArchived && { 'data-is-archived': '' })}
                                            onClick={this.onUserStatusClick}
                                        >
                                            {!current.isArchived ? ' Archived' : ' Active'}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                {this.renderActionBar()}
                <hr />
                <div className="wrap">
                    <Nav tabs>
                        {this.tabs.map((tab) => {
                            const match = matchPath(this.props.location.pathname, { path: tab.path, exact: true });
                            const isActive = match !== null;
                            return (
                                <NavItem key={tab.id}>
                                    <NavLink
                                        id={tab.id}
                                        active={isActive}
                                        aria-selected={isActive}
                                        tag={Link}
                                        {...{ replace: true, to: toPath(tab.path, this.props.match.params) }}
                                    >
                                        {tab.label}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </div>
                <div className="wrap pt-4">
                    <Switch>
                        <Route
                            path={this.tabs[0].path}
                            exact
                            render={() => (
                                <div aria-labelledby={this.tabs[0].id}>
                                    <UserPlatformPermissions
                                        disabled={state.profile.isArchived}
                                        selected={state.profile.platformPermissions}
                                        values={permission.selectors.getSystemPerms(this.props.permission)}
                                        onPermissionsChange={this.onPlatformPermissionsChange}
                                    />
                                </div>
                            )}
                        />
                        <Route
                            path={this.tabs[1].path}
                            render={() => (
                                <div aria-labelledby={this.tabs[1].id}>
                                    <UserClientRoles
                                        userRoles={state.profile.clientRoles}
                                        clients={client.selectors.getList(this.props.client)}
                                        roles={values(this.props.role.map)}
                                        onRolesChange={this.onRolesChange}
                                    />
                                </div>
                            )}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}

export const profileConfig: LoaderConfig<UserDetailProps> = {
    memoizer: (props) => props.match.params.userId,
    needsData: (props, prevProps) => !prevProps || !props.profile.map[props.match.params.userId],
    fetchData: (props) => props.dispatch(profile.actions.fetchProfile(props.match.params.userId)),
};
export const permissionsConfig: LoaderConfig<UserDetailProps> = {
    memoizer: () => true,
    needsData: (props) => !props.permission.manifests[OvationsApi.Enums.PermissionType.System],
    fetchData: (props) => props.dispatch(permission.actions.fetchAll()),
};

/* istanbul ignore next */
export default connect((state) => state)(withData(profileConfig, permissionsConfig)(UserDetailContainer));
